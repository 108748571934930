import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-icon-product-group',
    templateUrl: './icon-product-group.component.html',
    styleUrls: ['./icon-product-group.component.scss']
})
export class IconProductGroupComponent implements OnInit {

    @Input() cssClass: string;

    constructor() { }

    ngOnInit(): void {
    }

}
