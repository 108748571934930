import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
    selector: 'app-status-filter-temp1',
    templateUrl: './status-filter.temp1.component.html',
    styleUrls: ['./status-filter.temp1.component.scss']
})
export class StatusFilterTemp1Component implements OnInit {
    @Input() statuses: Array<any>;
    @Input() selectedStatuses: number[] = [];
    @Output() selectAll = new EventEmitter<any>();
    @Output() nameClick = new EventEmitter<any>();
    @Output() select = new EventEmitter<Array<number>>();

    constructor() { }

    ngOnInit(): void {
    }

    onSelectAll() {
        this.selectAll.emit([]);
    }

    onNameClick($event, status) {
        $event.preventDefault();
        this.nameClick.emit(status);
    }

    onSelect($event: MatCheckboxChange, status) {
        if ($event.checked) {
            this.selectedStatuses.push(status.id);
        } else {
            const idx = this.selectedStatuses.findIndex(s => s === status.id);
            this.selectedStatuses.splice(idx, 1);
        }

        this.select.emit(this.selectedStatuses);
    }

}
