import { SafeResourceUrl } from "@angular/platform-browser";
import { GeneralItemModel } from "src/app/shared/models/general-item.model";
import { AuctionItemStatusEnum } from "./auction-item-status.enum";
import { DeliveryMethodModel } from "./delivery-method.model";
import { ProductTypeEnum } from "./product-type.enum";

export class AuctionItemModel {
    address: string;
    conditionDescription: string;
    deliveryFee: number;
    id: number;
    imageUrl: SafeResourceUrl;
    isCurrentUsersProduct: boolean;
    isPriceNegociable: boolean;
    name: string;
    numberOfOffers: number;
    price: number;
    status: AuctionItemStatusEnum;
    description: string;
    categoryId?: number;
    manufacturerId?: number;
    conditionTypeId: number;
    deliveryMethodIds?: Array<number> = new Array<number>();
    manufacturer?: Array<GeneralItemModel> = [new GeneralItemModel()];
    category?: Array<GeneralItemModel> = [new GeneralItemModel()];
    productId: number;
    deliveryMethods?: Array<DeliveryMethodModel>;
    imageNames: Array<string>;
    categoryString: string;
    manufacturerString: string;
    productTypeId: ProductTypeEnum;
}


