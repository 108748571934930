import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-icon-verification-email',
    templateUrl: './icon-verification-email.component.html'
})
export class IconVerificationEmailComponent implements OnInit {

    @Input() cssClass = 'icon--18';

    constructor() { }

    ngOnInit() {
    }

}
