import { createSelector } from '@ngrx/store';

export const selectInfobarState = state => state.infobar;

export const isOpenInfobar = createSelector(
    selectInfobarState,
    state => state.isOpen
);

export const infoBarTransactionParams = createSelector(
    selectInfobarState,
    state => state.transaction
);

export const infobarTitle = createSelector(
    selectInfobarState,
    state => state.title
);
