import { Action } from '@ngrx/store';

export enum UtilsActionTypes {
    LoadCountries = '[Global] LoadCountries',
    CountriesLoaded = '[Global] CountriesLoaded',
    LoadStates = '[Global] LoadStates',
    StatesLoaded = '[Global] StatesLoaded',
    LoadTimezones = '[Global] Load Timezones',
    TimezonesLoaded = '[Global] Timezones Loaded',
}

export class LoadCountries implements Action {
    readonly type = UtilsActionTypes.LoadCountries;
}

export class CountriesLoaded implements Action {
    readonly type = UtilsActionTypes.CountriesLoaded;

    constructor(public payload: { countries: any[] }) { }
}

export class LoadStates implements Action {
    readonly type = UtilsActionTypes.LoadStates;

    constructor(public payload: { countryId: number }) { }
}

export class StatesLoaded implements Action {
    readonly type = UtilsActionTypes.StatesLoaded;

    constructor(public payload: { countryId: number, states: any[] }) { }
}

export class LoadTimezones implements Action {
    readonly type = UtilsActionTypes.LoadTimezones;
}

export class TimezonesLoaded implements Action {
    readonly type = UtilsActionTypes.TimezonesLoaded;

    constructor(public payload: { timezones: any[] }) { }
}

export type UtilsAction =
    LoadCountries
    | CountriesLoaded
    | LoadStates
    | StatesLoaded
    | LoadTimezones
    | TimezonesLoaded;
