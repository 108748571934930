import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-icon-bath-faucets',
  templateUrl: './icon-bath-faucets.component.html',
  styleUrls: ['./icon-bath-faucets.component.scss']
})
export class IconBathFaucetsComponent implements OnInit {

  @Input() cssClass = 'icon--18';

  constructor() { }

  ngOnInit() {
  }

}
