import { BuyerVendorModel } from './buyer-vendor.model';

export class ChatDisplayStatus {
    showOnlyInbox: boolean;
    showChat: boolean;
    showNewConversation: boolean;
    entityTypeId: number;
    entity: string;
    entityId: number;
    buyerVendor: BuyerVendorModel;
    chatClosed: boolean;
}
