import { Component, ElementRef, EventEmitter, Input, OnInit, Output, HostListener, AfterViewInit } from '@angular/core';

@Component({
    selector: 'app-tl-daterange-timepicker',
    templateUrl: './tl-daterange-timepicker.component.html',
    styleUrls: ['./tl-daterange-timepicker.component.scss']
})
export class TlDaterangeTimepickerComponent implements OnInit, AfterViewInit {
    @Input() selectedRangeInput: Array<Date>;
    @Input() displayComponent: boolean;
    @Output() displayComponentChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() selectedRangeChanged: EventEmitter<Array<Date>> = new EventEmitter<Array<Date>>();

    startTime: Date;
    endTime: Date;
    selectedRange: Array<Date>;
    private parentNode: any;

    constructor(
        private element: ElementRef) {
    }

    ngAfterViewInit(): void {
        this.parentNode = this.element.nativeElement.parentNode;
    }

    @HostListener('document:click', ['$event.path'])
    onClickOutside($event: Array<any>) {
        const elementRefInPath = $event.find(node => node === this.parentNode);
        if (!elementRefInPath) {
            this.displayComponent = false;
            this.displayComponentChange.emit(this.displayComponent);
        }
    }

    ngOnInit() {
        this.selectedRange = this.selectedRangeInput;
        this.startTime = this.selectedRange[0];
        this.endTime = this.selectedRange[1];
    }

    setDaterangeTime() {
        this.selectedRange[0].setHours(this.startTime.getHours());
        this.selectedRange[0].setMinutes(this.startTime.getMinutes());
        this.selectedRange[0].setSeconds(this.startTime.getSeconds());
        this.selectedRange[0].setMilliseconds(this.startTime.getMilliseconds());

        this.selectedRange[1].setHours(this.endTime.getHours());
        this.selectedRange[1].setMinutes(this.endTime.getMinutes());
        this.selectedRange[1].setSeconds(this.endTime.getSeconds());
        this.selectedRange[1].setMilliseconds(this.endTime.getMilliseconds());

        this.selectedRangeChanged.emit(this.selectedRange);
    }

    resetToCurrentDate() {
        const startDate = new Date();
        startDate.setHours(0);
        startDate.setMinutes(0);
        startDate.setSeconds(0);
        startDate.setMilliseconds(0);

        const days7 = 24 * 7;
        const endDate = new Date();
        endDate.setHours(days7 + 23);
        endDate.setMinutes(59);
        endDate.setSeconds(59);
        endDate.setMilliseconds(999);

        this.selectedRange = [
            startDate,
            endDate
        ];

        this.startTime = this.selectedRange[0];
        this.endTime = this.selectedRange[1];
    }
}
