import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { SettingsActionTypes, SettingsOrderMinLoaded, LoadSettingsOrderMin, AddOrderMin, AddOrderMinSuccess, DeleteOrderMin, DeleteOrderMinSuccess, SettingsOrderWeightLoaded, DeleteOrderWeightSuccess, AddOrderWeightSuccess, DeleteOrderWeight, AddOrderWeight } from '../actions/settings.actions';
import { SettingsService } from 'src/app/core/services/settings/settings.service';
import { NotifierService } from 'angular-notifier';

@Injectable()
export class SettingsEffects {

    @Effect()
    settingsOrderMin$ = this.actions$.pipe(
        ofType<LoadSettingsOrderMin>(SettingsActionTypes.LoadSettingsOrderMin),
        mergeMap(() => this.settingsSvc.getOrderMinimum().pipe(
            map(settingsOrderMin =>
                new SettingsOrderMinLoaded({ settingsOrderMin }))


        ))
    );

    @Effect()
    addOrderMin$ = this.actions$.pipe(
        ofType<AddOrderMin>(SettingsActionTypes.AddOrderMin),
        mergeMap((action: AddOrderMin) => this.settingsSvc.addOrderMinimum(action.payload.orderMin).pipe(
            map((newOrderMin) =>
                new AddOrderMinSuccess({ newOrderMin })
            ),
            catchError(err => {
                this.notifier.notify('error', err.error.message);
                return err;
            })),
        )
    );

    @Effect()
    deleteOrderMin$ = this.actions$.pipe(
        ofType<DeleteOrderMin>(SettingsActionTypes.DeleteOrderMin),
        mergeMap((action) => this.settingsSvc.deleteOrderMinimum(action.payload.orderMinId)
            .pipe(map(() => action.payload.orderMinId))
        ),
        map((orderMinId) => new DeleteOrderMinSuccess({ orderMinId }))
    );

    @Effect()
    settingsOrderWeight$ = this.actions$.pipe(
        ofType<LoadSettingsOrderMin>(SettingsActionTypes.LoadSettingsOrderWeight),
        mergeMap(() => this.settingsSvc.getOrderWeight().pipe(
            map(settingsOrderWeight =>
                new SettingsOrderWeightLoaded({ settingsOrderWeight }))
        ))
    );

    @Effect()
    addOrderWeight$ = this.actions$.pipe(
        ofType<AddOrderWeight>(SettingsActionTypes.AddOrderWeight),
        mergeMap((action: AddOrderWeight) => this.settingsSvc.addOrderWeight(action.payload.orderWeight)),
        map((newOrderWeight) =>
            new AddOrderWeightSuccess({ newOrderWeight })
        )
    );

    @Effect()
    deleteOrderWeight$ = this.actions$.pipe(
        ofType<DeleteOrderWeight>(SettingsActionTypes.DeleteOrderWeight),
        mergeMap((action) => this.settingsSvc.deleteOrderWeight(action.payload.orderWeightId)
            .pipe(map(() => action.payload.orderWeightId))
        ),
        map((orderWeightId) => new DeleteOrderWeightSuccess({ orderWeightId }))
    );


    constructor(
        private actions$: Actions,
        private settingsSvc: SettingsService,
        private store: Store<AppState>,
        private notifier: NotifierService
    ) { }

}
