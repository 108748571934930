import { Component, OnInit, Input } from '@angular/core';
import { environment } from 'src/environments/environment';
import { PlatformType } from 'src/environments/platform-type.enum';

@Component({
    selector: 'app-icon-all-categories',
    templateUrl: './icon-all-categories.component.html',
    styleUrls: ['./icon-all-categories.component.scss']
})
export class IconAllCategoriesComponent implements OnInit {

    @Input() cssClass = 'icon--18';

    currentPlatform = environment.platformId;
    PlatformType = PlatformType;

    constructor() { }

    ngOnInit() {
    }

}
