import { Action } from '@ngrx/store';
import { CouponDetails } from 'src/app/core/models/cupons/coupon-details.model';
import { CouponsFilter } from 'src/app/core/models/cupons/coupon-filter.model';
import { CouponModel } from 'src/app/core/models/cupons/coupon.model';
import { SaveCouponModel } from 'src/app/core/models/cupons/save-coupon.model';

export enum CouponActionTypes {
    ApplyCoupon = '[Coupon] Apply Coupon',
    ApplyCouponError = '[Coupon] Apply Coupon Error',
    RemoveCoupon = '[Coupon] Apply remove',
    SaveCoupon = '[Coupon] Save Cupon',
    SaveCouponSuccess = '[Coupon] Save Cupon Success',
    SaveCouponFailed = '[Coupon] Save Cupon Failed',
    LoadCoupon = '[Coupon] Load Coupon',
    LoadCouponSuccess = '[Coupon] Load Coupon Success',
    LoadCouponDetails = '[Coupon] Load Coupon Details',
    LoadCouponDetailsSuccess = '[Coupon] Load Coupon Details Success',
    ActivateCoupon = '[Coupon] Activate Coupon',
    ActivateCouponSuccess = '[Coupon] Activate Coupon Success',
    ActivateCouponFailed = '[Coupon] Activate Coupon Failed',
    SaveEditCoupon = '[Coupon] Save Edit Cupon',
    SaveEditCouponSuccess = '[Coupon] Save Edit Cupon Success',
    SaveEditCouponFailed = '[Coupon] Save Edit Cupon Failed',
    ResetCouponErrors = '[Coupon] Reset Coupon errors list'
}

export class ApplyCoupon implements Action {
    readonly type = CouponActionTypes.ApplyCoupon;

    constructor(public payload: { coupon: any }) { }
}

export class ApplyCouponError implements Action {
    readonly type = CouponActionTypes.ApplyCouponError;

    constructor(public payload: { vendorSiteId: number, message: string }) { }
}

export class RemoveCoupon implements Action {
    readonly type = CouponActionTypes.RemoveCoupon;

    constructor(public payload: { vendorSiteId: number }) { }
}

export class SaveCoupon implements Action {
    readonly type = CouponActionTypes.SaveCoupon;

    constructor(public payload: SaveCouponModel) { }
}

export class SaveCouponSuccess implements Action {
    readonly type = CouponActionTypes.SaveCouponSuccess;

    constructor(public payload: CouponModel) { }
}

export class SaveCouponFailed implements Action {
    readonly type = CouponActionTypes.SaveCouponFailed;

    constructor(public payload: string) { }
}

export class LoadCoupon implements Action {
    readonly type = CouponActionTypes.LoadCoupon;

    constructor(public payload: CouponsFilter) { }
}

export class LoadCouponSuccess implements Action {
    readonly type = CouponActionTypes.LoadCouponSuccess;

    constructor(public payload: CouponDetails) { }
}

export class LoadCouponDetails implements Action {
    readonly type = CouponActionTypes.LoadCouponDetails;

    constructor(public payload: number) { }
}

export class LoadCouponDetailsSuccess implements Action {
    readonly type = CouponActionTypes.LoadCouponDetailsSuccess;

    constructor(public payload: SaveCouponModel) { }
}

export class ActivateCoupon implements Action {
    readonly type = CouponActionTypes.ActivateCoupon;

    constructor(public payload: { couponId: number, active: boolean }) { }
}

export class ActivateCouponSuccess implements Action {
    readonly type = CouponActionTypes.ActivateCouponSuccess;
}

export class ActivateCouponFailed implements Action {
    readonly type = CouponActionTypes.ActivateCouponFailed;
}

export class SaveEditCoupon implements Action {
    readonly type = CouponActionTypes.SaveEditCoupon;

    constructor(public payload: { couponId: number, coupon: SaveCouponModel }) { }
}

export class SaveEditCouponSuccess implements Action {
    readonly type = CouponActionTypes.SaveEditCouponSuccess;

    constructor(public payload: CouponModel) { }
}

export class SaveEditCouponFailed implements Action {
    readonly type = CouponActionTypes.SaveEditCouponFailed;
}

export class ResetCouponErrors implements Action {
    readonly type = CouponActionTypes.ResetCouponErrors;
}

export type CouponActions = ApplyCoupon
    | ApplyCouponError
    | RemoveCoupon
    | SaveCoupon
    | SaveCouponFailed
    | SaveCouponSuccess
    | LoadCoupon
    | LoadCouponSuccess
    | LoadCouponDetails
    | LoadCouponDetailsSuccess
    | ActivateCoupon
    | ActivateCouponSuccess
    | ActivateCouponFailed
    | SaveEditCoupon
    | SaveEditCouponFailed
    | SaveEditCouponSuccess
    | ResetCouponErrors;
