import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-icon-bread',
  templateUrl: './icon-bread.component.html',
  styleUrls: ['./icon-bread.component.scss']
})
export class IconBreadComponent implements OnInit {
  
  @Input() cssClass = 'icon--18';
  
  constructor() { }

  ngOnInit() {
  }

}
