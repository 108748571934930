import { PriceRequestActions, PriceRequestActionTypes } from '../actions/price-requests.actions';
import { PriceRequestModel } from 'src/app/core/models/price-request/price-request.model';
import { randomColor } from '../constants/global.contants';
import { PriceRequestSeller } from 'src/app/core/models/price-request/price-request-seller.interface';

export interface PriceRequestsState {
    priceRequests: Array<PriceRequestModel>;
    loading: boolean;
    vendorPriceRequests: Array<PriceRequestSeller>;
}

export const initialState: PriceRequestsState = {
    priceRequests: [],
    loading: false,
    vendorPriceRequests: [],
};

export function priceRequestsReducer(state = initialState, action: PriceRequestActions): PriceRequestsState {
    switch (action.type) {
        case PriceRequestActionTypes.LoadPriceRequests:
            return {
                ...state,
                loading: true
            };
        case PriceRequestActionTypes.LoadPriceRequestsSuccess:
            return {
                ...state,
                loading: false,
                priceRequests: action.payload.response.items
            };
        case PriceRequestActionTypes.ArchivePriceRequestSuccess:
            return {
                ...state,
                priceRequests: state.priceRequests.map((pr) => ({
                    ...pr,
                    archived: pr.id === action.payload.id ? true : pr.archived,
                    loading: pr.id === action.payload.id ? false : pr.loading
                }))
            };
        case PriceRequestActionTypes.UpdatePriceRequestSuccess:
            const actionPR = action.payload;
            return {
                ...state,
                priceRequests: state.priceRequests.map((pr) => pr.id === actionPR.id ? actionPR : pr)
            };

        case PriceRequestActionTypes.LoadVendorPriceRequestSuccess:
            return {
                ...state,
                vendorPriceRequests: action.payload.vendorDetails.map(v => ({
                    ...v,
                    randomColor: randomColor()
                }))
            };
        case PriceRequestActionTypes.RequestPriceVendorSuccess:
            const newVPR = state.vendorPriceRequests.map((pr) => ({
                ...pr,
                isApproved: pr.vendorId === action.payload.vendorId ? true : pr.isApproved,
                requestDate: pr.vendorId === action.payload.vendorId ? action.payload.applyDate : pr.requestDate,
            }))
            return {
                ...state,
                vendorPriceRequests: newVPR
            };
        default:
            return {
                ...state
            };
    }
}
