import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { AppState } from 'src/app/app.reducer';
import { Store, select } from '@ngrx/store';
import { ToggleInfobar } from 'src/app/shared/actions/infobar.actions';
import * as moment from 'moment';
import { ProductUploadResponseModel } from 'src/app/core/models/product/upload-response.model';
import { getUploadSummary, getUploadLoading } from 'src/app/shared/selectors/product.selector';
import { ProductUploadModel } from 'src/app/core/models/product/product-upload.model';
import { ProductPriceLevelModel } from 'src/app/core/models/product/product-price-level.model';
import { UploadPrices, UploadPricesFinish } from 'src/app/shared/actions/product.actions';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UploadStepEnum } from 'src/app/core/models/product/upload-step.enum';
import { FileSummaryType } from 'src/app/core/models/product/file-summary-type.enum';
import { SaveUploadModel } from 'src/app/core/models/product/save-upload.model';
import { InfobarState } from 'src/app/shared/reducers/infobar.reducer';

@Component({
    selector: 'app-upload-products',
    templateUrl: './upload-products.component.html',
    styleUrls: ['./upload-products.component.scss']
})
export class UploadProductsComponent implements OnInit, OnDestroy {
    @Input() infobarConfigs: InfobarState;

    priceLevelList: Array<ProductPriceLevelModel>;
    selectedPriceLevelId: number;
    uploadPricesSummary: ProductUploadResponseModel;
    form: FormGroup;
    uploadStep = UploadStepEnum;
    step = UploadStepEnum.UPLOAD;
    fileSummaryType = FileSummaryType;
    makeZeroPricedUnavailable = true;
    loading: boolean;


    constructor(
        private store: Store<AppState>,
        private formBuilder: FormBuilder
    ) { }

    ngOnDestroy(): void {
        this.tryAgain();
    }

    ngOnInit() {
        this.priceLevelList = this.infobarConfigs.params.priceLevels;
        this.selectedPriceLevelId = this.infobarConfigs.params.selectedPriceLevelId;

        this.store.pipe(select(getUploadSummary)).subscribe((uploadPricesSummary: ProductUploadResponseModel) => {
            if (uploadPricesSummary) {
                this.uploadPricesSummary = uploadPricesSummary;
                this.step = this.uploadStep.CONFIRM;
            }
        });

        this.store.pipe(select(getUploadLoading)).subscribe(uploadLoading => {
            this.loading = uploadLoading;
        });

        this.createForm();
    }

    uploadProducts() {
        if (!this.form.valid) {
            return;
        }

        const uploadPrices = new ProductUploadModel();
        uploadPrices.file = this.form.value.file;
        uploadPrices.entityId = this.form.value.priceLevel.id;
        uploadPrices.entityTypeId = this.form.value.priceLevel.type;

        this.store.dispatch(new UploadPrices(uploadPrices));
    }

    createForm() {
        this.form = this.formBuilder.group({
            priceLevel: [this.priceLevelList.find(priceLevel => priceLevel.id === this.selectedPriceLevelId), Validators.required],
            file: [null, Validators.required]
        });
    }

    onSelect(event: any) {
        this.form.controls.file.setValue(event.addedFiles[0]);
        this.form.updateValueAndValidity();
        this.form.markAsDirty();
    }

    onRemove() {
        this.form.controls.file.reset();
        this.form.updateValueAndValidity();
    }

    getDate() {
        return moment().format('l');
    }

    getSummary(type: FileSummaryType) {
        return this.uploadPricesSummary.summary.filter(summary => summary !== null).find(summary => summary.type === type);
    }

    tryAgain() {
        this.step = this.uploadStep.UPLOAD;
        this.uploadPricesSummary = undefined;
        this.onRemove();
    }

    finalize() {
        const confirmUpload = new SaveUploadModel();
        confirmUpload.batchId = this.uploadPricesSummary.batchId;
        confirmUpload.entityId = this.uploadPricesSummary.entityId;
        confirmUpload.entityTypeId = this.uploadPricesSummary.entityTypeId;
        confirmUpload.makeZeroPricedUnavailable = this.makeZeroPricedUnavailable;

        this.store.dispatch(new UploadPricesFinish(confirmUpload));
    }
}
