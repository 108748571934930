import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-icon-edit-new',
    templateUrl: './icon-edit-new.component.html'
})
export class IconEditNewComponent implements OnInit {

    @Input() cssClass = '';

    constructor() { }

    ngOnInit() {
    }

}