import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AppState } from 'src/app/app.reducer';
import { select, Store } from '@ngrx/store';
import { LoadSettingsOrderMin, AddOrderMin, DeleteOrderMin } from '../../actions/settings.actions';
import { Observable } from 'rxjs';
import { loadingSettingsOrderMin, settingsOrderMin } from '../../selectors/settings.selector';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { NotifierService } from 'angular-notifier';
import { filter, tap } from 'rxjs/operators';
import { currentSiteID } from '../../selectors/auth.selector';
import { OrderSettingsSaveModel } from 'src/app/core/models/order/order-settings-save.model';
import { OrderSettingsMinimum } from 'src/app/core/models/order/order-settings-minimum.model';

@Component({
    selector: 'app-settings-order-minimum',
    templateUrl: './settings-order-minimum.component.html',
    styleUrls: ['./settings-order-minimum.component.scss']
})
export class SettingsOrderMinimumComponent implements OnInit {

    editOrderMinForm: FormGroup;
    addOrderMinForm: FormGroup;

    showAddMinSettings = false;
    isEditActions = false;
    settingsOrderMin: Array<OrderSettingsMinimum>;
    loading$: Observable<boolean>;

    currentSiteId: number;
    isAcceptOrder = true;

    constructor(
        private fb: FormBuilder,
        private store: Store<AppState>,
        private dialog: MatDialog,
        private notifierSvc: NotifierService) {
    }

    ngOnInit() {
        this.editOrderMinForm = this.fb.group({
            minAmount: ['', [Validators.required]],
            deliveryCharge: ['', [Validators.required]],
        });

        this.addOrderMinForm = this.fb.group({
            minAmount: ['', [Validators.required]],
            deliveryCharge: ['', [Validators.required]],
        });

        this.loading$ = this.store.select(loadingSettingsOrderMin);
        this.store.pipe(select(settingsOrderMin), filter(data => !!data)).subscribe(data => {
            this.settingsOrderMin = data;
        });
        this.store.select(currentSiteID)
            .subscribe(() => {
                this.store.dispatch(new LoadSettingsOrderMin());
            });
    }

    get editForm() { return this.editOrderMinForm.controls; }
    get editFormVal() { return this.editOrderMinForm.value; }
    get addForm() { return this.addOrderMinForm.controls; }
    get addFormVal() { return this.addOrderMinForm.value; }

    // add new entry
    onAddMinSettings() {
        this.showAddMinSettings = true;
        this.isAcceptOrder = true;
    }

    onAddRevert() {
        this.addOrderMinForm.reset();
        this.showAddMinSettings = !this.showAddMinSettings;
    }

    onAddSave() {
        if (this.addOrderMinForm.invalid) {
            return;
        }
        if (isNaN(this.addFormVal.minAmount)) {
            this.addForm.minAmount.setErrors({ 'invalid': true });
            return;
        }
        if (isNaN(this.addFormVal.deliveryCharge)) {
            this.addForm.deliveryCharge.setErrors({ 'invalid': true });
            return;
        }

        const orderMin = {
            id: -1,
            minAmount: this.addFormVal.minAmount,
            deliveryCharge: this.addFormVal.deliveryCharge,
            acceptOrder: this.isAcceptOrder
        };

        this.store.dispatch(new AddOrderMin({ orderMin }));

        this.addOrderMinForm.reset();
        this.showAddMinSettings = false;
    }

    // edit settings
    onEditOrderMin(settings: OrderSettingsMinimum) {
        if (this.isEditActions) {
            const confirmRef = this.dialog.open(ConfirmationModalComponent, {
                data: {
                    message: `Are you sure you want to cancel the previous edit ?`
                },
            });
            confirmRef.afterClosed().subscribe(isConfirmed => {
                if (isConfirmed) {
                    this.settingsOrderMin.forEach((s) => {
                        s.showEditActions = false;
                    });
                    settings.showEditActions = !settings.showEditActions;
                }
            });
        } else {
            this.isEditActions = true;
            settings.showEditActions = !settings.showEditActions;
            this.editForm.minAmount.setValue(settings.minAmount);
            this.editForm.deliveryCharge.setValue(settings.deliveryCharge);
            this.editOrderMinForm.updateValueAndValidity();
        }
    }

    onEditSave(settings) {
        if (!this.editFormVal.minAmount) {
            this.editForm.minAmount.setValue(settings.minAmount);
        }
        if (!this.editFormVal.deliveryCharge) {
            this.editForm.deliveryCharge.setValue(settings.deliveryCharge);
        }

        if (this.editOrderMinForm.invalid) {
            return;
        }

        if (isNaN(this.editFormVal.minAmount)) {
            this.editForm.minAmount.setErrors({ 'invalid': true });
            return;
        }
        if (isNaN(this.editFormVal.deliveryCharge)) {
            this.editForm.deliveryCharge.setErrors({ 'invalid': true });
            return;
        }

        const orderMin: OrderSettingsSaveModel = {
            id: settings.id,
            minAmount: this.editFormVal.minAmount,
            deliveryCharge: this.editFormVal.deliveryCharge,
            acceptOrder: settings.acceptOrder
        };

        this.store.dispatch(new AddOrderMin({ orderMin }));
        this.editOrderMinForm.reset();
        this.isEditActions = false;
        settings.showEditActions = false;
        this.showAddMinSettings = false;
    }

    onRevert(settings) {
        this.editOrderMinForm.reset();
        this.isEditActions = false;
        settings.showEditActions = !settings.showEditActions;
    }

    onDelete(orderMinId: number) {
        const confirmRef = this.dialog.open(ConfirmationModalComponent, {
            data: {
                message: `Are you sure you want to delete this order minimum ?`
            },
        });
        confirmRef.afterClosed().subscribe(isConfirmed => {
            if (isConfirmed) {
                this.store.dispatch(new DeleteOrderMin({ orderMinId }));
                this.isEditActions = false;
            }
        });
    }
    onAcceptOrder(settings) {
        if (settings.showEditActions) {
            settings.acceptOrder = !settings.acceptOrder;
        }
    }
    addAcceptOrder() {
        if (this.isAcceptOrder) {
            this.addForm.deliveryCharge.setValidators(null);
            this.addForm.deliveryCharge.reset(0);
        } else {
            this.addForm.deliveryCharge.setValidators(Validators.required);
        }
        this.addOrderMinForm.updateValueAndValidity();

        this.isAcceptOrder = !this.isAcceptOrder;
    }

}
