import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PaymentsService } from 'src/app/core/services/payments/payments.service';
import { PAYMENT_STATUS } from 'src/app/core/enums/payment-status.enum';
import { InfobarComponents } from 'src/app/core/enums/infobar-components.enum';
import { ToggleInfobar, UpdateTransaction } from '../../actions/infobar.actions';
import { AppState } from 'src/app/app.reducer';
import { Store } from '@ngrx/store';
import { NotifierService } from 'angular-notifier';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-capture-transaction-modal',
  templateUrl: './capture-transaction-modal.component.html',
  styleUrls: ['./capture-transaction-modal.component.scss']
})
export class CaptureTransactionModalComponent implements OnInit {
  
  formCapture: FormGroup;

  loading = false;

  constructor(
    public fb: FormBuilder,
    public store: Store<AppState>,
    public notifySvc: NotifierService,
    public paymentService: PaymentsService,
    public dialogRef: MatDialogRef<CaptureTransactionModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { amount: number, transactionId: number, orderNumber: string }
  ) { 
    this.formCapture = this.fb.group({
      amount: [data.amount, [Validators.required, Validators.max(data.amount), Validators.min(0.5)]]
    });
  }

  ngOnInit() {
  }

  get form() { return this.formCapture.controls; }

  onCapture() {
    if (this.formCapture.invalid) {
      return;
    }

    this.loading = true;
    this.paymentService.updateTransactionStatus(this.data.transactionId, PAYMENT_STATUS.Captured, this.formCapture.controls.amount.value)
      .subscribe((response: any) => {
        this.loading = false;
        this.store.dispatch(new ToggleInfobar({
          open: true,
          title: `#${response.transaction.id}`,
          componentsIds: [InfobarComponents.TRANSACTION],
          params: {
            [InfobarComponents.TRANSACTION]: response.transaction
          }
        }));
        
        this.store.dispatch(new UpdateTransaction({
          transaction: response.transaction
        }));

        this.notifySvc.show({
          type: 'success',
          message: 'Payment captured with success'
        });

        this.close();
    }, (errorResponse: HttpErrorResponse) => {
      this.loading = false;
      this.notifySvc.show({
        type: 'error',
        message: errorResponse.error.message
      });
    });
  }

  close() {
    this.dialogRef.close();
  }

}
