import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-icon-plumbing',
    templateUrl: './icon-plumbing.component.html',
    styleUrls: ['./icon-plumbing.component.scss']
})
export class IconPlumbingComponent implements OnInit {

    @Input() cssClass = 'icon--18';

    constructor() { }

    ngOnInit() {
    }

}
