import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-icon-edit-product-tab',
    templateUrl: './icon-edit-product-tab.component.html'
})
export class IconEditProductTabComponent implements OnInit {

    @Input() cssClass = '';

    constructor() { }

    ngOnInit() {
    }

}
