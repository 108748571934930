import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { PlatformType } from 'src/environments/platform-type.enum';

@Component({
    selector: 'app-icon-cupons',
    templateUrl: './icon-cupons.component.html',
    styleUrls: ['./icon-coupons.component.scss']
})
export class IconCuponsComponent implements OnInit {

    @Input() cssClass = 'icon--18';

    currentPlatform = environment.platformId;
    PlatformType = PlatformType;

    constructor() { }

    ngOnInit() {
    }

}
