import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { AppState } from 'src/app/app.reducer';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { BrandingService } from '../services/branding/branding.service';
import { LoadPlatformConfigurationSuccess } from 'src/app/shared/actions/branding.actions';
import { Platform } from '../models/branding/platform.model';

@Injectable()
export class PlatformConfigurationResolve implements Resolve<any> {
    constructor(
        private brandingService: BrandingService,
        private store: Store<AppState>,
        private router: Router
    ) { }

    resolve(
    ): Observable<any> | Promise<any> | any {
        document.body.classList.add('loading', 'loading--large');
        return this.brandingService.getPlatformConfiguration().subscribe((platform: Platform) => {
            document.body.classList.remove('loading', 'loading--large');
            this.store.dispatch(new LoadPlatformConfigurationSuccess(platform));

            if (window.location.pathname === '/vendors' && platform.branding.isVendorStore) {
                this.router.navigate(['/']);
            }

            return platform;
        }, () => {
            return false;
        });
    }
}
