import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import * as authSelectors from '../../shared/selectors/auth.selector';
import { UserPermissionGroup } from '../models/user-permission-group.model';

@Injectable({
    providedIn: 'root'
})
export class PermissionsGuard implements CanActivate {
    constructor(
        private store: Store<AppState>,
        private router: Router
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const routePermission = route.data[`permission`];

        this.store.select(authSelectors.userHasPermissions,
            { permissionGroup: routePermission.permissionGroup, permissionKey: routePermission.permissionKey })
            .subscribe((hasPermission: boolean) => {

                if (!hasPermission) {
                    this.router.navigate(['/home']);
                }
            });
        return true;
    }

}
