import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-icon-leaf',
    templateUrl: './icon-leaf.component.html'
})
export class IconLeafComponent implements OnInit {

    @Input() cssClass: string;
    @Input() fill: boolean;

    constructor() { }

    ngOnInit() {
    }

}
