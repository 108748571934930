import { Component, OnInit, Output, EventEmitter, Input, OnChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { LoadStatuses } from '../../actions/order.actions';
import { Observable } from 'rxjs';
import { selectStatuses } from '../../selectors/order.selector';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { map, tap } from 'rxjs/operators';
import { TemplateEnum } from 'src/app/core/enums/template.enum';
import { getThemeId } from '../../selectors/branding.selector';

@Component({
    selector: 'app-status-filter',
    templateUrl: './status-filter.component.html',
    styleUrls: ['./status-filter.component.scss']
})
export class StatusFilterComponent implements OnInit, OnChanges {

    statuses$: Observable<any[]>;
    private statuses = [];

    @Input() selectedStatuses: number[] = [];
    @Output() change = new EventEmitter<any>();

    template$: Observable<TemplateEnum>;
    templateEnum = TemplateEnum;

    constructor(
        private store: Store<AppState>
    ) { }

    ngOnInit() {
        this.template$ = this.store.select(getThemeId);
        this.store.dispatch(new LoadStatuses());
    }

    ngOnChanges() {
        this.statuses$ = this.store.select(selectStatuses)
            .pipe(
                map((statuses) => statuses.map((s) => ({
                    ...s,
                    isSelected: this.selectedStatuses.includes(s.id)
                }))),
                tap(statuses => this.statuses = statuses)
            );
    }

    onSelect($event: MatCheckboxChange, status) {
        if ($event.checked) {
            this.selectedStatuses.push(status.id);
        } else {
            const idx = this.selectedStatuses.findIndex(s => s === status.id);
            this.selectedStatuses.splice(idx, 1);
        }

        this.emitSelectedStatuses(this.selectedStatuses);
    }

    emitSelectedStatuses(selectedStatuses: Array<number>) {
        this.change.emit(selectedStatuses);
    }

    onNameClick(status, $event?) {
        if ($event) {
            $event.preventDefault();
        }

        this.change.emit([status.id]);
    }

    onSelectAll() {
        this.change.emit([]);
    }
}
