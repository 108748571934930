
import { Injectable } from '@angular/core';
import {
    Router, Resolve,
    ActivatedRouteSnapshot,
    RouterStateSnapshot
} from '@angular/router';
import { AppState } from 'src/app/app.reducer';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { SitesService } from '../services/sites/sites.service';
import { SitesLoaded } from 'src/app/shared/actions/site.actions';
import { Logout } from 'src/app/shared/actions/auth.actions';
import { SiteModel } from '../models/site.model';

@Injectable()
export class SitesResolve implements Resolve<any> {
    constructor(
        private service: SitesService,
        private store: Store<AppState>
    ) { }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        document.body.classList.add('loading', 'loading--large');
        return this.service.loadSites().subscribe((sites: Array<SiteModel>) => {
            document.body.classList.remove('loading', 'loading--large');
            this.store.dispatch(new SitesLoaded({ sites }));
            return sites;
        }, () => {
            document.body.classList.remove('loading', 'loading--large');
            this.store.dispatch(new Logout());
            return false;
        });
    }
}
