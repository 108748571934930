import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { VendorService } from 'src/app/core/services/vendor/vendor.service';
import { Router } from '@angular/router';
import { LoadVendors, VendorActionTypes, VendorsLoaded, SetVendorToFavorite, ManageRelation, ManageRelationSuccess, LoadCategories, LoadCategoriesSuccess } from '../actions/vendor.actions';
import { map, exhaustMap, withLatestFrom, tap } from 'rxjs/operators';
import { selectVendors } from '../selectors/vendor.selector';
import { searchReducer } from '../reducers/search.reducer';



@Injectable()
export class VendorEffects {

  @Effect()
  vendorEffect$ = this.actions$.pipe(
      ofType<LoadVendors>(VendorActionTypes.LoadVendorsAction),
      exhaustMap((action) => this.vendorSvc.getVendorsList(action.payload).pipe(
          map((res) => new VendorsLoaded ({ vendors: res }))
      ))
  );
  
  @Effect({ dispatch: false })
  setVendorFavorite$ = this.actions$.pipe(
      ofType<SetVendorToFavorite>(VendorActionTypes.SetVendorFavorite),
      exhaustMap((action) => this.vendorSvc.setVendorFavorite(action.payload.vendorId, action.payload.isFavorite)
        .pipe(
            map(() => this.vendorSvc.resetCache())
        )
      )
  );

  @Effect()
  manageRelation$ = this.actions$.pipe(
      ofType<ManageRelation>(VendorActionTypes.ManageRelationAction),
      exhaustMap((action) => this.vendorSvc.manageRelation(action.payload).pipe(
        tap((res) => this.vendorSvc.resetCache()),
        map((res) => new ManageRelationSuccess ({ response: res }))
      ))
  );

  @Effect()
  loadCategories$ = this.actions$.pipe(
      ofType<LoadCategories>(VendorActionTypes.LoadCategoriesAction),
      exhaustMap((action) => this.vendorSvc.getCategories()),
      map((categories: any[]) => new LoadCategoriesSuccess ({ categories }))
  );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private vendorSvc: VendorService,
  ) { }
}
