import { Component, OnInit, Input } from '@angular/core';
import { environment } from 'src/environments/environment';
import { PlatformType } from 'src/environments/platform-type.enum';

@Component({
    selector: 'app-icon-chat',
    templateUrl: './icon-chat.component.html',
    styleUrls: ['./icon-chat.component.scss']
})
export class IconChatComponent implements OnInit {

    @Input() cssClass = 'icon--18';

    currentPlatform = environment.platformId;
    PlatformType = PlatformType;

    constructor() { }

    ngOnInit() {
    }

}
