import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {RuleModel} from '../../models/rewards/rule.model';
import {ExchangeRateModel} from '../../models/rewards/token.model';

@Injectable({
    providedIn: 'root'
})
export class WalletService {

    constructor(private http: HttpClient) {}

    loadWallet() {
        return this.http.get(`${environment.api.content}/Wallet`);
    }

    loadWalletActivity(walletId: number, page: number, pageSize: number) {
        const params = new HttpParams().set('page', page.toString()).set('pageSize', pageSize.toString());
        return this.http.get(`${environment.api.content}/Wallet/${walletId}/transactions?` + params.toString());
    }

    loadRules() {
        return this.http.get(`${environment.api.content}/Wallet/rules`);
    }

    loadTokens() {
        return this.http.get(`${environment.api.content}/Wallet/tokens`);
    }

    saveRule(rule: RuleModel, isEditMode: boolean) {
        if (!isEditMode) {
            return this.http.post(`${environment.api.content}/Wallet/rules`, rule);
        }

        return this.http.put(`${environment.api.content}/Wallet/rules/${rule.id}`, rule);
    }

    loadTokenActivity(tokenId: number, page: number, pageSize: number) {
        const params = new HttpParams().set('page', page.toString()).set('pageSize', pageSize.toString());
        return this.http.get(`${environment.api.content}/Wallet/${tokenId}/seller_transactions?` + params.toString());
    }

    loadRuleById(ruleId: number) {
        return this.http.get(`${environment.api.content}/Wallet/rules/${ruleId}`);
    }

    enableRule(ruleId: number, isActive: boolean) {
        return this.http.put(`${environment.api.content}/Wallet/rules/${ruleId}/activation`, {isActive});
    }

    updateExchangeRate(exchangeRate: ExchangeRateModel) {
        return this.http.post(`${environment.api.content}/Wallet/update_exchange_rate`, exchangeRate);
    }

    loadWalletByToken(tokenId: number) {
        return this.http.get(`${environment.api.content}/Wallet/${tokenId}/payment`);
    }
}
