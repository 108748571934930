import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/core/services/authentication/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppState } from 'src/app/app.reducer';
import { Store } from '@ngrx/store';
import { Login } from 'src/app/shared/actions/auth.actions';
import { PageNavigationType } from 'src/app/core/enums/page-navigation.type.enum';

@Component({
    selector: 'app-auto-login',
    templateUrl: './auto-login.component.html',
    styleUrls: ['./auto-login.component.scss']
})
export class AutoLoginComponent implements OnInit {

    constructor(
        private authSvc: AuthenticationService,
        private currentRoute: ActivatedRoute,
        private store: Store<AppState>,
        private router: Router
    ) { }

    ngOnInit() {
        const { data, queryParams } = this.currentRoute.snapshot;
        this.authSvc.loginWithToken(queryParams.token, (data || {}).isOldVersion)
            .subscribe((loginResponse: any) => {
                this.store.dispatch(new Login({ loginResponse }));
                switch (loginResponse.pageNavigationType) {
                    case PageNavigationType.DASHBOARD:
                    // TODO: make sure this logic is fixed on the backend side.
                    // this.router.navigate(['/dashboard']);
                    // break;
                    case PageNavigationType.ORDERGUIDE:
                        this.router.navigate(['/order-guide']);
                        break;
                    case PageNavigationType.PAYMENTS:
                        this.router.navigate(['/payments']);
                        break;
                }
            });
    }

}
