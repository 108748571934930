import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-sort-selection-temp1',
    templateUrl: './sort-selection.temp1.component.html',
    styleUrls: ['./sort-selection.temp1.component.scss']
})
export class SortSelectionTemp1Component implements OnInit {

    @Input() options: any;
    @Input() sortOptions: any;
    @Output() clickOption: EventEmitter<any> = new EventEmitter<any>();

    showSortOptions: boolean;

    constructor() { }

    ngOnInit(): void {
    }

    sortOpened(isOpen: boolean) {
        this.showSortOptions = isOpen;
    }

    onClick(option: any) {
        this.clickOption.emit(option);
    }

}
