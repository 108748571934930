import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'containsFilter'
})
export class ContainsFilterPipe implements PipeTransform {

  transform(items: any, property: string, filter: any): any {
    if (!items || !filter) {
      return items;
    }

    return items.filter(i => i[property].toLowerCase().indexOf(filter.toLowerCase()) >= 0);
  }

}
