import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Coupon } from '../../models/coupon.model';
import { SaveCouponModel } from '../../models/cupons/save-coupon.model';
import { CouponsFilter } from '../../models/cupons/coupon-filter.model';
import { DatePipe } from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class CouponService {

    constructor(
        private http: HttpClient,
        private datePipe: DatePipe
    ) { }

    applyCoupon(coupon: Coupon) {
        return this.http.post(`${environment.api.content}/Coupon/apply_coupon`, {
            code: coupon.code,
            vendorSiteId: coupon.vendorSiteId
        });
    }

    removeCoupon(vendorSiteId: number) {
        return this.http.post(`${environment.api.content}/Coupon/remove_coupon/${vendorSiteId}`, vendorSiteId);
    }

    addCupon(saveCuponModel: SaveCouponModel) {
        return this.http.post(`${environment.api.content}/Coupon/add_coupon`, {
            ...saveCuponModel,
            startDate: this.datePipe.transform(saveCuponModel.startDate, 'MM/dd/yyyy HH:mm:ss a'),
            endDate: this.datePipe.transform(saveCuponModel.endDate, 'MM/dd/yyyy HH:mm:ss a')
        });
    }

    getCupons(couponsFilter: CouponsFilter) {
        return this.http.post(`${environment.api.content}/Coupon/search`, couponsFilter);
    }

    getCuponDetails(couponId: number) {
        return this.http.get(`${environment.api.content}/Coupon/${couponId}`);
    }

    activateCoupon(couponId: number, active: boolean) {
        return this.http.put(`${environment.api.content}/Coupon/${couponId}/set_active/${active}`, undefined);
    }

    saveEditCoupon(couponId: number, coupon: SaveCouponModel) {
        return this.http.put(`${environment.api.content}/Coupon/${couponId}`, {
            ...coupon,
            startDate: this.datePipe.transform(coupon.startDate, 'MM/dd/yyyy HH:mm:ss a'),
            endDate: this.datePipe.transform(coupon.endDate, 'MM/dd/yyyy HH:mm:ss a')
        });
    }
}
