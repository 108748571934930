import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { DeliveryDayModel } from '../../models/account/delivery-day.model';


@Injectable({
    providedIn: 'root'
})
export class DeliveryDateService {

    constructor(private http: HttpClient) { }

    loadDeliveryDates(): Observable<any> {
        return this.http.get(`${environment.api.content}/Settings/DeliveryDays/load`);
    }

    loadSpecialDeliveryDates(): Observable<any> {
        return this.http.get(`${environment.api.content}/Settings/DeliveryDaysSpecial/load`);
    }

    saveDeliveryDay(deliveryDay: { saveDefaults: boolean, deliveryDay: Array<DeliveryDayModel>; }) {
        return this.http.post(`${environment.api.content}/Settings/DeliveryDays/${deliveryDay.saveDefaults}/save`, deliveryDay.deliveryDay);
    }

    saveSpecialDeliveryDay(deliveryDay: DeliveryDayModel) {
        return this.http.post(`${environment.api.content}/Settings/DeliveryDaysSpecial/save`, deliveryDay);
    }

    updateSpecialDeliveryDayOnAllPriceLevels(specialDeliveryDayId: number): Observable<boolean> {
        return this.http.post<boolean>(
            `${environment.api.content}/Settings/DeliveryDaysSpecial/${specialDeliveryDayId}/copyToAllPriceLevels`,
            {}
        );
    }

    deleteSpecialDeliveryDayFromAllPriceLevels(specialDeliveryDayId: number): Observable<boolean> {
        return this.http.delete<boolean>(
            `${environment.api.content}/Settings/DeliveryDaysSpecial/${specialDeliveryDayId}/deleteAllPriceLevels`
        );
    }

    deleteSpecialDeliveryDay(specialDeliveryDayId: number) {
        return this.http.delete(`${environment.api.content}/Settings/DeliveryDaysSpecial/${specialDeliveryDayId}/delete`);
    }
}

