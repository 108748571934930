import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-icon-sort-recent',
  templateUrl: './icon-sort-recent.component.html',
  styleUrls: ['./icon-sort-recent.component.scss']
})
export class IconSortRecentComponent implements OnInit {

  @Input() cssClass = '';
  
  constructor() { }

  ngOnInit() {
  }

}
