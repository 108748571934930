import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LoadAllChannelsFilter } from '../../models/chat/load-all-channels.filter';
import { Observable } from 'rxjs';
import { ChannelFilter } from '../../models/chat/channel.filter';
import { ChatConversation } from '../../models/chat/chat-conversation.model';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ChatService {

    constructor(private http: HttpClient) { }

    loadAllChannels(loadAllChannelsFilter: LoadAllChannelsFilter): Observable<any> {
        return this.http.post(`${environment.api.content}/Communication/search`, loadAllChannelsFilter);
    }

    loadConversation(messagesFilter: ChannelFilter): Observable<any> {
        return this.http.post(`${environment.api.content}/Communication/load_channel`, messagesFilter)
            .pipe(map((data: ChatConversation) => {
                data.channel.entityId = messagesFilter.entityId;
                data.channel.entityTypeId = messagesFilter.entityTypeId;
                return data;
            }));
    }

    loadBuyerVendor(keyword: string): Observable<any> {
        return this.http.post(`${environment.api.content}/Communication/buyer_vendor_search`, { keyword });
    }

    generateChannelUUID(entityTypeId: number): Observable<any> {
        return this.http.post(`${environment.api.content}/Communication/${entityTypeId}/generate_channel`,
            undefined, { responseType: 'text' });
    }

    resetUnreadMessages(channelUuid: string): Observable<any> {
        return this.http.post(`${environment.api.content}/Communication/${channelUuid}/reset_unread_messages`, undefined);
    }
}

