import { InfobarActions, InfobarActionTypes } from '../actions/infobar.actions';
import { InfobarComponents } from 'src/app/core/enums/infobar-components.enum';
import { TabHeader } from 'src/app/core/models/product/tab-header.model';

export interface InfobarState {
    isOpen: boolean;
    title?: string;
    componentsIds: InfobarComponents[];
    params: {
        [id: string]: any
    };
    transaction: any;
    tabHeaders?: Array<TabHeader>;
    selectedTabId: number;
}

export const initialState: InfobarState = {
    isOpen: false,
    componentsIds: [],
    params: {},
    transaction: undefined,
    tabHeaders: undefined,
    selectedTabId: undefined
};

export function infobarReducer(state = initialState,
    action: InfobarActions): InfobarState {
    switch (action.type) {
        case InfobarActionTypes.ToggleInfobar:
            return {
                ...state,
                isOpen: action.payload.open,
                title: action.payload.title,
                componentsIds: action.payload.componentsIds,
                params: action.payload.params,
                tabHeaders: action.payload.tabHeaders,
                selectedTabId: action.payload.selectedTabId
            };
        case InfobarActionTypes.UpdateTransaction:
            return {
                ...state,
                transaction: action.payload.transaction
            };
        default:
            return state;
    }
}
