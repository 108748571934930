import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { tap, exhaustMap, map } from 'rxjs/operators';
import { LoadSites, SiteActionTypes, SitesLoaded } from '../actions/site.actions';
import { SitesService } from 'src/app/core/services/sites/sites.service';
import { UtilsService } from 'src/app/core/services/utils/utils.service';
import { LoadCountries, UtilsActionTypes, LoadStates, CountriesLoaded, StatesLoaded, LoadTimezones, TimezonesLoaded } from '../actions/utils.actions';

@Injectable()
export class UtilsEffects {

    @Effect()
    loadCountries$ = this.actions$.pipe(
        ofType<LoadCountries>(UtilsActionTypes.LoadCountries),
        exhaustMap(() => this.utilsSvc.getCountries().pipe(
            map((countries: any[]) => new CountriesLoaded({ countries }))
        ))
    );

    @Effect()
    loadStates$ = this.actions$.pipe(
        ofType<LoadStates>(UtilsActionTypes.LoadStates),
        exhaustMap((action) => this.utilsSvc.getStates(action.payload.countryId).pipe(
            map((states: any[]) => new StatesLoaded({ countryId: action.payload.countryId, states }))
        ))
    );

    @Effect()
    timezones$ = this.actions$.pipe(
        ofType<LoadTimezones>(UtilsActionTypes.LoadTimezones),
        exhaustMap(() => this.utilsSvc.getTimezones().pipe(
            map((timezones: any[]) => new TimezonesLoaded({ timezones }))
        ))
    );

    constructor(
        private actions$: Actions,
        private utilsSvc: UtilsService
    ) { }

}