import {createSelector, select} from '@ngrx/store';

export const selectCartState = state => state.cart;

export const cartSummary = createSelector(
    selectCartState,
    cart => cart.summary
);

export const loadingCartSummary = createSelector(
    selectCartState,
    cart => cart.loadingSummary
);

export const selectCart = createSelector(
    selectCartState,
    cart => cart.cartDetails
);

export const selectLoadingCart = createSelector(
    selectCartState,
    cart => cart.loadingCart
);

export const selectOpenSuccesModal = createSelector(
    selectCartState,
    cart => cart.openSuccesModalData
);

export const getSavingTips = createSelector(
    selectCartState,
    cart => cart.savingTips
);

export const getCartVendor = createSelector(
    selectCartState,
    (state, props) => {
        return state.cartDetails.find(cartDetails => cartDetails.id === props.vendorId);
    }
);

export const getCartVendors = createSelector(
    selectCartState,
    (state) => {
        return state.cartDetails;
    }
);

export const getAuctionShoppingCart = createSelector(
    selectCartState,
    state => state.auctionShoppingCart
);

export const getAuctionCartVendor = createSelector(
    selectCartState,
    (state, props) => {
        return state.auctionShoppingCart.find(c => c.sellerId === props.vendorId);
    }
);
