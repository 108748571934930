import { Pipe, PipeTransform } from '@angular/core';
import { ORDER_HISTORY_ITEM_CHANGE } from '../../../core/enums/order-history-item-change.enum';

@Pipe({
  name: 'countOrderItems'
})
export class CountOrderItemsPipe implements PipeTransform {

  transform(value: any[]): unknown {
    return value ? value.filter(i => i.historyChange !== ORDER_HISTORY_ITEM_CHANGE.REMOVED).length : 0;
  }

}
