import { AuctionItemModel } from "src/app/modules/auctions/models/auction-item.model";
import { AuctionOfferModel } from "src/app/modules/auctions/models/auction-offer.model";
import { AuctionsResponseModel } from "src/app/modules/auctions/models/auctions-response.model";
import { OfferStatusEnum } from "src/app/modules/auctions/models/offer-status.enum";
import { AuctionActions, AuctionActionTypes } from "../actions/auction.actions";

export interface AuctionState {
    loading: boolean;
    auctions: AuctionsResponseModel;
    saving: boolean;
    auctionOffers: Array<AuctionOfferModel>;
}

export const initialState: AuctionState = {
    loading: false,
    auctions: undefined,
    saving: false,
    auctionOffers: undefined
};

export function auctionReducer(state = initialState, action: AuctionActions): AuctionState {
    switch (action.type) {
        case AuctionActionTypes.LoadAuction:
            return {
                ...state,
                loading: true
            };
        case AuctionActionTypes.LoadAuctionSuccess:
            return {
                ...state,
                loading: false,
                auctions: action.payload
            };
        case AuctionActionTypes.SaveAuctionItem:
            return {
                ...state,
                saving: true
            };
        case AuctionActionTypes.SaveAuctionItemSuccess:
            if (action.payload.isEditMode) {
                const index = state.auctions.items.findIndex(auctionItem => auctionItem.id === action.payload.auctionItem.id);
                const auctionItemStatus = state.auctions.items[index].status;
                action.payload.auctionItem.status = auctionItemStatus;
                state.auctions.items.splice(index, 1, action.payload.auctionItem);
            } else {
                state.auctions.items.splice(0, 0, action.payload.auctionItem);
            }

            return {
                ...state,
                saving: false,
                auctions: {
                    ...state.auctions,
                    items: [...state.auctions.items]
                }
            };
        case AuctionActionTypes.SaveAuctionItemFailed:
            return {
                ...state,
                saving: false
            };
        case AuctionActionTypes.LoadAuctionOffers:
            return {
                ...state,
                loading: true
            };
        case AuctionActionTypes.LoadAuctionOffersSuccess:
            return {
                ...state,
                loading: false,
                auctionOffers: action.payload
            };
        case AuctionActionTypes.MakeOffer:
            return {
                ...state,
                saving: true
            };
        case AuctionActionTypes.MakeOfferSuccess:
            return {
                ...state,
                saving: false
            };
        case AuctionActionTypes.MakeOfferFailed:
            return {
                ...state,
                saving: false
            };
        case AuctionActionTypes.ApproveOffer:
            return {
                ...state,
                saving: true
            };
        case AuctionActionTypes.ApproveOfferSuccess:
            return {
                ...state,
                auctionOffers: state.auctionOffers.map((offer: AuctionOfferModel) => ({
                    ...offer,
                    statusId: offer.auctionItemId === action.payload.auctionItemId && offer.offerId === action.payload.offerId ? OfferStatusEnum.APPROVED : offer.statusId
                })),
                saving: false
            };
        case AuctionActionTypes.ApproveOfferFailed:
            return {
                ...state,
                saving: false
            };
        case AuctionActionTypes.RejectOffer:
            return {
                ...state,
                auctionOffers: state.auctionOffers.map((offer: AuctionOfferModel) => ({
                    ...offer,
                    statusId: offer.auctionItemId === action.payload.auctionItemId && offer.offerId === action.payload.offerId ? OfferStatusEnum.REJECTED : offer.statusId
                })),
                saving: true
            };
        case AuctionActionTypes.RejectOfferSuccess:
            return {
                ...state,
                saving: false
            };
        case AuctionActionTypes.RejectOfferFailed:
            return {
                ...state,
                saving: false
            };
        default:
            return state;
    }
}
