import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-icon-order-details',
  templateUrl: './icon-order-details.component.html',
  styleUrls: ['./icon-order-details.component.scss']
})
export class IconOrderDetailsComponent implements OnInit {

  @Input() cssClass = '';
  
  constructor() { }

  ngOnInit() {
  }

}
