import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-icon-check-no-bg',
  templateUrl: './icon-check-no-bg.component.html',
  styleUrls: ['./icon-check-no-bg.component.scss']
})
export class IconCheckNoBgComponent implements OnInit {

  @Input() cssClass = '';
  
  constructor() { }

  ngOnInit() {
  }

}
