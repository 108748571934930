import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-icon-wallet',
    templateUrl: './icon-wallet.component.html',
    styleUrls: ['./icon-wallet.component.scss']
})
export class IconWalletComponent implements OnInit {

    @Input() cssClass: string;

    constructor() { }

    ngOnInit(): void {
    }

}
