import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { SelectConfigs } from '../../models/select-configs.model';
import { SelectItemModel } from '../../models/select-item.model';

@Component({
    selector: 'app-trad-select',
    templateUrl: './trad-select.component.html',
    styleUrls: ['./trad-select.component.scss']
})
export class TradSelectComponent implements OnInit, OnChanges {

    @Input() selectData: Array<SelectItemModel>;
    @Input() selectConfigs: SelectConfigs;
    @Input() selectedValue: SelectItemModel;

    @Output() selectChange: EventEmitter<SelectItemModel> = new EventEmitter<SelectItemModel>();

    displayData: Array<SelectItemModel>;
    search$ = new Subject<string>();
    searchInput: string;

    constructor() { }

    ngOnInit(): void {
        this.displayData = this.selectData;

        this.search$.subscribe(() => {
            this.displayData = this.selectData.filter(item => item.itemName.toUpperCase().includes(this.searchInput.toUpperCase()));
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.selectedValue?.currentValue) {
            this.selectedValue = changes?.selectedValue?.currentValue;
        }

        this.displayData = this.selectData;
    }

    selectionChange(selectItem: SelectItemModel): void {
        this.selectedValue = selectItem;
        this.selectChange.emit(selectItem);
    }

}
