import { Coupon } from '../coupon.model';
import { VendorItemModel } from '../vendor/vendor-item.model';
import { VendorModel } from '../vendor/vendor.model';
import { PaymentMethodModel } from './payment-method.model';
import { Carrier } from '../shipping/carrier.model';
import { DeliveryDayModel } from '../account/delivery-day.model';

export class GeneralCartModel {
    id: number;
    shoppingCartId?: number;
    productId: number;
    productName?: string;
    imageName?: string;
    imageUrl?: string;
    quantity: number;
    price: number;
    deliveryFee: number;
    sellerId: number;
    sellerName?: string;
    shippingAddressId: number;
    billingAddressId: number;
    timezoneName?: string;
    isStore: boolean;
    timezoneId: number;
    merchantAccount?: string;
    commission: number;
    gatewayId: number;
    notes?: string;
    totalPrice: number;
    subTotalPrice: number;
    acronym?: string;
    acceptedPaymentMethods?: Array<PaymentMethodModel>;
    canSubmitOrder: boolean;
    companyTypeId: number;
    coupon: Coupon;
    customerAccountId: number;
    deliveryDate: Date;
    deliveryDateFromTime: Date;
    deliveryDateToTime: Date;
    deliveryFromTime: string;
    deliveryToTime: string;
    deliveryDays: DeliveryDayModel[];
    specialDeliveryDays: DeliveryDayModel[];
    discount: number;
    errorMessage: string;
    hasCouponAddOn: boolean;
    isBuyerOnHold: boolean;
    isExpanded?: boolean;
    isFavorite: boolean;
    isOnHold: boolean;
    items: Array<GeneralCartItemsModel>;
    loading: boolean;
    name: string;
    paymentType: number;
    randomColor: string;
    siteManagerId: number;
    slideToCheckout: boolean;
    slideToShipping: boolean;
    validationMessages: Array<string>;
    vendorAccountId: number;
    isTipsEnabled: boolean;
    tip: {
        price: number;
        productId: number;
        quantity: number;
    };
    isAuction: boolean;
    availableShippingMethods: Carrier[];
    shippingMethodId: number;
    shippingReferenceId?: string;
}

export class GeneralCartItemsModel {
    allowFractionOrdering: boolean;
    categoryId: number;
    categoryName: string;
    customName: string;
    discount: number;
    discountPrice: number;
    imageUrl: string;
    internalSku: null;
    name: string;
    otherVendorItems: Array<VendorItemModel>;
    otherVendors: Array<VendorModel>;
    price: number;
    priceUom: number;
    privateProduct: boolean;
    productDeliveryTemplateId: number;
    productId: number;
    quantity: number;
    shoppingCartId: number;
    shoppingCartItemID: number;
    shortDescription: string;
    sku: string;
    totalPrice: number;
    uomDescription: string;
    uomName: string;
    uomSize: string;
    vendor: VendorModel;
    vendorDeliveryTemplateId: number;
    vendorDisplayName: string;
    vendorId: number;
    vendorName: string;
    vendorSku: string;
    weight: number;
}
