import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-icon-circle-cancel',
    templateUrl: './icon-circle-cancel.component.html',
    styleUrls: ['./icon-circle-cancel.component.scss']
})
export class IconCircleCancelComponent implements OnInit {

    @Input() cssClass: string;

    constructor() {}

    ngOnInit(): void {
    }

}
