import { UtilsActionTypes, UtilsAction } from '../actions/utils.actions';

export interface UtilsState {
    countries: any[],
    states: Map<number, any[]>,
    timezones: any[],
}

export const initialState: UtilsState = {
    countries: [],
    states: new Map<number, any[]>(),
    timezones: [],
};

export function utilsReducer(
    state = initialState,
    action: UtilsAction): UtilsState {
    switch (action.type) {
        case UtilsActionTypes.CountriesLoaded:
            return {
                ...state,
                countries: action.payload.countries
            };
        case UtilsActionTypes.StatesLoaded:
            const newStates = state.states;
            newStates.set(action.payload.countryId, action.payload.states);

            return {
                ...state,
                states: newStates
            };
        case UtilsActionTypes.TimezonesLoaded:
            return {
                ...state,
                timezones: action.payload.timezones
            };
        default:
            return state;
    }
}