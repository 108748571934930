import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/app.reducer';
import { WalletModel } from 'src/app/core/models/rewards/wallet.model';
import { LoadWallet } from '../../actions/rewards.actions';
import { getWallet } from '../../selectors/rewards.selector';
import { RewardsPointsLearnModalComponent } from '../rewards-points-learn-modal/rewards-points-learn-modal.component';

@Component({
    selector: 'app-rewards-points',
    templateUrl: './rewards-points.component.html',
    styleUrls: ['./rewards-points.component.scss']
})
export class RewardsPointsComponent implements OnInit {

    @Input() isFromSidebar: boolean;
    wallet: WalletModel;

    constructor(
        private store: Store<AppState>,
        private dialog: MatDialog
    ) { }

    ngOnInit(): void {
        this.store.pipe(select(getWallet)).subscribe(data => {
            if (data) {
                this.wallet = data;
            }
        });
        this.store.dispatch(new LoadWallet());
    }

    showRewardsModal() {
        this.dialog.open(RewardsPointsLearnModalComponent, {
            maxWidth: '100vw',
            width: '100%',
            height: '100%'
        });
    }

}
