import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-icon-orders-amast',
    templateUrl: './icon-orders-amast.component.html'
})
export class IconOrdersAmastComponent implements OnInit {

    @Input() cssClass = '';

    constructor() { }

    ngOnInit() {
    }

}
