import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-icon-dont-save',
  templateUrl: './icon-dont-save.component.html',
  styleUrls: ['./icon-dont-save.component.scss']
})
export class IconDontSaveComponent implements OnInit {
  
  @Input() cssClass = '';
  
  constructor() { }

  ngOnInit() {
  }

}
