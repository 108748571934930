import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-icon-cake',
  templateUrl: './icon-cake.component.html',
  styleUrls: ['./icon-cake.component.scss']
})
export class IconCakeComponent implements OnInit {

  @Input() cssClass = 'icon--18';

  constructor() { }

  ngOnInit(): void {
  }

}
