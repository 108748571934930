import { Action } from '@ngrx/store';
import { AuctionItemModel } from 'src/app/modules/auctions/models/auction-item.model';
import { AuctionSearch } from 'src/app/core/models/auction/auction-search.model';
import { AuctionsResponseModel } from 'src/app/modules/auctions/models/auctions-response.model';
import { AuctionOfferModel } from 'src/app/modules/auctions/models/auction-offer.model';
import { DeliveryOptionEnum } from 'src/app/modules/auctions/models/delivery-options.enum';

export enum AuctionActionTypes {
    LoadAuction = '[Auction] Load.',
    LoadAuctionSuccess = '[Auction] Auctions Loaded success.',
    AddToCartFailed = '[Auction] AddToCartFailed',
    SaveAuctionItem = '[Auction] Save Auction Item',
    SaveAuctionItemSuccess = '[Auction] Save Auction Item Success',
    SaveAuctionItemFailed = '[Auction] Save Auction Item Failed',
    AddToCart = '[Auction] AddToCart',
    AddToCartSuccess = '[Auction] AddToCartSuccess',
    LoadAuctionOffers = '[Auction] LoadAuctionOffers',
    LoadAuctionOffersSuccess = '[Auction] LoadAuctionOffers Success',
    MakeOffer = '[Auction] Make Offer',
    MakeOfferSuccess = '[Auction] Make Offer Success',
    MakeOfferFailed = '[Auction] Make Offer Failed',
    ApproveOffer = '[Auction] ApproveOffer Offer',
    ApproveOfferSuccess = '[Auction] Approve Offer Offer Success',
    ApproveOfferFailed = '[Auction] Approve Offer Offer Failed',
    RejectOffer = '[Auction] Reject Offer Offer',
    RejectOfferSuccess = '[Auction] Reject Offer Offer Success',
    RejectOfferFailed = '[Auction] Reject Offer Offer Failed'
}

export class LoadAuctions implements Action {
    readonly type = AuctionActionTypes.LoadAuction;

    constructor(public payload: AuctionSearch) { }
}

export class LoadAuctionSuccess implements Action {
    readonly type = AuctionActionTypes.LoadAuctionSuccess;

    constructor(public payload: AuctionsResponseModel) { }
}

export class AddToCart implements Action {
    readonly type = AuctionActionTypes.AddToCart;

    constructor(public payload: { auctionItemId: number, quantity: number; }) { }
}

export class AddToCartSuccess implements Action {
    readonly type = AuctionActionTypes.AddToCartSuccess;

    constructor(public payload: { auctionItemId?: number, quantity: number; }) { }
}

export class SaveAuctionItem implements Action {
    readonly type = AuctionActionTypes.SaveAuctionItem;

    constructor(public payload: { images: Array<File>, auctionItem: AuctionItemModel, isEditMode: boolean; }) { }
}

export class SaveAuctionItemSuccess implements Action {
    readonly type = AuctionActionTypes.SaveAuctionItemSuccess;

    constructor(public payload: { auctionItem: AuctionItemModel, isEditMode: boolean; }) { }
}

export class SaveAuctionItemFailed implements Action {
    readonly type = AuctionActionTypes.SaveAuctionItemFailed;
}

export class MakeOffer implements Action {
    readonly type = AuctionActionTypes.MakeOffer;

    constructor(public payload: { auctionItemId: number, deliveryTypeId: DeliveryOptionEnum, price: number; }) { }
}

export class MakeOfferSuccess implements Action {
    readonly type = AuctionActionTypes.MakeOfferSuccess;
}

export class MakeOfferFailed implements Action {
    readonly type = AuctionActionTypes.MakeOfferFailed;
}

export class LoadAuctionOffers implements Action {
    readonly type = AuctionActionTypes.LoadAuctionOffers;

    constructor(public payload: number) { }
}

export class LoadAuctionOffersSuccess implements Action {
    readonly type = AuctionActionTypes.LoadAuctionOffersSuccess;

    constructor(public payload: Array<AuctionOfferModel>) { }
}

export class ApproveOffer implements Action {
    readonly type = AuctionActionTypes.ApproveOffer;

    constructor(public payload: { auctionItemId: number, offerId: number; }) { }
}

export class ApproveOfferSuccess implements Action {
    readonly type = AuctionActionTypes.ApproveOfferSuccess;

    constructor(public payload: { auctionItemId: number, offerId: number; }) { }
}

export class ApproveOfferFailed implements Action {
    readonly type = AuctionActionTypes.ApproveOfferFailed;
}

export class RejectOffer implements Action {
    readonly type = AuctionActionTypes.RejectOffer;

    constructor(public payload: { auctionItemId: number, offerId: number; }) { }
}

export class RejectOfferSuccess implements Action {
    readonly type = AuctionActionTypes.RejectOfferSuccess;

    constructor(public payload: { auctionItemId: number, offerId: number; }) { }
}

export class RejectOfferFailed implements Action {
    readonly type = AuctionActionTypes.RejectOfferFailed;
}

export type AuctionActions =
    | LoadAuctions
    | LoadAuctionSuccess
    | SaveAuctionItem
    | SaveAuctionItemSuccess
    | SaveAuctionItemFailed
    | AddToCart
    | AddToCartSuccess
    | LoadAuctionOffers
    | LoadAuctionOffersSuccess
    | MakeOffer
    | MakeOfferSuccess
    | MakeOfferFailed
    | ApproveOffer
    | ApproveOfferSuccess
    | ApproveOfferFailed
    | RejectOffer
    | RejectOfferFailed
    | RejectOfferSuccess;
