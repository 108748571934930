import { Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { SiteModel } from '../../models/site.model';
import { AddressModel } from '../../models/customer/address.model';
import { SaveSiteModel } from '../../models/site/save-site.model';

@Injectable({
    providedIn: 'root'
})
export class SitesService {

    constructor(
        private http: HttpClient
    ) { }

    loadSites(): Observable<Array<SiteModel>> {
        return this.http.get<Array<SiteModel>>(`${environment.api.content}/Site`);
    }

    loadSitesBase(includeBase?: boolean): Observable<any[]> {
        return this.http.get<any[]>(`${environment.api.content}/Site?includeBase=${includeBase}`);
    }

    updateSelectedSite(siteId) {
        return of(true);
    }

    getSites(params: { keyword?: string; }) {
        return this.http.post(`${environment.api.content}/Site/search`, {
            keyword: params.keyword
        });
    }

    editSite(site: SaveSiteModel) {
        return this.http.put(`${environment.api.content}/Site/${site.id}`, {
            name: site.name,
            isActive: site.active,
            timezoneId: site.timezoneId,
            billingAddress: site.billingAddress,
            shippingAddress: site.shippingAddress
        });
    }

    deleteSite(siteId: number) {
        return this.http.delete(`${environment.api.content}/Site/${siteId}`, {});
    }

    addSite(site: SaveSiteModel) {
        return this.http.post(`${environment.api.content}/Site`, {
            name: site.name,
            timezoneId: site.timezoneId,
            shippingAddressId: site.shippingAddressId,
            shippingAddress: site.shippingAddress,
            billingAddressId: site.billingAddressId,
            billingAddress: site.billingAddress
        });
    }

    getCreditApplication(siteId: number) {
        return this.http.get(`${environment.api.content}/Site/${siteId}/credit_application`);
    }

    addCreditApplication(siteId: number, creditRA: any) {
        return this.http.post(`${environment.api.content}/Site/${siteId}/credit_application`, {
            business: creditRA.business,
            deliveryInformation: creditRA.deliveryInformation,
            financialInformation: creditRA.financialInformation,
            officeInformation: creditRA.officeInformation,
            ownerInformation: creditRA.ownerInformation,
            tradeReferences: creditRA.tradeReferences,
            tradeReferences1: creditRA.tradeReferences1,
            tradeReferences2: creditRA.tradeReferences2
        });
    }

    getCompanyDetails() {
        return this.http.get(`${environment.api.content}/Site/load_company_addresses`);
    }

    updateAddress(address: AddressModel) {
        return this.http.put(`${environment.api.content}/Address/${address.id}`, address);
    }
}
