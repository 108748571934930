import { UserAccountType } from "../enums/user-account-type.enum";
import { SiteModel } from "./site.model";

export class AppUser {
    accountName: string;
    acronym: string;
    defaultSiteId: number;
    defaultSiteSecreteKey: string;
    email: string;
    firstName: string;
    fullName: string;
    hasValidEmail: boolean;
    history: string;
    isActive: boolean;
    isGuest: boolean;
    lastName: string;
    name: string;
    phone: string;
    secretKey: string;
    sendNotification: boolean;
    sites: Array<SiteModel>;
    userAccountId: number;
    userAccountTypeId: number;
    userId: number;
    userName: string;
    userType: number;
    addons: Array<string>;
    warehouseLocationId: number;
    parTemplateId: number;
    warehouseId: number;
    connectedSellers: Array<number>;
    isAccountVerified: boolean;
    role?: UserAccountType;
    userEmail?: string;
    userPassword?: string;
}
