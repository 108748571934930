import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-icon-arrow-right',
    templateUrl: './icon-arrow-right.component.html',
    styleUrls: ['./icon-arrow-right.component.scss']
})
export class IconArrowRightComponent implements OnInit {

    @Input() cssClass = '';

    constructor() { }

    ngOnInit() {
    }

}
