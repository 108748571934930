import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Cacheable } from 'ngx-cacheable';
import { Subject } from 'rxjs';
import { GeneralItemModel } from 'src/app/shared/models/general-item.model';

const tagsCacheBuster$ = new Subject<void>();

@Injectable({
    providedIn: 'root'
})
export class TagsService {

    constructor(private http: HttpClient) { }

    @Cacheable({
        cacheBusterObserver: tagsCacheBuster$
    })
    get() {
        return this.http.get(`${environment.api.content}/Tags`);
    }

    edit(tag: GeneralItemModel) {
        return this.http.put(`${environment.api.content}/Tags/${tag.id}`, {
            id: tag.id,
            name: tag.name
        })
    }

    delete(tag: GeneralItemModel) {
        return this.http.delete(`${environment.api.content}/Tags/${tag.id}`, {})
    }

    assign(tagId: number, productId: number, name?: string) {
        return this.http.post(`${environment.api.content}/Tags/${tagId}/assign`, {
            productId,
            name
        })
    }

    unassign(tagId: number, productId: number) {
        return this.http.post(`${environment.api.content}/Tags/${tagId}/unassign`, {
            productId
        })
    }

    create(name: string, productId?: number) {
        return this.http.post(`${environment.api.content}/Tags/`, {
            productId,
            name
        });
    }

    resetCache() {
        tagsCacheBuster$.next();
    }
}
