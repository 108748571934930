export class ProductParCountModel {
    countId: number;
    parId: number;
    productId: number;
    onHand: number;
    uomId: number;
    uomSize: number;
    deleted = false;
    quantity: number;
    id: number;
    saving: boolean;
}
