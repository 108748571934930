import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Effect, ofType, Actions } from '@ngrx/effects';
import { Observable } from 'rxjs';
import {
    DeliveryDayActionTypes,
    LoadDeliveryDaysSuccess,
    LoadSpecialDeliveryDaysSuccess,
    SaveDeliveryDaySuccess,
    SaveDeliveryDay,
    SaveSpecialDeliveryDay,
    SaveSpecialDeliveryDaySuccess,
    DeleteSpecialDeliveryDay,
    DeleteSpecialDeliveryDaySuccess,
    DeleteSpecialDeliveryDayFromAllPriceLevels
} from '../actions/delivery-day.actions';
import { mergeMap, map } from 'rxjs/operators';
import { DeliveryDayModel } from 'src/app/core/models/account/delivery-day.model';
import { DeliveryDateService } from 'src/app/core/services/delivery/delivery-day.service';

@Injectable()
export class DeliveryDayEffects {

    constructor(
        private actions$: Actions,
        private deliveryDateService: DeliveryDateService
    ) { }

    @Effect()
    loadDeliveryDates$: Observable<Action> = this.actions$.pipe(
        ofType(DeliveryDayActionTypes.LoadDeliveryDays),
        mergeMap(() => this.deliveryDateService.loadDeliveryDates().pipe(
            map((data: Array<DeliveryDayModel>) => new LoadDeliveryDaysSuccess(data))
        ))
    );

    @Effect()
    loadSpecialDeliveryDates$: Observable<Action> = this.actions$.pipe(
        ofType(DeliveryDayActionTypes.LoadSpecialDeliveryDays),
        mergeMap(() => this.deliveryDateService.loadSpecialDeliveryDates().pipe(
            map((data: Array<DeliveryDayModel>) => new LoadSpecialDeliveryDaysSuccess(data))
        ))
    );

    @Effect()
    saveDeliveryDay$: Observable<Action> = this.actions$.pipe(
        ofType(DeliveryDayActionTypes.SaveDeliveryDay),
        mergeMap((action: SaveDeliveryDay) => this.deliveryDateService.saveDeliveryDay(action.payload).pipe(
            map(() => {
                return new SaveDeliveryDaySuccess({ ...action.payload.deliveryDay[0], editMode: false, saving: false });
            })
        ))
    );

    @Effect()
    saveSpecialDeliveryDay$: Observable<Action> = this.actions$.pipe(
        ofType(DeliveryDayActionTypes.SaveSpecialDeliveryDay),
        mergeMap((action: SaveSpecialDeliveryDay) => this.deliveryDateService.saveSpecialDeliveryDay(action.payload).pipe(
            map((data: DeliveryDayModel) => {
                return new SaveSpecialDeliveryDaySuccess(data);
            })
        ))
    );

    @Effect()
    deleteSpecialDeliveryDay$: Observable<Action> = this.actions$.pipe(
        ofType(DeliveryDayActionTypes.DeleteSpecialDeliveryDay),
        mergeMap((action: DeleteSpecialDeliveryDay) => this.deliveryDateService.deleteSpecialDeliveryDay(action.payload).pipe(
            map((deleteSuccess: boolean) => {
                if (deleteSuccess) {
                    return new DeleteSpecialDeliveryDaySuccess(action.payload);
                }
            })
        ))
    );

    @Effect()
    deleteSpecialDeliveryDayFromAllPriceLevels$: Observable<Action> = this.actions$.pipe(
        ofType(DeliveryDayActionTypes.DeleteSpecialDeliveryDayFromAllPriceLevels),
        mergeMap((action: DeleteSpecialDeliveryDayFromAllPriceLevels) =>
            this.deliveryDateService.deleteSpecialDeliveryDayFromAllPriceLevels(action.payload).pipe(
                map((deleteSuccess: boolean) => {
                    if (deleteSuccess) {
                        return new DeleteSpecialDeliveryDaySuccess(action.payload);
                    }
                })
            ))
    );
}
