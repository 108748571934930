import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppState } from 'src/app/app.reducer';
import { TemplateEnum } from 'src/app/core/enums/template.enum';
import { ApplyCoupon, ResetCouponErrors } from '../../actions/coupon.actions';
import { getThemeId } from '../../selectors/branding.selector';
import { couponErrorList } from '../../selectors/coupon.selector';

@Component({
    selector: 'app-coupon',
    templateUrl: './coupon.component.html',
    styleUrls: ['./coupon.component.scss']
})
export class CouponComponent implements OnInit, OnDestroy, OnChanges {

    couponForm: FormGroup;
    @Input() vendorSiteId: number;
    couponError$: Observable<any>;
    template$: Observable<TemplateEnum>;
    templateEnum = TemplateEnum;
    private ngUnsubscribe: Subject<void> = new Subject<void>();

    constructor(
        private fb: FormBuilder,
        private store: Store<AppState>) {
        this.couponForm = this.fb.group({
            code: ['', [Validators.required]],
        });
    }

    ngOnInit() {
        this.template$ = this.store.select(getThemeId);
        this.couponError$ = this.store.select(couponErrorList);
    }

    ngOnChanges() {
        this.store.select(couponErrorList).pipe(
            takeUntil(this.ngUnsubscribe)
        ).subscribe(error => {
            if (error !== undefined) {
                // tslint:disable-next-line:no-string-literal
                this.couponForm.controls['code'].setErrors({ incorrect: true });
            }
        });
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    onSubmit() {
        if (this.couponForm.invalid) {
            this.store.dispatch(new ResetCouponErrors());
        }

        const coupon = {
            code: this.couponForm.value.code,
            vendorSiteId: this.vendorSiteId
        };

        this.store.dispatch(new ApplyCoupon({ coupon }));
    }
}
