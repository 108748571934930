import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-icon-save-check',
    templateUrl: './icon-save-check.component.html'
})
export class IconSaveCheckComponent implements OnInit {

    @Input() cssClass = 'icon--18';

    constructor() { }

    ngOnInit(): void {
    }

}
