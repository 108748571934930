export class PaymentMethodModel {
    hasPayment: boolean;
    id: number;
    name: string;
    vendorId: number;
    tokenId: number;
    code: string;
    color: string;
    imageUrl: string;
    orderTotalInToken: number;
}
