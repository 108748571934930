import { Component, OnInit, Inject, NgZone, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { AppState } from 'src/app/app.reducer';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { SubmitOrder } from 'src/app/shared/actions/cart.actions';
import { selectCart } from 'src/app/shared/selectors/cart.selector';
import { tap, map, takeUntil, filter, withLatestFrom } from 'rxjs/operators';
import { PAYMENT_METHOD } from 'src/app/core/enums/payment-method.enum';
import { ConfirmationModalComponent } from 'src/app/shared/components/confirmation-modal/confirmation-modal.component';
import { LoadPaymentProfiles, DeletePaymentProfile } from 'src/app/shared/actions/payment.actions';
import { selectPaymentProfiles, selectPaymentProfilesLoading } from 'src/app/shared/selectors/payment.selector';
import { environment } from 'src/environments/environment';
import { AppUser } from 'src/app/core/models/user.model';
import { currentSiteID, currentUserDetails } from '../../selectors/auth.selector';

@Component({
    selector: 'app-payment-modal',
    templateUrl: './payment-modal.component.html',
    styleUrls: ['./payment-modal.component.scss']
})
export class PaymentModalComponent implements OnInit, OnDestroy {

    currentOrderSubmitting$: Observable<boolean>;
    paymentProfiles$: Observable<any[]>;
    selectedProfile: any;
    acceptsCreditCardPayment = false;
    showAddNewCreditCard = false;

    userDetails: AppUser;
    currentSiteId: number;

    private behalfHandler: any;
    private ngUnsubscribe: Subject<void> = new Subject<void>();

    constructor(
        public dialogRef: MatDialogRef<PaymentModalComponent>,
        private dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: { availablePaymentMethods: any[], vendorId: number, vendor: any },
        private store: Store<AppState>
    ) {
    }

    ngOnInit() {
        this.behalfHandler = (window as any).BehalfPayment;
        const config = {
            clientToken: environment.BEHALF.SDK_CLIENT_TOKEN
        };

        if (this.behalfHandler) {
            this.behalfHandler.init(config);
            this.subscribeEventsBehalf();
        }


        this.currentOrderSubmitting$ = this.store.select(selectCart).pipe(
            takeUntil(this.ngUnsubscribe),
            filter(details => details !== undefined),
            map(details => (details.find(d => d.id === this.data.vendorId) || {}).loading)
        );

        document.body.className = 'overflow-hidden';
    }

    subscribeEventsBehalf() {
        this.behalfHandler.on('payment_status_changed', (paymentData) => {
            if (paymentData.paymentStatus === 'approved') {
                this.dialogRef.close();

                this.store.dispatch(new SubmitOrder({
                    vendorId: this.data.vendorId,
                    popNotifications: true,
                    paymentMethod: this.selectedProfile.paymentMethod,
                    paymentProfileId: this.selectedProfile.profileGuid,
                    profileId: this.selectedProfile.profileId,
                    modalInstance: this.dialogRef,
                    paymentDataBehalf: paymentData
                }));
            }
        });
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();

        document.body.classList.remove('overflow-hidden');
    }

    close(): void {
        this.dialogRef.close();
    }
}
