export class ProductGroupModel {
    productGroupId: number;
    mainProductId?: number;
    deleted: boolean;
    productGroupName?: string;
    productIds?: Array<number>;
    selectedProducts?: Array<any> = new Array<any>();

    constructor(data?: any) {
        if (data) {
            this.productGroupId = data.productGroupId;
            this.productGroupName = data.productGroupName;
            this.mainProductId = data.items.find(item => item.isMainProduct).productId;
            this.selectedProducts = data.items;
        }
    }
}
