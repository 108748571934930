import { Action } from '@ngrx/store';
import { RatingActionTypes, RatingActions } from '../actions/rating.actions';
import { Rating } from 'src/app/core/models/rating.model';


export interface RatingState {
  ratingCategories: any[],
  ratings: Rating[],
  loadingRatingCat: boolean,
  loadingAddRating: boolean,
}

export const initialState: RatingState = {
  ratingCategories: [],
  ratings: [],
  loadingRatingCat: false,
  loadingAddRating: false
};

export function ratingReducer(state = initialState,
  action: RatingActions): RatingState {
  switch (action.type) {
    case RatingActionTypes.LoadRatingCategoriesAction:
      return {
        ...state,
        loadingRatingCat: true
      };
    case RatingActionTypes.LoadRatingCategoriesSuccess:
      return {
        ...state,
        ratingCategories: action.payload.categories,
        loadingRatingCat: false
      };
    case RatingActionTypes.LoadRatingSuccess:
      const newRatings = state.ratings;
      const idx = newRatings.findIndex(r => r.entityId === action.payload.rating.entityId 
        && r.ratingEntityTypeId === action.payload.rating.ratingEntityTypeId);
        if(idx >= 0){
          newRatings[idx] = action.payload.rating
        } else {
          newRatings.push(action.payload.rating);
        }
      return {
        ...state,
        ratings: [...newRatings]
      };
    default:
      return state;
  }
}
