import { Component, Input, OnInit } from '@angular/core';
import { ProductCatalogModel } from 'src/app/core/models/product/product-catalog.model';
import { environment } from 'src/environments/environment';
import { PlatformType } from 'src/environments/platform-type.enum';

@Component({
    selector: 'app-product-related-temp1',
    templateUrl: './product-related.temp1.component.html',
    styleUrls: ['./product-related.temp1.component.scss']
})
export class ProductRelatedTemp1Component implements OnInit {

    @Input() products: Array<any>;

    currentPlatform = environment.platformId;
    PlatformType = PlatformType;

    constructor() { }

    ngOnInit(): void {
    }

    getDefaultImage(): string {
        switch (this.currentPlatform) {
            case PlatformType.AMAST:
                return 'default.amast';
            default:
                return 'default';
        }
    }

    getOnErrorImage($event: any) {
        $event.src = 'assets/images/categories/' + this.getDefaultImage() + '.svg';
        $event.classList.add('product-related__img--faded');
    }

}
