import { Action } from '@ngrx/store';
import { ShippingSettings } from '../../core/models/shipping/shipping-settings.model';
import { UpdateShippingSettingsRequest } from '../../core/models/shipping/update-shipping-settings-request.model';

export enum ShippingActionTypes {
    LoadShippingSettings = '[ShippingSettings] LoadShippingSettings',
    LoadShippingSettingsSuccess = '[ShippingSettings] LoadShippingSettingsSuccess',
    UpdateShippingSettingsBatch = '[ShippingSettings] UpdateShippingSettingsBatch',
    UpdateShippingSettingsBatchSuccess = '[ShippingSettings] UpdateShippingSettingsBatchSuccess',
    UpdateShippingSetting = '[ShippingSettings] UpdateShippingSetting',
    UpdateShippingSettingSuccess = '[ShippingSettings] UpdateShippingSettingSuccess',
}

export class LoadShippingSettings implements Action {
    readonly type = ShippingActionTypes.LoadShippingSettings;
}

export class LoadShippingSettingsSuccess implements Action {
    readonly type = ShippingActionTypes.LoadShippingSettingsSuccess;

    constructor(public payload: { settings: ShippingSettings[]; }) {
    }
}

export class UpdateShippingSettingsBatch implements Action {
    readonly type = ShippingActionTypes.UpdateShippingSettingsBatch;

    constructor(public payload: { carrierId: number, isEnabled: boolean; }) {
    }
}

export class UpdateShippingSettingsBatchSuccess implements Action {
    readonly type = ShippingActionTypes.UpdateShippingSettingsBatchSuccess;

    constructor(public payload: { carrierId: number, isEnabled: boolean; }) {
    }
}

export class UpdateShippingSetting implements Action {
    readonly type = ShippingActionTypes.UpdateShippingSetting;

    constructor(public payload: { setting: UpdateShippingSettingsRequest }) {
    }
}

export class UpdateShippingSettingSuccess implements Action {
    readonly type = ShippingActionTypes.UpdateShippingSettingSuccess;

    constructor(public payload: { setting: UpdateShippingSettingsRequest }) { }
}

export type ShippingAction =
    LoadShippingSettings
    | LoadShippingSettingsSuccess
    | UpdateShippingSettingsBatch
    | UpdateShippingSettingsBatchSuccess
    | UpdateShippingSetting
    | UpdateShippingSettingSuccess;
