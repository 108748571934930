import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { userHasAddon } from '../../shared/selectors/auth.selector';

@Directive({
    selector: '[appHasAddon]'
})
export class HasAddonDirective implements OnInit {

    @Input() addon: string;

    @Input() additionalOrCondition: boolean = false;

    constructor(
        private store: Store<AppState>,
        private elementRef: ElementRef
    ) { }

    ngOnInit() {
        this.store.pipe(select(userHasAddon, { addon: this.addon }))
            .subscribe((addon: string) => {

                if (!(addon || this.additionalOrCondition)) {
                    const el: HTMLElement = this.elementRef.nativeElement;

                    if (el.parentNode) {
                        el.parentNode.removeChild(el);
                    }
                }
            });
    }

}
