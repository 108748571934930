import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
    selector: 'app-pagination-temp1',
    templateUrl: './pagination.temp1.component.html',
    styleUrls: ['./pagination.temp1.component.scss']
})
export class PaginationTemp1Component implements OnInit {

    @Input() page = 1;
    @Input() itemDetails: any;
    @Output() pageChanged: EventEmitter<number> = new EventEmitter<number>();

    public term$ = new Subject<number>();

    constructor() { }

    ngOnInit(): void {
        this.term$.pipe(
            debounceTime(500),
            distinctUntilChanged()
        ).subscribe((newPage) => {
            this.setPage(isNaN(newPage) ? 1 : newPage);
        });
    }

    setPage(pageNr: number) {
        this.pageChanged.emit(pageNr);
    }

}
