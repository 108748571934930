import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GridColumnDateGrouping } from 'src/app/core/enums/date-groping.enum';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductCostByCategoryService {

  constructor(
    private http: HttpClient
  ) { }

  loadProductCost(startDate, endDate, dateGrouping = GridColumnDateGrouping.DAILY, orderFilter, keyword = '') {
    return this.http.post(`${environment.api.content}/Reports/product_cost_by_category`, {
      startDate,
      endDate,
      keyword,
      dateGrouping,
      orderFilter
    })
  }
  
}
