import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-request-price',
    templateUrl: './request-price.component.html',
    styleUrls: ['./request-price.component.scss']
})
export class RequestPriceComponent implements OnInit {

    @Input() productId: number;
    constructor() { }

    ngOnInit() {
    }

}
