import { Action } from '@ngrx/store';
import { DeliveryDayModel } from 'src/app/core/models/account/delivery-day.model';

export enum DeliveryDayActionTypes {
    LoadDeliveryDays = '[Delivery Day] Load Delivery Day',
    LoadDeliveryDaysSuccess = '[Delivery Day] Load Delivery Day Success',
    LoadSpecialDeliveryDays = '[Delivery Day] Load Special Delivery Day',
    LoadSpecialDeliveryDaysSuccess = '[Delivery Day] Load Special Delivery Day Success',
    SaveDeliveryDay = '[Delivery Day] Save Delivery Day',
    SaveDeliveryDaySuccess = '[Delivery Day] Save Delivery Day Success',
    SaveSpecialDeliveryDay = '[Delivery Day] Save Special Delivery Day',
    SaveSpecialDeliveryDaySuccess = '[Delivery Day] Save Special Delivery Day Success',
    DeleteSpecialDeliveryDay = '[Delivery Day] Delete Special Delivery Day',
    DeleteSpecialDeliveryDayFromAllPriceLevels = '[Delivery Day] Delete Special Delivery Day From All Price Levels',
    DeleteSpecialDeliveryDaySuccess = '[Delivery Day] Delete Special Delivery Day Success'
}

export class LoadDeliveryDays implements Action {
    readonly type = DeliveryDayActionTypes.LoadDeliveryDays;
}

export class LoadDeliveryDaysSuccess implements Action {
    readonly type = DeliveryDayActionTypes.LoadDeliveryDaysSuccess;

    constructor(public payload: Array<DeliveryDayModel>) { }
}

export class LoadSpecialDeliveryDays implements Action {
    readonly type = DeliveryDayActionTypes.LoadSpecialDeliveryDays;
}

export class LoadSpecialDeliveryDaysSuccess implements Action {
    readonly type = DeliveryDayActionTypes.LoadSpecialDeliveryDaysSuccess;

    constructor(public payload: Array<DeliveryDayModel>) { }
}

export class SaveDeliveryDay implements Action {
    readonly type = DeliveryDayActionTypes.SaveDeliveryDay;

    constructor(public payload: { saveDefaults: boolean, deliveryDay: Array<DeliveryDayModel> }) { }
}

export class SaveDeliveryDaySuccess implements Action {
    readonly type = DeliveryDayActionTypes.SaveDeliveryDaySuccess;

    constructor(public payload: DeliveryDayModel) { }
}

export class SaveSpecialDeliveryDay implements Action {
    readonly type = DeliveryDayActionTypes.SaveSpecialDeliveryDay;

    constructor(public payload: DeliveryDayModel) { }
}

export class SaveSpecialDeliveryDaySuccess implements Action {
    readonly type = DeliveryDayActionTypes.SaveSpecialDeliveryDaySuccess;

    constructor(public payload: DeliveryDayModel) { }
}

export class DeleteSpecialDeliveryDay implements Action {
    readonly type = DeliveryDayActionTypes.DeleteSpecialDeliveryDay;

    constructor(public payload: number) { }
}

export class DeleteSpecialDeliveryDayFromAllPriceLevels implements Action {
    readonly type = DeliveryDayActionTypes.DeleteSpecialDeliveryDayFromAllPriceLevels;

    constructor(public payload: number) { }
}

export class DeleteSpecialDeliveryDaySuccess implements Action {
    readonly type = DeliveryDayActionTypes.DeleteSpecialDeliveryDaySuccess;

    constructor(public payload: number) { }
}

export type DeliveryDayActions = LoadDeliveryDaysSuccess
    | LoadDeliveryDays
    | LoadSpecialDeliveryDays
    | LoadSpecialDeliveryDaysSuccess
    | SaveDeliveryDay
    | SaveDeliveryDaySuccess
    | SaveSpecialDeliveryDay
    | SaveSpecialDeliveryDaySuccess
    | DeleteSpecialDeliveryDay
    | DeleteSpecialDeliveryDayFromAllPriceLevels
    | DeleteSpecialDeliveryDaySuccess;
