import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-icon-zip-code',
    templateUrl: './icon-zip-code.component.html',
    styleUrls: ['./icon-zip-code.component.scss']
})
export class IconZipCodeComponent implements OnInit {

    @Input() cssClass = '';
    constructor() { }

    ngOnInit() {
    }

}
