import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-icon-wood-stains-finishes',
    templateUrl: './icon-wood-stains-finishes.component.html'
})
export class IconWoodStainsFinishesComponent implements OnInit {

    @Input() cssClass = 'icon--18';

    constructor() { }

    ngOnInit(): void {
    }

}
