import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { AppState } from 'src/app/app.reducer';
import { RuleModel } from 'src/app/core/models/rewards/rule.model';
import { TokenModel } from 'src/app/core/models/rewards/token.model';
import { ActivitiesDetails } from 'src/app/core/models/rewards/activities-details.model';
import { WalletModel } from 'src/app/core/models/rewards/wallet.model';
import { WalletService } from 'src/app/core/services/wallet/wallet.service';
import { ToggleInfobar } from '../actions/infobar.actions';

import {
    SaveRuleSuccess,
    SaveRule,
    EnableRule,
    EnableRuleSuccess,
    LoadRuleById,
    LoadRuleByIdSuccess,
    LoadRules,
    LoadRulesSuccess,
    LoadTokenActivities,
    LoadTokenActivitiesSuccess,
    LoadTokens,
    LoadTokensSuccess,
    LoadWallet, LoadWalletActivity, LoadWalletActivitySuccess,
    LoadWalletSuccess, RewardsActionTypes, UpdateExchangeRate, UpdateExchangeRateSuccess,
    LoadWalletByToken, LoadWalletByTokenSuccess, UpdateExchangeRateFailed
} from '../actions/rewards.actions';
import { EditRuleResponse } from 'src/app/core/models/rewards/edit-rule.model';
import { PaymentWalletModel } from 'src/app/core/models/rewards/payment-wallet.model';
import { of } from 'rxjs';
import { NotifierService } from 'angular-notifier';

@Injectable()
export class RewardsEffects {
    @Effect()
    loadWallet$ = this.actions$.pipe(
        ofType<LoadWallet>(RewardsActionTypes.LoadWallet),
        exhaustMap(() => this.walletService.loadWallet()
            .pipe(
                map((response: Array<WalletModel>) => new LoadWalletSuccess(response))
            )
        )
    );

    @Effect()
    loadWalletActivity$ = this.actions$.pipe(
        ofType<LoadWalletActivity>(RewardsActionTypes.LoadWalletActivity),
        exhaustMap((action: LoadWalletActivity) => this.walletService.loadWalletActivity(action.payload.walletId,
            action.payload.page, action.payload.pageSize).pipe(
                map((response: ActivitiesDetails) => new LoadWalletActivitySuccess(response))
            )
        )
    );

    @Effect()
    loadRules$ = this.actions$.pipe(
        ofType<LoadRules>(RewardsActionTypes.LoadRules),
        exhaustMap(() => this.walletService.loadRules()
            .pipe(
                map((response: Array<RuleModel>) => new LoadRulesSuccess(response))
            )
        )
    );

    @Effect()
    loadTokens$ = this.actions$.pipe(
        ofType<LoadTokens>(RewardsActionTypes.LoadTokens),
        exhaustMap(() => this.walletService.loadTokens()
            .pipe(
                map((response: Array<TokenModel>) => new LoadTokensSuccess(response))
            )
        )
    );

    @Effect()
    saveRule$ = this.actions$.pipe(
        ofType<SaveRule>(RewardsActionTypes.SaveRule),
        exhaustMap((action: SaveRule) => this.walletService.saveRule(action.payload.rule, action.payload.isEditMode)
            .pipe(
                map((response: RuleModel) => {
                    this.store.dispatch(new ToggleInfobar({
                        open: false
                    }));

                    return new SaveRuleSuccess({ rule: response, isEditMode: action.payload.isEditMode });
                })
            )
        )
    );

    @Effect()
    loadTokenActivity$ = this.actions$.pipe(
        ofType<LoadTokenActivities>(RewardsActionTypes.LoadTokenActivities),
        exhaustMap((action: LoadTokenActivities) => this.walletService.loadTokenActivity(action.payload.tokenId,
            action.payload.page, action.payload.pageSize).pipe(
                map((response: ActivitiesDetails) => new LoadTokenActivitiesSuccess(response))
            )
        )
    );

    @Effect()
    loadRuleById$ = this.actions$.pipe(
        ofType<LoadRuleById>(RewardsActionTypes.LoadRuleById),
        exhaustMap((action: LoadRuleById) => this.walletService.loadRuleById(action.payload).pipe(
            map((response: EditRuleResponse) => new LoadRuleByIdSuccess(response))
        )
        )
    );

    @Effect()
    enableRule$ = this.actions$.pipe(
        ofType<EnableRule>(RewardsActionTypes.EnableRule),
        exhaustMap((action: EnableRule) => this.walletService.enableRule(action.payload.ruleId, action.payload.isActive).pipe(
            map((response: RuleModel) => new EnableRuleSuccess(response))
        )
        )
    );

    @Effect()
    updateExchangeRate$ = this.actions$.pipe(
        ofType<UpdateExchangeRate>(RewardsActionTypes.UpdateExchangeRate),
        exhaustMap((action: UpdateExchangeRate) => this.walletService.updateExchangeRate(action.payload).pipe(
            map(() => new UpdateExchangeRateSuccess(action.payload)),
            catchError(err => {
                this.notificationService.show({ type: 'error', message: err.error.message });
                this.store.dispatch(new UpdateExchangeRateFailed({ tokenId: action.payload.fromTokenId }));
                return of();
            })
        )
        )
    );

    @Effect()
    loadWalletByTokenId$ = this.actions$.pipe(
        ofType<LoadWalletByToken>(RewardsActionTypes.LoadWalletByToken),
        exhaustMap((action: LoadWalletByToken) => this.walletService.loadWalletByToken(action.payload).pipe(
            map((response: Array<PaymentWalletModel>) => new LoadWalletByTokenSuccess(response))
        )
        )
    );

    constructor(
        private actions$: Actions,
        private walletService: WalletService,
        private store: Store<AppState>,
        private notificationService: NotifierService
    ) { }
}
