import { ShippingSettings } from '../../core/models/shipping/shipping-settings.model';
import { ShippingAction, ShippingActionTypes } from '../actions/shipping.actions';

export interface ShippingState {
    settings: ShippingSettings[];
}

export const initialState: ShippingState = {
    settings: []
};

export function shippingReducer(state = initialState,
                                action: ShippingAction): ShippingState {
    switch (action.type) {
        case ShippingActionTypes.LoadShippingSettingsSuccess:
            return {
                ...state,
                settings: action.payload.settings
            };
        case ShippingActionTypes.UpdateShippingSettingsBatchSuccess:
            const updatedSettingsBatch = state.settings;
            updatedSettingsBatch.forEach(setting => {
                setting.carrierSettings.find(cs => cs.carrierId === action.payload.carrierId).isEnabled = action.payload.isEnabled;
            });

            return {
                ...state,
                settings: [...updatedSettingsBatch]
            };
        case ShippingActionTypes.UpdateShippingSettingSuccess:
            const updatedSettings = state.settings;
            updatedSettings.find(setting => setting.priceGroupId === action.payload.setting.priceGroupId)
                .carrierSettings.find(cs => cs.carrierId === action.payload.setting.carrierId)
                .isEnabled = action.payload.setting.isEnabled;

            return {
                ...state,
                settings: [...updatedSettings]
            };
        default:
            return state;
    }
}
