import { GlobalProductModel } from './global-product.model';

export class ProductCompositionModel {
    id: number;
    imageUrl: string;
    productName: string;
    sku: string;
    uomSize: number;
    uomName: string;
    productId: number;
    compositionType: number;
    productCompositionId: number;
    items: Array<GlobalProductModel>;
}
