import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-icon-kitchen-kitchenware',
  templateUrl: './icon-kitchen-kitchenware.component.html',
  styleUrls: ['./icon-kitchen-kitchenware.component.scss']
})
export class IconKitchenKitchenwareComponent implements OnInit {

  @Input() cssClass = 'icon--18';

  constructor() { }

  ngOnInit() {
  }

}
