import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-icon-delete',
  templateUrl: './icon-delete.component.html',
  styleUrls: ['./icon-delete.component.scss']
})
export class IconDeleteComponent implements OnInit {

  @Input() cssClass = '';
  
  constructor() { }

  ngOnInit() {
  }

}
