import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { BuyerModel } from 'src/app/core/models/order/buyer.model';

@Component({
    selector: 'app-customers-filter-temp1',
    templateUrl: './customers-filter.temp1.component.html',
    styleUrls: ['./customers-filter.temp1.component.scss']
})
export class CustomersFilterTemp1Component implements OnInit {
    @Input() searchCustomers: string;
    @Input() buyers: Array<BuyerModel>;
    @Input() selectedBuyers: Array<number> = new Array<number>();
    @Output() selectAll = new EventEmitter();
    @Output() select = new EventEmitter<Array<number>>();

    constructor() { }

    ngOnInit(): void {
    }

    onSelectAll() {
        this.selectAll.emit();
    }

    onSelect($event: MatCheckboxChange, buyer: BuyerModel) {
        if ($event.checked) {
            this.selectedBuyers.push(buyer.id);
        } else {
            const idx = this.selectedBuyers.findIndex(v => v === buyer.id);
            this.selectedBuyers.splice(idx, 1);
        }

        this.select.emit(this.selectedBuyers);
    }

}
