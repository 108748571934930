import { Component, OnInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/app.reducer';
import { TemplateEnum } from 'src/app/core/enums/template.enum';
import { environment } from 'src/environments/environment';
import { PlatformType } from 'src/environments/platform-type.enum';
import { getThemeId } from '../../selectors/branding.selector';

@Component({
    selector: 'app-icon-plus',
    templateUrl: './icon-plus.component.html',
    styleUrls: ['./icon-plus.component.scss']
})
export class IconPlusComponent implements OnInit {

    currentPlatform = environment.platformId;
    PlatformType = PlatformType;
    template$: Observable<TemplateEnum>;
    templateEnum = TemplateEnum;

    @Input() cssClass = '';

    constructor(private store: Store<AppState>) { }

    ngOnInit() {
        this.template$ = this.store.select(getThemeId);
    }

}
