export enum LeadsStatus {
    NoAction = 6,
    Accepted = 7,
    Rejected = 8,
    New = 9,
    Dismissed = 14,
    Cancel = -10
}

export enum LeadsStatusFilter {
    Pending = 9,
    Approved = 7,
    Rejected = 8
}