import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { AvailablePlatformModel } from 'src/app/core/models/order/available-platform.model';

@Component({
    selector: 'app-platform-filter-temp1',
    templateUrl: './platform-filter.temp1.component.html',
    styleUrls: ['./platform-filter.temp1.component.scss']
})
export class PlatformFilterTemp1Component implements OnInit {

    @Input() availablePlatforms: Array<AvailablePlatformModel>;
    @Input() selectedPlatforms: number[] = [];
    @Output() selectAll = new EventEmitter<any>();
    @Output() nameClick = new EventEmitter<any>();
    @Output() select = new EventEmitter<Array<number>>();

    constructor() { }

    ngOnInit(): void {
    }

    onSelectAll() {
        this.selectAll.emit([]);
    }

    onNameClick($event, status) {
        $event.preventDefault();
        this.nameClick.emit(status);
    }

    onSelect($event: MatCheckboxChange, status) {
        if ($event.checked) {
            this.selectedPlatforms.push(status.id);
        } else {
            const idx = this.selectedPlatforms.findIndex(s => s === status.id);
            this.selectedPlatforms.splice(idx, 1);
        }

        this.select.emit(this.selectedPlatforms);
    }

}
