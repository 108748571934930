import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { Platform } from 'src/app/core/models/branding/platform.model';
import { getPlatformConfiguration } from '../../selectors/branding.selector';
import { RewardsPointsLearnModalComponent } from '../rewards-points-learn-modal/rewards-points-learn-modal.component';

@Component({
    selector: 'app-rewards-banner',
    templateUrl: './rewards-banner.component.html',
    styleUrls: ['./rewards-banner.component.scss']
})
export class RewardsBannerComponent implements OnInit {

    @Input() cssClass: string;

    constructor(
        private dialog: MatDialog
    ) { }

    ngOnInit(): void { }

    closeBanner() {
        document.getElementById("rewards-banner").hidden = true;
    }

    showRewardsModal() {
        this.dialog.open(RewardsPointsLearnModalComponent, {
            maxWidth: '100vw',
            width: '100%',
            height: '100%'
        });
    }

}
