import { CatalogColorStatus } from './catalog-color-status.enum';

export class PriceLevelInfo {
    inheritPrice: boolean;
    inheritDiscountFee: boolean;
    defaultDiscountFee: number;
    price: number;
    baseCatalogDiscountFee: number;
    vendorId: number;
    vendorName: string;
    isAvailable: boolean;
    inheritAvailable: boolean;
    unitPrice: number;
    commission: number;
    defaultInheritTypeId: number;
    isAvailableLock: boolean;
    allowEdit: boolean;
    markupStatus: CatalogColorStatus;
    priceStatus: CatalogColorStatus;
    id: number;
    name: string;
    discountFee: number;
    baseCatalogId: number;
}
