import { Injectable } from '@angular/core';
import { } from 'googlemaps';
import { GeoAddressModel } from '../../models/location/address.model';

@Injectable({
    providedIn: 'root'
})
export class GeoLocationService {

    constructor() { }

    async getAddressFromPlaces(place: any): Promise<GeoAddressModel> {
        return new Promise((resolve, reject) => {
            try {
                new google.maps.places.PlacesService(
                    document.createElement('div')
                ).getDetails(
                    {
                        placeId: place.place_id,
                        fields: ['address_components', 'utc_offset_minutes'],
                    },
                    details => {
                        const address = new GeoAddressModel();
                        details?.address_components?.forEach(entry => {
                            if (entry.types?.[0] === 'locality') {
                                address.city = entry.long_name;
                            }
                            if (entry.types?.[0] === 'postal_code') {
                                address.zipCode = entry.long_name;
                            }
                            if (entry.types?.[0] === 'administrative_area_level_1') {
                                address.state = entry.short_name;
                            }
                            if (entry.types?.[0] === 'country') {
                                address.country = entry.short_name;
                            }
                        });
                        address.utcOffset = details?.utc_offset_minutes;

                        return resolve(address);
                    }
                );
            } catch (e) {
                reject(e);
            }
        });
    }
}
