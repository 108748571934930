export class RuleModel {
    active: boolean;
    createdDate: Date;
    givenAmount: number;
    givenTokenId: number;
    id: number;
    tokenName: string;
    actionId: number;
    ruleOperation: RuleOperationModel;
    beneficiaryEntityTypeId: number;
    beneficiaries: RuleRestrictionsModel;
    isLoading: boolean;

    constructor(data?: any) {
        if (data) {
            this.givenAmount = data.amount;
            this.givenTokenId = data.currency;
            this.actionId = data.action;
            this.beneficiaryEntityTypeId = data.beneficiary;
            this.ruleOperation = {
                id: data.operation,
                value: data.actionValue
            };
            this.beneficiaries = {
                priceGroups: data.selectedPriceLevels.map(pricelevel => pricelevel.id),
                businessUnits: data.selectedCustomers.map(customer => customer.id)
            };
        }
    }
}

export class RuleOperationModel {
    id: number;
    value: number;
}

export class RuleRestrictionsModel {
    priceGroups: Array<number> = new Array<number>();
    businessUnits: Array<number> = new Array<number>();
}
