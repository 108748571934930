import { Action } from '@ngrx/store';
import { SortbyFilter } from 'src/app/core/enums/sortby-filter.enum';
import { Draft } from 'src/app/core/models/draft/draft.model';
import { DraftOrderStatusType } from 'src/app/core/enums/draft-status.enum';
import { DraftRequest } from 'src/app/core/models/draft/draft-request.model';
import { ProcessDraftsResponse } from 'src/app/core/models/draft/process-drafts-response.model';

export enum DraftActionTypes {
    LoadDraft = '[Draft] Load.',
    LoadDraftSuccess = '[Draft] Drafts Loaded success.',
    LoadDraftFail = '[Draft] Draft loaded fail.',
    ProcessDrafts = '[Draft] ProcessDrafts',
    ProcessDraftsSuccess = '[Draft] ProcessDraftsSuccess',
    LoadDraftsCount = '[Global] LoadDraftsCount',
    LoadDraftsCountSuccess = '[Global] LoadDraftsCountSuccess',
    HandleConflicts = '[Draft] HandleConflicts',
}

export class LoadDrafts implements Action {
    readonly type = DraftActionTypes.LoadDraft;

    constructor(public payload: {page: number, pageSize: number, statusId: DraftOrderStatusType, start: Date, end: Date}) { }
}

export class LoadDraftSuccess implements Action {
    readonly type = DraftActionTypes.LoadDraftSuccess;

    constructor(public payload: Array<Draft>) {}
}

export class LoadDraftFail implements Action {
    readonly type = DraftActionTypes.LoadDraftFail;

    constructor(public payload: string) {}
}

export class ProcessDrafts implements Action {
    readonly type = DraftActionTypes.ProcessDrafts;

    constructor( public payload: { drafts: DraftRequest[] }) {}
}

export class ProcessDraftsSuccess implements Action {
    readonly type = DraftActionTypes.ProcessDraftsSuccess;

    constructor( public payload: { response: ProcessDraftsResponse }) {}
}

export class LoadDraftsCount implements Action {
    readonly type = DraftActionTypes.LoadDraftsCount;
}

export class LoadDraftsCountSuccess implements Action {
    readonly type = DraftActionTypes.LoadDraftsCountSuccess;

    constructor( public payload: { count: number }) { }
}

export class HandleConflicts implements Action {
    readonly type = DraftActionTypes.HandleConflicts;

    constructor( public payload: { conflicts: Draft[] }) { }
}

export type DraftActions =
    | LoadDrafts
    | LoadDraftSuccess
    | LoadDraftFail
    | ProcessDrafts
    | ProcessDraftsSuccess
    | LoadDraftsCount
    | LoadDraftsCountSuccess 
    | HandleConflicts ;
