import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-datepicker-range-filter-temp1',
    templateUrl: './datepicker-range-filter.temp1.component.html',
    styleUrls: ['./datepicker-range-filter.temp1.component.scss']
})
export class DatepickerRangeFilterTemp1Component implements OnInit {

    @Input() selected: any;
    @Input() maxDate: Date;
    @Input() title: string;
    @Output() dateRangeChanged = new EventEmitter<any>();
    @Output() applyRange = new EventEmitter<string>();

    constructor() { }

    ngOnInit(): void {
    }

    onDateRangeChanged(params) {
        this.dateRangeChanged.emit(params);
    }

    onApplyRange(range: string) {
        this.applyRange.emit(range);
    }

}
