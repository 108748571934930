import * as moment from 'moment';

export class DateUtils {
    public static getHourFromDate(date: Date): string {
        return moment(date).format('LT');
    }

    public static getDate(date: Date): string {
        return moment(date).format('L');
    }

    public static getDateWithSetTimes(time: string): Date {
        const date = new Date();
        if (time) {
            const parts = time.split(':');
            if (parts.length !== 2) {
                date.setHours(0);
                date.setMinutes(0);
                return date;
            }
            // tslint:disable-next-line:radix
            date.setHours(parseInt(parts[0]));
            // tslint:disable-next-line:radix
            date.setMinutes(parseInt(parts[1].split(' ')[0]));
        }

        return date;
    }
}
