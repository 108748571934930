import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { PlatformType } from 'src/environments/platform-type.enum';

@Component({
    selector: 'app-icon-paid-with-fundbox',
    templateUrl: './icon-paid-with-fundbox.component.html',
    styleUrls: ['./icon-paid-with-fundbox.component.scss']
})
export class IconPaidWithFundboxComponent implements OnInit {

    currentPlatform = environment.platformId;
    PlatformType = PlatformType;

    constructor() { }

    ngOnInit() {
    }

}
