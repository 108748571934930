import { Component, OnInit } from '@angular/core';
import { AppState } from 'src/app/app.reducer';
import { Store } from '@ngrx/store';
import { SearchByKeywordGlobal } from '../../actions/search.actions';
import { FormBuilder, FormGroup } from '@angular/forms';
import { debounceTime, filter } from 'rxjs/operators';
import { selectSearchKeyword, selectSearchText } from '../../selectors/search.selector';
import { ActivatedRoute, Router, NavigationEnd, ActivationEnd } from '@angular/router';
import { Observable } from 'rxjs';
import { TemplateEnum } from 'src/app/core/enums/template.enum';
import { getThemeId } from '../../selectors/branding.selector';

@Component({
    selector: 'app-global-search',
    templateUrl: './global-search.component.html',
    styleUrls: ['./global-search.component.scss']
})
export class GlobalSearchComponent implements OnInit {

    form: FormGroup;
    searchText$: Observable<string>;
    template$: Observable<TemplateEnum>;
    templateEnum = TemplateEnum;

    constructor(
        private store: Store<AppState>,
        private fb: FormBuilder,
        private activatedRoute: ActivatedRoute,
        private router: Router
    ) { }

    ngOnInit() {
        this.template$ = this.store.select(getThemeId);
        this.searchText$ = this.store.select(selectSearchText);
        this.router.events.pipe(
            filter((e: any): e is NavigationEnd => e instanceof NavigationEnd)
        )
            .subscribe(e => {
                const routeKeyword = this.activatedRoute.firstChild.snapshot.queryParams.keyword;
                if (routeKeyword === undefined) {
                    this.form.controls.keyword.setValue(undefined, {
                        emitEvent: false
                    });
                }
            });

        this.form = this.fb.group({
            keyword: [null, []]
        });

        this.form.controls.keyword.valueChanges
            .pipe(
                debounceTime(500)
            ).subscribe((keyword) => {
                this.store.dispatch(new SearchByKeywordGlobal({ keyword }));
            });

        this.store.select(selectSearchKeyword)
            .pipe(
                filter(keyword => keyword !== null),
                debounceTime(50)
            )
            .subscribe((keyword) => {
                this.form.controls.keyword.setValue(keyword, {
                    emitEvent: false
                });
            });
    }

    clearKeyword() {
        this.form.controls.keyword.setValue(undefined);
    }

    get formControls() { return this.form.controls; }

}
