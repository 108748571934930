import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuctionsResponseModel } from 'src/app/modules/auctions/models/auctions-response.model';
import { Observable } from 'rxjs';
import { AuctionItemModel } from 'src/app/modules/auctions/models/auction-item.model';
import { AddEditAuctionItemResponseModel } from 'src/app/modules/auctions/models/add-edit-auction-item-response.model';
import { DeliveryOptionEnum } from 'src/app/modules/auctions/models/delivery-options.enum';
import { AuctionSearch } from '../../models/auction/auction-search.model';
import { GeneralCartModel } from '../../models/cart/general-cart.model';

@Injectable({
    providedIn: 'root'
})

export class AuctionService {

    constructor(
        private http: HttpClient
    ) { }

    getAuctions(params: AuctionSearch): Observable<AuctionsResponseModel> {
        return this.http.post<AuctionsResponseModel>(`${environment.api.content}/Auction/search`, {
            page: params.page,
            pageSize: params.pageSize,
            keyword: params.keyword,
            categories: params.categories,
            showMyItems: params.showMyItems,
            showMakeOffer: params.showMakeOffer
        });
    }

    addToCart(auctionItemId: number, quantity: number) {
        return this.http.post(`${environment.api.content}/Auction/${auctionItemId}/add_to_cart`, {
            quantity
        });
    }

    loadShoppingCart(shoppingCartItemId?: number) {
        return this.http.post(`${environment.api.content}/Auction/shopping_cart`, { shoppingCartItemId });
    }

    saveAuctionItem(requestModel: Array<File>, auctionProduct: AuctionItemModel, isEditMode: boolean) {
        const formData = new FormData();

        requestModel.forEach((file: File) => {
            formData.append('file', file);
        });

        formData.append('auctionItemString', JSON.stringify(auctionProduct));

        if (isEditMode) {
            return this.http.put(`${environment.api.content}/Auction/${auctionProduct.id}`, formData);
        } else {
            return this.http.post(`${environment.api.content}/Auction`, formData);
        }
    }

    getAuctionDetails(auctionItemId: number) {
        return this.http.get(`${environment.api.content}/Auction/${auctionItemId}`);
    }

    getAllConditions(): Observable<any> {
        return this.http.get<any>(`${environment.api.content}/Auction/get_all_conditions`);
    }

    getOffers(auctionItemId: number) {
        return this.http.get(`${environment.api.content}/Auction/${auctionItemId}/offers`);
    }

    getAuctionItemForEdit(auctionItemId: number): Observable<AddEditAuctionItemResponseModel> {
        return this.http.get<AddEditAuctionItemResponseModel>(`${environment.api.content}/Auction/${auctionItemId}`);
    }

    makeOffer(auctionItemId: number, deliveryTypeId: DeliveryOptionEnum, price: number): Observable<any> {
        return this.http.post(`${environment.api.content}/Auction/${auctionItemId}/make_offer`, { deliveryTypeId, price });
    }

    approveOffer(auctionItemId: number, offerId: number): Observable<any> {
        return this.http.post(`${environment.api.content}/Auction/${auctionItemId}/offers/${offerId}/approve`, undefined);
    }

    rejectOffer(auctionItemId: number, offerId: number): Observable<any> {
        return this.http.post(`${environment.api.content}/Auction/${auctionItemId}/offers/${offerId}/reject`, undefined);
    }
}
