export class ChangePasswordModel {
    currentPassword: string;
    newPassword: string;
    confirmPassword: string;

    constructor(data?: ChangePasswordModel) {
        if (data) {
            this.currentPassword = data.currentPassword;
            this.newPassword = data.newPassword;
            this.confirmPassword = data.confirmPassword;
        }
    }
}
