import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-icon-electrical',
    templateUrl: './icon-electrical.component.html',
    styleUrls: ['./icon-electrical.component.scss']
})
export class IconElectricalComponent implements OnInit {

    @Input() cssClass = 'icon--18';

    constructor() { }

    ngOnInit() {
    }

}
