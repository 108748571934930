import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-icon-send-envelope',
    templateUrl: './icon-send-envelope.component.html',
    styleUrls: ['./icon-send-envelope.component.scss']
})
export class IconSendEnvelopeComponent implements OnInit {

    @Input() cssClass: string;

    constructor() { }

    ngOnInit(): void {
    }

}
