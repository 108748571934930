import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { IconDeleteComponent } from '../../icons/icon-delete/icon-delete.component';

@Component({
    selector: 'app-leads-application',
    templateUrl: './leads-application.component.html',
    styleUrls: ['./leads-application.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: IconDeleteComponent,
        }]
})
export class LeadsApplicationComponent implements OnInit {

    files: File[] = [];

    constructor() { }

    ngOnInit() {
    }

    onSelect(event) {
        this.files.push(...event.addedFiles);
    }

    onRemove(event, f) {
        this.files.splice(this.files.indexOf(f), 1);
    }
}
