import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { AppState } from 'src/app/app.reducer';
import { GeneralCartModel } from 'src/app/core/models/cart/general-cart.model';
import { DeleteCartByVendorId, SaveNotes } from 'src/app/shared/actions/cart.actions';
import { ConfirmationModalComponent } from 'src/app/shared/components/confirmation-modal/confirmation-modal.component';

@Component({
    selector: 'app-auctions-cart',
    templateUrl: './auctions-cart.component.html',
    styleUrls: ['./auctions-cart.component.scss']
})
export class AuctionsCartComponent implements OnInit {

    slideToCheckout: boolean = false;

    @Input() auctions: Array<GeneralCartModel>;
    @Output() showPaymentDetailsEmit: EventEmitter<GeneralCartModel> = new EventEmitter<GeneralCartModel>();

    public term$ = new Subject<number>();
    constructor(
        private store: Store<AppState>,
        private dialog: MatDialog,
    ) { }

    ngOnInit(): void {
        this.term$.pipe(distinctUntilChanged())
            .subscribe((auction: any) => {
                this.saveNotes(auction);
            });
    }

    onSubmit(auction: GeneralCartModel) {
        auction.notes ? auction.notes : auction.notes = '';
        auction.slideToCheckout = true;
        this.saveNotes(auction, auction.slideToCheckout);


        this.showPaymentDetailsEmit.emit(auction);
    }

    onDeleteCartByVendorId(vendorId, shoppingCartItemId) {
        const confirmRef = this.dialog.open(ConfirmationModalComponent, {
            data: {
                message: 'Are you sure you want to delete this item?'
            },
        });

        confirmRef.afterClosed().subscribe(isConfirmed => {
            if (isConfirmed) {
                this.store.dispatch(new DeleteCartByVendorId({
                    vendorId,
                    shoppingCartItemId
                }));
            }
        });
    }

    saveNotes(auction: GeneralCartModel, slideToCheckout?: boolean) {
        this.store.dispatch(new SaveNotes({
            vendorId: auction.sellerId,
            notes: auction.notes,
            shoppingCartItemId: auction.id,
            slideToCheckout
        }));
    }

}
