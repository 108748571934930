import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppState } from 'src/app/app.reducer';
import { ProductCatalogModel } from 'src/app/core/models/product/product-catalog.model';
import { ProductParCountModel } from 'src/app/core/models/product/product-par-count.model';
import { UnitOfMeasureModel } from 'src/app/core/models/product/unit-of-measure.model';
import { AppUser } from 'src/app/core/models/user.model';
import { AddCount, AddPar, ConvertProductUom, SaveProductInventorySettings } from 'src/app/shared/actions/product.actions';
import { currentUserDetails } from 'src/app/shared/selectors/auth.selector';
import { getProductCatalog, getRelatedUom } from 'src/app/shared/selectors/product.selector';

@Component({
    selector: 'app-product-inventory',
    templateUrl: './product-inventory.component.html',
    styleUrls: ['./product-inventory.component.scss']
})
export class ProductInventoryComponent implements OnInit, OnDestroy {
    selectedProductUnit: string;
    productCatalog: ProductCatalogModel;
    countFormArray: FormArray;
    parForm: FormGroup;
    timer: any;
    userDetails: AppUser;
    relatedUomOptions: Array<UnitOfMeasureModel> = new Array<UnitOfMeasureModel>();
    savingProductCatalog: boolean;
    private ngUnsubscribe: Subject<void> = new Subject<void>();
    originalUoms: Array<UnitOfMeasureModel>;

    constructor(
        private store: Store<AppState>,
        private formBuilder: FormBuilder
    ) { }

    ngOnInit() {
        this.store.pipe(select(currentUserDetails)).subscribe((data: AppUser) => {
            if (data) {
                this.userDetails = data;
            }
        });

        combineLatest(
            this.store.select(getProductCatalog),
            this.store.select(getRelatedUom)
        ).pipe(
            takeUntil(this.ngUnsubscribe)
        ).subscribe(([productCatalog, relatedUoms]) => {
            if (productCatalog && relatedUoms && relatedUoms.length > 0) {
                this.productCatalog = productCatalog;
                this.selectedProductUnit = this.productCatalog.uomName;
                this.savingProductCatalog = this.productCatalog.saving;
                this.originalUoms = relatedUoms;
                if (this.productCatalog.uomSize !== 1) {
                    const productUom = new UnitOfMeasureModel();
                    productUom.name = this.productCatalog.uomSize + ' ' + this.productCatalog.uomName;
                    productUom.id = this.productCatalog.uomId;
                    this.relatedUomOptions = [productUom];
                    this.originalUoms.forEach(uom => this.relatedUomOptions.push(uom));
                } else {
                    this.relatedUomOptions = this.originalUoms;
                }

                if (this.productCatalog.warehouseProductCounts) {
                    this.countFormArray = new FormArray([]);
                    this.productCatalog.warehouseProductCounts.forEach(count => {
                        const control = this.setCountFormGroup(count);
                        this.countFormArray.push(control);
                    });
                }

                this.createParForm(this.productCatalog.warehouseProductPars[0]);
            }
        });
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    addNewCount() {
        if (!this.countFormArray) {
            this.countFormArray = new FormArray([]);
        }

        const formGroup = this.setCountFormGroup();
        if (this.getRemainingUomOptions(formGroup).length && this.countFormArray.length < this.relatedUomOptions.length) {
            this.countFormArray.push(formGroup);
        }
    }

    saveProductInventorySettings(event: any, productCatalog: ProductCatalogModel, isFromAllowBackorder: boolean) {
        this.store.dispatch(new SaveProductInventorySettings({
            warehouseId: this.userDetails.warehouseId,
            locationId: this.userDetails.warehouseLocationId,
            settings: {
                productId: productCatalog.id,
                allowBackorder: isFromAllowBackorder ? event.checked : productCatalog.allowBackorder,
                enableInventory: isFromAllowBackorder ? productCatalog.isInventory : event.checked
            }
        }));
    }

    saveParOrCount(form: FormGroup, isPar?: boolean) {
        if (!form.valid || !form.dirty) {
            return;
        }

        const productParCount = new ProductParCountModel();

        productParCount.productId = form.value.productId;
        productParCount.onHand = form.value.onHand;
        productParCount.uomId = this.getUomIdByName(form.value.uomName);
        productParCount.uomSize = isNaN(+form.value.uomName.split(' ')[0]) ? 1 : +form.value.uomName.split(' ')[0];
        productParCount.quantity = form.value.quantity;

        clearTimeout(this.timer);
        form.controls.saving.setValue(true);
        form.updateValueAndValidity();

        this.timer = setTimeout(() => {
            if (isPar) {
                productParCount.id = form.value.parId;
                this.store.dispatch(new AddPar({
                    warehouseId: this.userDetails.warehouseId,
                    locationId: this.userDetails.warehouseLocationId,
                    parTemplateId: this.userDetails.parTemplateId,
                    par: productParCount
                }));
            } else {
                productParCount.id = form.value.countId;

                this.store.dispatch(new AddCount({
                    warehouseId: this.userDetails.warehouseId,
                    locationId: this.userDetails.warehouseLocationId,
                    count: productParCount
                }));
            }
        }, 1000);
    }

    setCountFormGroup(count?: ProductParCountModel): FormGroup {
        return new FormGroup({
            countId: new FormControl(count ? count.countId : -1),
            onHand: new FormControl(count ? count.onHand : '', Validators.required),
            uomName: new FormControl(count ? this.getSelectedUom(count) : '', Validators.required),
            productId: new FormControl(this.productCatalog.id),
            saving: new FormControl(count ? count.saving : false)
        });
    }

    createParForm(par: ProductParCountModel) {
        this.parForm = this.formBuilder.group({
            parId: new FormControl(par ? par.parId : -1),
            quantity: new FormControl(par ? par.quantity : '', Validators.required),
            uomName: new FormControl(par ? this.getSelectedUom(par) : '', Validators.required),
            productId: new FormControl(this.productCatalog.id),
            saving: new FormControl(par ? par.saving : false)
        });
    }

    getSelectedUom(count: ProductParCountModel): string {
        const uomSelected = this.relatedUomOptions.find(uom => uom.name === this.getCountUomName(count));

        if (uomSelected) {
            return uomSelected.name;
        }

        return '';
    }

    getCountUomName(count: ProductParCountModel): string {
        if (count.uomSize > 1) {
            return count.uomSize + ' ' + this.getUomName(count);
        }
        return this.getUomName(count);
    }

    getUomName(count: ProductParCountModel): string {
        const uomSelected = this.originalUoms.find(uom => uom.id === count.uomId);

        if (uomSelected) {
            return uomSelected.name;
        }

        return '';
    }

    getUomIdByName(uomName: string): number {
        return this.relatedUomOptions.find(uom => uom.name === uomName).id;
    }

    getRemainingUomOptions(currentCountParForm: FormGroup): Array<UnitOfMeasureModel> {
        const remainingUomUptions: Array<UnitOfMeasureModel> = new Array<UnitOfMeasureModel>();

        this.relatedUomOptions.forEach((uom: UnitOfMeasureModel) => {
            const index = this.countFormArray.controls.findIndex((control: FormGroup) => control.controls.uomName.value === uom.name);

            if (index === -1 || uom.name === currentCountParForm.controls.uomName.value) {
                remainingUomUptions.push(uom);
            }
        });

        return remainingUomUptions;
    }

    updateProductUOM(event: string) {
        this.store.dispatch(new ConvertProductUom(
            {
                productId: this.productCatalog.id,
                uomId: this.getUomIdByName(event),
                uomSize: isNaN(+event.split(' ')[0]) ? 1 : +event.split(' ')[0]
            }
        ));
    }
}
