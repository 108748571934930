import { Action } from '@ngrx/store';
import { RuleModel } from 'src/app/core/models/rewards/rule.model';
import { ExchangeRateModel, TokenModel } from 'src/app/core/models/rewards/token.model';
import { ActivitiesDetails } from 'src/app/core/models/rewards/activities-details.model';
import { WalletModel } from 'src/app/core/models/rewards/wallet.model';
import { EditRuleResponse } from 'src/app/core/models/rewards/edit-rule.model';
import { PaymentWalletModel } from 'src/app/core/models/rewards/payment-wallet.model';


export enum RewardsActionTypes {
    LoadWallet = '[Account] Load Wallet',
    LoadWalletSuccess = '[Account] Load Wallet Success',
    LoadWalletActivity = '[Account] Load Wallet Activity',
    LoadWalletActivitySuccess = '[Account] Load Wallet Activity Success',
    LoadRules = '[Rewards] Load Rules',
    LoadRulesSuccess = '[Rewards] Load Rules Success',
    LoadTokens = '[Rewards] Load Tokens',
    LoadTokensSuccess = '[Rewards] Load Tokens Success',
    SaveRule = '[Rewards] Save Rule',
    SaveRuleSuccess = '[Rewards] Save Rule Success',
    LoadTokenActivities = '[Rewards] Load Token Activities',
    LoadTokenActivitiesSuccess = '[Rewards] Load Token Activities Success',
    LoadRuleById = '[Rewards] Load Rule By Id',
    LoadRuleByIdSuccess = '[Rewards] Load Rule By Id Success',
    EnableRule = '[Rewards] Enable Rule',
    EnableRuleSuccess = '[Rewards] Enable Rule Success',
    UpdateExchangeRate = '[Rewards] Update Exchange Rate',
    UpdateExchangeRateSuccess = '[Rewards] Update Exchange Rate Success',
    UpdateExchangeRateFailed = '[Rewards] Update Exchange Rate Failed',
    LoadWalletByToken = '[Account] Load Wallet By Token',
    LoadWalletByTokenSuccess = '[Account] Load Wallet By Token Success'
}

export class LoadWallet implements Action {
    readonly type = RewardsActionTypes.LoadWallet;
}

export class LoadWalletSuccess implements Action {
    readonly type = RewardsActionTypes.LoadWalletSuccess;

    constructor(public payload: Array<WalletModel>) { }
}

export class LoadWalletActivity implements Action {
    readonly type = RewardsActionTypes.LoadWalletActivity;

    constructor(public payload: { walletId: number, page: number, pageSize: number }) { }
}

export class LoadWalletActivitySuccess implements Action {
    readonly type = RewardsActionTypes.LoadWalletActivitySuccess;

    constructor(public payload: ActivitiesDetails) { }
}

export class LoadRules implements Action {
    readonly type = RewardsActionTypes.LoadRules;
}

export class LoadRulesSuccess implements Action {
    readonly type = RewardsActionTypes.LoadRulesSuccess;

    constructor(public payload: Array<RuleModel>) { }
}

export class LoadTokens implements Action {
    readonly type = RewardsActionTypes.LoadTokens;
}

export class LoadTokensSuccess implements Action {
    readonly type = RewardsActionTypes.LoadTokensSuccess;

    constructor(public payload: Array<TokenModel>) { }
}

export class SaveRule implements Action {
    readonly type = RewardsActionTypes.SaveRule;

    constructor(public payload: { rule: RuleModel, isEditMode: boolean }) { }
}

export class SaveRuleSuccess implements Action {
    readonly type = RewardsActionTypes.SaveRuleSuccess;

    constructor(public payload: { rule: RuleModel, isEditMode: boolean }) { }
}

export class LoadTokenActivities implements Action {
    readonly type = RewardsActionTypes.LoadTokenActivities;

    constructor(public payload: { tokenId: number, page: number, pageSize: number }) { }
}

export class LoadTokenActivitiesSuccess implements Action {
    readonly type = RewardsActionTypes.LoadTokenActivitiesSuccess;

    constructor(public payload: ActivitiesDetails) { }
}

export class LoadRuleById implements Action {
    readonly type = RewardsActionTypes.LoadRuleById;

    constructor(public payload: number) { }
}

export class LoadRuleByIdSuccess implements Action {
    readonly type = RewardsActionTypes.LoadRuleByIdSuccess;

    constructor(public payload: EditRuleResponse) { }
}

export class EnableRule implements Action {
    readonly type = RewardsActionTypes.EnableRule;

    constructor(public payload: { ruleId: number, isActive: boolean }) { }
}

export class EnableRuleSuccess implements Action {
    readonly type = RewardsActionTypes.EnableRuleSuccess;

    constructor(public payload: RuleModel) { }
}

export class UpdateExchangeRate implements Action {
    readonly type = RewardsActionTypes.UpdateExchangeRate;

    constructor(public payload: { fromTokenId: number, toTokenId: number, fromAmount: number, toAmount: number }) { }
}

export class UpdateExchangeRateSuccess implements Action {
    readonly type = RewardsActionTypes.UpdateExchangeRateSuccess;

    constructor(public payload: ExchangeRateModel) { }
}

export class UpdateExchangeRateFailed implements Action {
    readonly type = RewardsActionTypes.UpdateExchangeRateFailed;

    constructor(public payload: { tokenId: number }) { }
}

export class LoadWalletByToken implements Action {
    readonly type = RewardsActionTypes.LoadWalletByToken;

    constructor(public payload: number) { }
}

export class LoadWalletByTokenSuccess implements Action {
    readonly type = RewardsActionTypes.LoadWalletByTokenSuccess;

    constructor(public payload: Array<PaymentWalletModel>) { }
}


export type RewardsActions =
    | LoadWallet
    | LoadWalletSuccess
    | LoadWalletActivity
    | LoadWalletActivitySuccess
    | LoadRules
    | LoadRulesSuccess
    | LoadTokens
    | LoadTokensSuccess
    | SaveRule
    | SaveRuleSuccess
    | LoadTokenActivitiesSuccess
    | LoadTokenActivities
    | LoadRuleById
    | LoadRuleByIdSuccess
    | EnableRule
    | EnableRuleSuccess
    | UpdateExchangeRate
    | UpdateExchangeRateSuccess
    | LoadWalletByToken
    | LoadWalletByTokenSuccess
    | UpdateExchangeRateFailed;
