import {Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {Context} from 'ag-grid-community';
import {AppState} from 'src/app/app.reducer';
import {BehalfPaymentContextModel} from 'src/app/core/models/cart/behalf-context.model';
import {OrderModel} from 'src/app/core/models/order/order.model';
import {environment} from 'src/environments/environment';
import {BehalfOrderReauthorization} from '../../actions/payment.actions';

@Component({
    selector: 'app-behalf-payment-modal',
    templateUrl: './behalf-payment-modal.component.html',
    styleUrls: ['./behalf-payment-modal.component.scss']
})
export class BehalfPaymentModalComponent implements OnInit {

    behalfHandler: any;

    constructor(
        private store: Store<AppState>,
        @Inject(MAT_DIALOG_DATA) public data: {context: BehalfPaymentContextModel},
        private dialogRef: MatDialogRef<BehalfPaymentModalComponent>
    ) {}

    ngOnInit(): void {
        this.behalfHandler = (window as any).BehalfPayment;
        const config = {
            clientToken: environment.BEHALF.SDK_CLIENT_TOKEN
        };

        if (this.behalfHandler) {
            this.behalfHandler.init(config);
            this.behalfHandler.on('payment_status_changed', (paymentData) => {
                if (paymentData.paymentStatus === 'approved' && this.data.context.isSubmit) {
                    this.dialogRef.close('submit-order');
                }

                if (paymentData.paymentStatus === 'approved') {
                    this.store.dispatch(new BehalfOrderReauthorization({
                        orderID: this.data.context.orderId,
                        transactionID: this.data.context.transactionId,
                        paymentDataBehalf: paymentData
                    }));
                }
            });

            const checkoutContext = {
                paymentDetails: this.data.context
            };

            this.behalfHandler.load('#behalf-payment-element', checkoutContext);
        }
    }

}
