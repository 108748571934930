import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-icon-time-picker',
    templateUrl: './icon-time-picker.component.html'
})
export class IconTimePickerComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
