import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { AppState } from 'src/app/app.reducer';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectVendorPriceRequest } from '../../selectors/price-requests.selector';
import { LoadVendorPriceRequest, RequestPriceVendor } from '../../actions/price-requests.actions';
import { PriceRequestSeller } from 'src/app/core/models/price-request/price-request-seller.interface';

@Component({
    selector: 'app-product-price-request',
    templateUrl: './product-price-request.component.html',
    styleUrls: ['./product-price-request.component.scss']
})
export class ProductPriceRequestComponent implements OnInit, OnChanges {

    @Input() productId: number;
    vendors$: Observable<any>;

    constructor(
        private store: Store<AppState>) { }

    ngOnInit() {
        this.vendors$ = this.store.select(selectVendorPriceRequest);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.productId) {
            this.store.dispatch(new LoadVendorPriceRequest({
                productId: this.productId
            }));
        }
    }

    onApply(vendor: PriceRequestSeller) {

        const payload = {
            productId: this.productId,
            vendorId: vendor.vendorId
        }

        this.store.dispatch(new RequestPriceVendor(payload));
    }

}
