import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-icon-grid',
  templateUrl: './icon-grid.component.html',
  styleUrls: ['./icon-grid.component.scss']
})
export class IconGridComponent implements OnInit {

  @Input() cssClass = '';
  @Input() fill = false;
  
  constructor() { }

  ngOnInit() {
  }

}
