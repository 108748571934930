import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-icon-group',
    templateUrl: './icon-group.component.html'
})
export class IconGroupComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
