import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-icon-valves',
    templateUrl: './icon-valves.component.html'
})
export class IconValvesComponent implements OnInit {

    @Input() cssClass = 'icon--18';

    constructor() { }

    ngOnInit() {
    }

}
