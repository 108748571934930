export enum InfobarComponents {
    TRANSACTION = 'transaction',
    MANAGE_PAYMENTS = 'manage_payments',
    ADD_PAYMENTS = 'add_payments',
    SITE = 'site'
}

export enum InfobarLocationComponents {
    PAGE_NAME = 'location',
    CREDIT_REQUEST = 'creditRequest',
}

export enum InfobarUserComponents {
    PAGE_NAME = 'team',
}

export enum InfobarChangePasswordComponents {
    PAGE_NAME = 'change_password',
}

export enum InfobarProductCompositionComponents {
    PAGE_NAME = 'product_composition',
}

export enum InfobarProductGroupComponents {
    PAGE_NAME = 'product_group',
}

export enum InfobarPriceLevelListComponents {
    PAGE_NAME = 'new_price_level'
}

export enum InfobarProductPriceInventoryComponents {
    PAGE_NAME = 'product_price_inventory'
}

export enum InfobarNewProductComponents {
    PAGE_NAME = 'new_product',
}

export enum InfobarUploadProductComponents {
    PAGE_NAME = 'upload_product',
}

export enum InfobarCuponComponents {
    PAGE_NAME = 'add_edit_cupon',
}

export enum InfobarRuleComponents {
    PAGE_NAME = 'add_edit_rule',
}

export enum InfobarCustomerComponents {
    PAGE_NAME = 'customer',
    LEADS_PAGE = 'leads',
    LEADS_DETAILS = 'leadsDetails',
    INVITES = 'invites'
}

export enum InfobarCompanyDetailsComponents {
    EDIT_DETAILS = 'editCompanyDetails',
    EDIT_SHIPPING = 'editShipping',
    EDIT_BILLING = 'editBilling',
}

export enum InfobarCompleteAccountComponents {
    PAGE_NAME = 'complete_account',
}

export enum InfobarAddAuctionItem {
    PAGE_NAME = 'add_auction_item'
}

export enum InfobarAuctionOffers {
    PAGE_NAME = 'auction_offers'
}
