import { Component, OnInit } from '@angular/core';
import { AppState } from 'src/app/app.reducer';
import { Store } from '@ngrx/store';
import { MatDialogRef } from '@angular/material/dialog';
import { AddCreditCard } from '../../actions/payment.actions';
import { selectPaymentProfilesLoadingAdd } from '../../selectors/payment.selector';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-add-payment-method-modal',
    templateUrl: './add-payment-method-modal.component.html',
    styleUrls: ['./add-payment-method-modal.component.scss']
})
export class AddPaymentMethodModalComponent implements OnInit {

    loading$: Observable<boolean>;

    constructor(
        public dialogRef: MatDialogRef<AddPaymentMethodModalComponent>,
        private store: Store<AppState>
    ) { }

    ngOnInit() {
        this.loading$ = this.store.select(selectPaymentProfilesLoadingAdd);
    }

    onSubmitAddCreditCard(creditCardDetails: { creditCard: any, address: any, save: boolean }) {
        creditCardDetails = {
            ...creditCardDetails,
            address: {
                ...creditCardDetails.address,
                countryId: creditCardDetails.address.countryId[0].id,
                stateId: creditCardDetails.address.stateId[0].id,
            }
        };

        this.store.dispatch(new AddCreditCard({
            ...creditCardDetails
        }));
    }

    close(): void {
        this.dialogRef.close();
    }

}
