import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-tags-filter-temp1',
    templateUrl: './tags-filter.temp1.component.html',
    styleUrls: ['./tags-filter.temp1.component.scss']
})
export class TagsFilterTemp1Component implements OnInit {

    @Input() tags: Array<any>;
    @Output() select: EventEmitter<any> = new EventEmitter<any>();
    @Output() selectAll = new EventEmitter();
    @Output() openModal = new EventEmitter();

    constructor() { }

    ngOnInit(): void {
    }

    onSelectAll() {
        this.selectAll.emit();
    }

    onSelect(tag: any) {
        this.select.emit(tag);
    }

    onOpenModal() {
        this.openModal.emit();
    }

}
