import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { InfobarComponents, InfobarProductCompositionComponents, InfobarProductPriceInventoryComponents } from 'src/app/core/enums/infobar-components.enum';
import { CompositionOperationTypeEnum } from 'src/app/core/enums/operation-type.enum';
import { ProductCompositionModel } from 'src/app/core/models/product/product-composition.model';
import { GlobalProductModel } from 'src/app/core/models/product/global-product.model';
import { ToggleInfobar } from 'src/app/shared/actions/infobar.actions';
import {
    CreateProductComposition, DeleteProductComposition, EditProductComposition, LoadProductComposition
} from 'src/app/shared/actions/product.actions';
import { ConfirmationModalComponent } from 'src/app/shared/components/confirmation-modal/confirmation-modal.component';
import {
    SearchProductCompositionItemModalComponent
} from 'src/app/shared/components/search-product-composition-item-modal/search-product-composition-item-modal.component';
import { InfobarState } from 'src/app/shared/reducers/infobar.reducer';
import { loadingProductComposition, productComposition } from 'src/app/shared/selectors/product.selector';
import { environment } from 'src/environments/environment';
import { PlatformType } from 'src/environments/platform-type.enum';

@Component({
    selector: 'app-product-composition',
    templateUrl: './product-composition.component.html',
    styleUrls: ['./product-composition.component.scss']
})
export class ProductCompositionComponent implements OnInit {

    @Input() infobarConfigs: InfobarState;

    productComposition: ProductCompositionModel;
    currentPlatform = environment.platformId;
    productId: number;
    productCompositionId: number;
    compositionOperationType = CompositionOperationTypeEnum;
    quantity: number;
    loading: boolean;
    productCompositionClone: string;
    excludedProductsIds: Array<number> = new Array<number>();

    constructor(
        private store: Store<AppState>,
        private dialog: MatDialog) { }

    ngOnInit(): void {
        this.productId = this.infobarConfigs.params.productId;
        this.productCompositionId = this.infobarConfigs.params.productCompositionId;

        this.store.pipe(select(loadingProductComposition)).subscribe(data => {
            this.loading = data;
        });

        this.store.pipe(select(productComposition, { productId: this.productId })).subscribe((data: ProductCompositionModel) => {
            if (data) {
                this.productComposition = data;
                this.productComposition.compositionType = this.productComposition.compositionType ?
                    this.productComposition.compositionType : CompositionOperationTypeEnum.ASSEMBLY;
                this.productCompositionClone = JSON.stringify(this.productComposition);
                this.productComposition.items.forEach(item => {
                    this.excludedProductsIds.push(item.productId);
                });
            }
        });

        this.store.dispatch(new LoadProductComposition({ productId: this.productId, productCompositionId: this.productCompositionId }));
    }

    save() {
        const productCompositionSave = new ProductCompositionModel();
        productCompositionSave.productCompositionId = this.productComposition.id;
        productCompositionSave.productId = this.productComposition.productId;
        productCompositionSave.compositionType = this.productComposition.compositionType;
        productCompositionSave.items = this.productComposition.items;

        if (this.productComposition.id) {
            this.store.dispatch(new EditProductComposition(productCompositionSave));
            return;
        }

        if (this.productComposition.items.length > 0) {
            this.store.dispatch(new CreateProductComposition(productCompositionSave));
        }
    }

    deleteCompositionItemPart(index: number) {
        this.productComposition.items.splice(index, 1);
        this.excludedProductsIds.splice(index, 1);
    }

    addCompositionItemPart() {
        const dialogRef = this.dialog.open(SearchProductCompositionItemModalComponent, {
            id: 'search-product-assembly-modal',
            data: { excludedProductsIds: this.excludedProductsIds }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result instanceof GlobalProductModel) {
                if (!this.productComposition.items) {
                    this.productComposition.items = new Array<GlobalProductModel>();
                }
                this.productComposition.items.push(result);
                this.excludedProductsIds.push(result.productId);
            }
        });
    }

    openProductDetailsInfobar() {
        this.store.dispatch(new ToggleInfobar({
            ...this.infobarConfigs,
            open: this.infobarConfigs.isOpen,
            params: {
                ...this.infobarConfigs.params,
                [InfobarProductPriceInventoryComponents.PAGE_NAME]: true,
                [InfobarProductCompositionComponents.PAGE_NAME]: false,
                [InfobarComponents.SITE]: null
            }
        }));
    }

    getDefaultImage(): string {
        switch (this.currentPlatform) {
            case PlatformType.AMAST:
                return 'default.amast';
            default:
                return 'default';
        }
    }

    getOnErrorImage($event: any) {
        $event.src = 'assets/images/categories/' + this.getDefaultImage() + '.svg';
    }

    deleteComposition() {
        const confirmRef = this.dialog.open(ConfirmationModalComponent, {
            data: {
                message: `Are you sure you want to delete this composition ?`
            },
        });
        confirmRef.afterClosed().subscribe(isConfirmed => {
            if (isConfirmed) {
                this.store.dispatch(new DeleteProductComposition({
                    compositionId: this.productComposition.id,
                    productId: this.productComposition.productId
                }));
            }
        });
    }

    disabledSave(): boolean {
        return this.productCompositionClone === JSON.stringify(this.productComposition);
    }

}
