import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AppState } from 'src/app/app.reducer';
import { CouponValueType } from 'src/app/core/models/cupons/coupon-value-type.enum';
import { SaveCouponModel } from 'src/app/core/models/cupons/save-coupon.model';
import { SaveCoupon, SaveEditCoupon } from 'src/app/shared/actions/coupon.actions';
import { ToggleInfobar } from 'src/app/shared/actions/infobar.actions';
import { LoadCategories } from 'src/app/shared/actions/order-guide.action';
import { getEditCoupon, loadingSaveCupon } from 'src/app/shared/selectors/coupon.selector';
import { selectCategories } from 'src/app/shared/selectors/order-guide.selector';
import { sitesList } from 'src/app/shared/selectors/site.selector';
import * as moment from 'moment';
import { InfobarState } from 'src/app/shared/reducers/infobar.reducer';

@Component({
    selector: 'app-add-edit-coupon',
    templateUrl: './add-edit-coupon.component.html',
    styleUrls: ['./add-edit-coupon.component.scss']
})
export class AddEditCouponComponent implements OnInit, OnDestroy {

    // @ViewChild(DaterangepickerDirective, { static: false }) pickerDirective: DaterangepickerDirective;
    @Input() infobarConfigs: InfobarState;
    @Input() set editMode(editMode: boolean) {
        this.isEditMode = editMode;
        if (this.form && !this.isEditMode) {
            this.selectedCategories = [];
            this.selectedPriceLevels = [];
            this.form = null;
            setTimeout(() => this.createForm());
        }
    }

    displayDateRange: boolean;
    isEditMode: boolean;
    form: FormGroup;
    loading$: Observable<boolean>;
    couponId: number;

    // Categories multiple select
    categoriesSettings = {
        text: 'Select Categories',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        classes: 'tl-multipleselect',
        enableSearchFilter: true,
        badgeShowLimit: 2,
        groupBy: 'masterCategory'
    };
    selectedCategories: Array<any> = [];
    availableCategories: Array<any> = [];

    // Categories multiple select
    priceLevelsSettings = {
        text: 'Select Price Levels',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        classes: 'tl-multipleselect',
        enableSearchFilter: true,
        badgeShowLimit: 2
    };
    selectedPriceLevels: Array<any> = [];
    availablePriceLevels: Array<any> = [];

    dateLocale = {
        applyLabel: 'Set',
        clearLabel: 'Clear',
        format: 'MM/DD/YYYY hh:mm A'
    };

    private categoriesGroup: Array<any>;

    constructor(
        private formBuilder: FormBuilder,
        private store: Store<AppState>
    ) { }

    ngOnInit() {
        combineLatest([
            this.store.pipe(select(getEditCoupon)),
            this.store.pipe(select(selectCategories)),
            this.store.pipe(select(sitesList))
        ]).pipe(
            filter(([coupon, categories, pricelevels]) => categories.length && pricelevels.length)
        ).subscribe(([coupon, categories, pricelevels]) => {
            this.categoriesGroup = categories;
            this.availableCategories = [];
            categories.forEach(c => {
                if (c.subCategories.length) {
                    c.subCategories.forEach(sc => this.availableCategories.push({
                        id: sc.id,
                        itemName: sc.name,
                        masterCategory: c.name
                    }));
                } else {
                    this.availableCategories.push({
                        id: c.id,
                        itemName: c.name,
                        masterCategory: c.name
                    });
                }
            });

            this.availablePriceLevels = pricelevels.map(c => ({
                id: c.id,
                itemName: c.name
            }));

            if (coupon && this.isEditMode) {
                this.couponId = coupon.id;

                // populate coupon price levels
                this.selectedPriceLevels = coupon.customers.filter(c => c.customerSiteId !== null).map(c => ({
                    id: c.customerSiteId,
                    itemName: c.name
                }));

                this.selectedCategories = [];
                coupon.category.forEach(c => {
                    if (c.isMasterCategory) {
                        const masterCategory = this.categoriesGroup.find(cg => cg.id === c.categoryId);
                        if (masterCategory.subCategories.length) {
                            masterCategory.subCategories.forEach(sc => this.selectedCategories.push({
                                id: sc.id,
                                itemName: sc.name
                            }));
                        } else {
                            this.selectedCategories.push({
                                id: masterCategory.id,
                                itemName: masterCategory.name
                            });
                        }
                    } else {
                        this.selectedCategories.push({
                            id: c.categoryId,
                            itemName: c.name
                        });
                    }
                });

                this.form = null;
                setTimeout(() => this.createForm(coupon));
            } else {
                this.form = null;
                setTimeout(() => this.createForm());
            }
        });

        this.loading$ = this.store.select(loadingSaveCupon);
        this.store.dispatch(new LoadCategories({
            search: {
                includeAll: true
            }
        }));
    }

    ngOnDestroy() {
        this.selectedCategories = [];
        this.selectedPriceLevels = [];
        this.form = null;
        this.editMode = false;
    }

    // tslint:disable-next-line:adjacent-overload-signatures
    get editMode() { return this.isEditMode; }

    createForm(editCoupon?: SaveCouponModel) {
        this.form = this.formBuilder.group({
            selectedCategories: [],
            selectedPriceLevels: [],
            name: [editCoupon ? editCoupon.name : '', [Validators.required, Validators.maxLength(150)]],
            code: [editCoupon ? editCoupon.code : '', [Validators.required, Validators.pattern(/^[a-zA-Z0-9%$-]{4,}$/),
            Validators.maxLength(30)]],
            active: [editCoupon ? editCoupon.active : false, Validators.required],
            value: [editCoupon ? editCoupon.value : '', this.getValueValidators(editCoupon ?
                editCoupon.valueType : CouponValueType.Percentage)],
            valueType: [editCoupon ? editCoupon.valueType : CouponValueType.Percentage, Validators.required],
            minOrderAmount: [editCoupon ? editCoupon.minOrderAmount : '', Validators.pattern(/^[1-9][0-9]*$/)],

            maxOrderAmount: [editCoupon ? editCoupon.maxOrderAmount : '', editCoupon
                && editCoupon.minOrderAmount ? [Validators.min(editCoupon.minOrderAmount),
                Validators.pattern(/^[1-9][0-9]*$/)] : Validators.pattern(/^[1-9][0-9]*$/)],

            usageLimitPerCustomer: [editCoupon ? editCoupon.numberOfUsagesPerUser : '', Validators.pattern(/^[1-9][0-9]*$/)],
            usageLimit: [editCoupon ? editCoupon.numberOfUsagesPerCoupon : '', editCoupon
                && editCoupon.numberOfUsagesPerUser ? [Validators.required, Validators.pattern(/^[1-9][0-9]*$/),
                Validators.min(editCoupon.numberOfUsagesPerUser)] : Validators.pattern(/^[1-9][0-9]*$/)],
            applyOnlyToNewCustomers: [editCoupon ? editCoupon.applyOnlyToNewCustomers : false, Validators.required],
            selectedDate: [editCoupon ? [this.getDateFormat(new Date(editCoupon.startDate)),
            this.getDateFormat(new Date(editCoupon.endDate))].join(' to ') : this.getDefaultDate().join(' to '), Validators.required],
            selectedDateArray: [editCoupon ? [this.getDateFormat(new Date(editCoupon.startDate)),
            this.getDateFormat(new Date(editCoupon.endDate))] : this.getDefaultDate(), Validators.required]
        });

        this.form.controls.valueType.valueChanges.subscribe((data: number) => {
            const value = this.form.controls.value.value;
            this.form.controls.value.reset();
            this.form.controls.value.setValidators(this.getValueValidators(data));
            if (value) {
                this.form.controls.value.setValue(value);
            }
            this.form.updateValueAndValidity();
        });

        this.form.controls.usageLimitPerCustomer.valueChanges.subscribe((data: number) => {
            this.form.controls.usageLimit.clearValidators();
            const usageLimit = this.form.controls.usageLimit.value;
            this.form.controls.usageLimit.reset();
            if (data) {
                this.form.controls.usageLimit.setValidators([Validators.required, Validators.pattern(/^[1-9][0-9]*$/),
                Validators.min(data)]);
            } else {
                this.form.controls.usageLimit.clearValidators();
            }

            this.form.controls.usageLimit.setValue(usageLimit);
            this.form.controls.usageLimit.markAsTouched();
            this.form.updateValueAndValidity();
        });

        this.form.controls.minOrderAmount.valueChanges.subscribe((data: number) => {
            this.form.controls.maxOrderAmount.clearValidators();
            const maxOrderAmount = this.form.controls.maxOrderAmount.value;
            this.form.controls.maxOrderAmount.reset();
            if (data) {
                this.form.controls.maxOrderAmount.setValidators([Validators.min(data),
                Validators.pattern(/^[1-9][0-9]*$/)]);
            } else {
                this.form.controls.maxOrderAmount.setValidators(Validators.pattern(/^[1-9][0-9]*$/));
            }

            this.form.controls.maxOrderAmount.setValue(maxOrderAmount);
            this.form.controls.maxOrderAmount.markAsTouched();
            this.form.updateValueAndValidity();
        });
    }

    onDeSelectAllCategories(items: Array<any>) {
        this.selectedCategories = items;
    }

    onDeSelectAllPriceLevels(items: Array<any>) {
        this.selectedPriceLevels = items;
    }

    getDefaultDate(): any {
        const startDate = new Date();
        startDate.setHours(0);
        startDate.setMinutes(0);
        startDate.setSeconds(0);
        startDate.setMilliseconds(0);

        const endDate = new Date();
        endDate.setHours(23);
        endDate.setMinutes(59);
        endDate.setSeconds(59);
        endDate.setMilliseconds(999);

        return [
            this.getDateFormat(startDate),
            this.getDateFormat(endDate)
        ];
    }

    getValueValidators(valueType: CouponValueType) {
        if (valueType === CouponValueType.Percentage) {
            return [Validators.required, Validators.pattern(/^[1-9][0-9]*$/), Validators.max(99)];
        }

        return [Validators.required, Validators.pattern(/^[1-9][0-9]*\.?\d*$/)];
    }

    save() {
        if (!this.form.valid) {
            return;
        }

        const selectedCategoriesGrouped = [];
        this.selectedCategories.forEach(c => {
            const masterCategory = this.categoriesGroup.find(cg => cg.subCategories.find(sc => sc.id === c.id) || cg.id === c.id);
            if (!selectedCategoriesGrouped[masterCategory.id]) {
                selectedCategoriesGrouped[masterCategory.id] = [];
            }

            selectedCategoriesGrouped[masterCategory.id].push(c);
        });

        const couponCategories = [];
        selectedCategoriesGrouped.forEach((subcategories, k) => {
            const masterCategory = this.categoriesGroup.find(c => c.id === k);
            if (subcategories.length === masterCategory.subCategories.length) {
                couponCategories.push(k);
            } else {
                subcategories.forEach(sc => couponCategories.push(sc.id));
            }
        });
        const saveCoupon = new SaveCouponModel({
            ...this.form.value,
            categories: couponCategories,
            priceLevels: this.selectedPriceLevels.length ? this.selectedPriceLevels.map(c => c.id) : []
        });

        if (this.editMode) {
            this.store.dispatch(new SaveEditCoupon({ couponId: this.couponId, coupon: saveCoupon }));
        } else {
            this.store.dispatch(new SaveCoupon(saveCoupon));
        }
    }

    getButtonTitle() {
        return this.editMode ? 'Save' : 'Add Coupon';
    }

    cancel() {
        this.store.dispatch(new ToggleInfobar({
            open: false,
        }));
    }

    setSelectedDate($event: Array<Date>) {
        const selectedDates = [];
        selectedDates.push(this.getDateFormat($event[0]));
        selectedDates.push(this.getDateFormat($event[1]));

        this.form.controls.selectedDate.setValue(selectedDates.join(' to '));
        this.form.controls.selectedDateArray.setValue(selectedDates);
        this.form.updateValueAndValidity();
        this.displayDateRange = false;
    }

    getDate(dateString: string): Array<Date> {
        return [new Date(dateString.split(' to ')[0]), new Date(dateString.split(' to ')[1])];
    }

    getDateFormat(date: Date): string {
        return moment(date).format('L LT');
    }
}
