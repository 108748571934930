import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/app.reducer';
import { TemplateEnum } from 'src/app/core/enums/template.enum';
import { getThemeId } from '../../selectors/branding.selector';

@Component({
    selector: 'app-order-status',
    templateUrl: './order-status.component.html',
    styleUrls: ['./order-status.component.scss']
})
export class OrderStatusComponent implements OnInit {

    @Input() status;
    @Input() orderId: number;
    @Input() availableStatuses = [];
    @Output() selected = new EventEmitter<any>();

    template$: Observable<TemplateEnum>;
    templateEnum = TemplateEnum;

    constructor(private store: Store<AppState>) { }

    ngOnInit() {
        this.template$ = this.store.select(getThemeId);
    }

    onSelect(status) {
        this.selected.emit({
            orderId: this.orderId,
            statusId: status.id
        });
    }

}
