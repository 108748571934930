import { AfterViewChecked, AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-google-places-autocomplete',
    templateUrl: './google-places-autocomplete.component.html',
    styleUrls: ['./google-places-autocomplete.component.scss']
})
export class GooglePlacesAutocompleteComponent implements OnInit, AfterViewInit, AfterViewChecked {

    @Input() placeholder: string;
    @Input() inputValue: string;
    @Input() autocompleteId: string;
    @Output() selectPlace: EventEmitter<any> = new EventEmitter<any>();
    @Output() clearAddressSelection: EventEmitter<any> = new EventEmitter<any>();

    addresstext: HTMLInputElement;

    constructor() { }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
        this.addresstext = document.getElementById(this.autocompleteId) as HTMLInputElement;
        this.addresstext.addEventListener('input', (e: any) => {
            if (!e.target.value) {
                this.clearAddressSelection.emit();
            }
        });
        this.getPlaceAutocomplete();
    }

    ngAfterViewChecked(): void {
        document.getElementById(this.autocompleteId).setAttribute('autocomplete', 'disabled');
    }

    private getPlaceAutocomplete(): void {
        const autocomplete = new google.maps.places.Autocomplete(this.addresstext,
            {
                fields: ['address_component', 'formatted_address', 'geometry', 'place_id', 'utc_offset_minutes'],
                componentRestrictions: { country: ['us', 'can', 'uk', 'fr', 'ro'] },
            });
        google.maps.event.addListener(autocomplete, 'place_changed', () => {
            const place = autocomplete.getPlace();
            this.selectPlace.emit(place);
        });
    }

}
