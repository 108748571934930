import { Permission } from './permission.model';

export class UserPermissionGroup {
    addOnKey: string;
    description: string;
    id: number;
    key: string;
    name: string;
    permission: Array<Permission>;
}
