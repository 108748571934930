import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-sortby-filter-temp1',
    templateUrl: './sortby-filter.temp1.component.html',
    styleUrls: ['./sortby-filter.temp1.component.scss']
})
export class SortbyFilterTemp1Component implements OnInit {

    @Input() title: string;
    @Input() options: Array<any>;
    @Output() optionClick = new EventEmitter<any>();

    constructor() { }

    ngOnInit(): void {
    }

    onClick(option: any) {
        this.optionClick.emit(option);
    }

}
