import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-rewards-points-learn-modal',
    templateUrl: './rewards-points-learn-modal.component.html',
    styleUrls: ['./rewards-points-learn-modal.component.scss']
})
export class RewardsPointsLearnModalComponent implements OnInit {

    iframeUrl: SafeResourceUrl;

    constructor(
        private dialogRef: MatDialogRef<RewardsPointsLearnModalComponent>,
        private sanitizer: DomSanitizer
    ) { }

    ngOnInit(): void {
        this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(environment.REWARDS_POINTS_LEARN_URL);
    }

    close() {
        this.dialogRef.close();
    }

    openSupportChat() {
        const isVisible = window['$crisp'].is("chat:visible");
        window['$crisp'].push(["do", `chat:${isVisible ? 'hide' : 'show'}`]);
        if (!isVisible) {
            window['$crisp'].push(["do", "chat:open"]);
        }
    }

}
