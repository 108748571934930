import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToggleInfobar } from 'src/app/shared/actions/infobar.actions';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { combineLatest, Observable, Subject } from 'rxjs';
import { UnitOfMeasureModel } from 'src/app/core/models/product/unit-of-measure.model';
import {
    getUomOptions, getProductCatalog, getProductCatalogLoading,
    getRelatedUom, getProductCategories
} from 'src/app/shared/selectors/product.selector';
import { LoadUomOptions, SaveProductCatalog, LoadRelatedUom, LoadAllCategories } from 'src/app/shared/actions/product.actions';
import { ProductCatalogModel } from 'src/app/core/models/product/product-catalog.model';
import { PlatformType } from 'src/environments/platform-type.enum';
import { environment } from 'src/environments/environment';
import { CategoryModel } from 'src/app/core/models/product/category.model';
import { takeUntil } from 'rxjs/operators';
import { InfobarState } from 'src/app/shared/reducers/infobar.reducer';
import { InfobarProductCompositionComponents, InfobarComponents } from '../../../../../app/core/enums/infobar-components.enum';

@Component({
    selector: 'app-add-edit-product',
    templateUrl: './add-edit-product.component.html',
    styleUrls: ['./add-edit-product.component.scss']
})
export class AddEditProductComponent implements OnInit, OnDestroy {
    @Input() infobarConfigs: InfobarState;

    baseCatalogId: number;
    editMode: boolean;
    uomOptions$: Observable<Array<UnitOfMeasureModel>>;
    relatedUomOptions$: Observable<Array<UnitOfMeasureModel>>;
    categoryOptions$: Observable<Array<CategoryModel>>;
    loading$: Observable<boolean>;
    availableCategories: Array<any> = [];
    selectedCategory: Array<any> = [];

    form: FormGroup;
    isOffer: boolean;
    isAvailable: boolean;
    productCatalog: ProductCatalogModel;
    url: string;
    file: File;
    PlatformType = PlatformType;
    currentPlatform = environment.platformId;
    submitted: boolean;

    categoriesSettings = {
        text: '',
        classes: 'tl-multipleselect invalid-input',
        badgeShowLimit: 2,
        groupBy: 'masterCategory',
        singleSelection: true,
        disabled: false
    };

    private ngUnsubscribe: Subject<void> = new Subject<void>();

    constructor(private fb: FormBuilder, private store: Store<AppState>) { }

    ngOnInit() {
        this.editMode = this.infobarConfigs.params.editMode;
        this.baseCatalogId = this.infobarConfigs.params.baseCatalogId;
        this.uomOptions$ = this.store.select(getUomOptions);
        this.relatedUomOptions$ = this.store.select(getRelatedUom);
        this.loading$ = this.store.select(getProductCatalogLoading);

        combineLatest(
            this.store.select(getProductCategories),
            this.store.select(getProductCatalog)
        ).pipe(
            takeUntil(this.ngUnsubscribe)
        ).subscribe(([categories, productCatalog]) => {
            if (categories) {
                this.availableCategories = [];
                categories.forEach(c => {
                    if (c.subCategories && c.subCategories.length) {
                        c.subCategories.forEach(sc => this.availableCategories.push({
                            id: sc.id,
                            itemName: sc.name,
                            masterCategory: c.name
                        }));
                    } else {
                        this.availableCategories.push({
                            id: c.id,
                            itemName: c.name,
                            masterCategory: c.name
                        });
                    }
                });
            }

            if (productCatalog && this.editMode) {
                this.productCatalog = productCatalog;

                this.selectedCategory = [];

                if (categories) {
                    categories.forEach(category => {
                        if (category.id === productCatalog.categoryId) {
                            this.selectedCategory.push({
                                id: category.id,
                                itemName: category.name,
                                masterCategory: category.name
                            });
                        } else if (category.subCategories) {
                            category.subCategories.forEach(subcategory => {
                                if (subcategory.id === productCatalog.categoryId) {
                                    this.selectedCategory.push({
                                        id: subcategory.id,
                                        itemName: subcategory.name,
                                        masterCategory: subcategory.name
                                    });
                                }
                            });
                        }
                    });
                }
            }
            this.createForm();
        });

        this.store.dispatch(new LoadUomOptions());
        this.store.dispatch(new LoadAllCategories());
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    createForm() {
        this.form = this.fb.group({
            name: [{
                value: this.productCatalog ? this.productCatalog.name : '',
                disabled: this.readOnly
            }, Validators.required],
            category: [{ value: this.productCatalog ? this.selectedCategory : '', disabled: this.readOnly }, Validators.required],
            description: [{ value: this.productCatalog ? this.productCatalog.shortDescription : '', disabled: this.readOnly }],
            shippingWeight: [{ value: this.productCatalog ? this.productCatalog.weight : '', disabled: this.readOnly }],
            sku: [{ value: this.productCatalog ? this.productCatalog.vendorSku : '', disabled: this.readOnly }],
            packSize: [{ value: this.productCatalog ? this.productCatalog.uomSize : '', disabled: this.readOnly }, Validators.required],
            unitSize: [{ value: this.productCatalog ? this.productCatalog.unitUomSize : '', disabled: this.readOnly }, Validators.required],
            packSizeUom: [{ value: this.productCatalog ? this.productCatalog.uomId : '', disabled: this.readOnly }, Validators.required],
            unitSizeUom: [{
                value: this.productCatalog ? this.productCatalog.unitUomId : '',
                disabled: this.readOnly
            }, Validators.required],
            packDescription: [{ value: this.productCatalog ? this.productCatalog.uomDescription : '', disabled: this.readOnly }],
            unitUomDescription: [{ value: this.productCatalog ? this.productCatalog.unitUomDescription : '', disabled: this.readOnly }],
            isOffered: [{ value: this.productCatalog ? this.productCatalog.isOffered : false, disabled: this.readOnly }],
            isAvailable: [{ value: this.productCatalog ? this.productCatalog.isAvailable : false, disabled: this.readOnly }]
        });

        this.categoriesSettings = {
            ...this.categoriesSettings,
            disabled: this.readOnly
        };
    }

    get readOnly() {
        return this.productCatalog && !this.productCatalog.isPrivate;
    }

    save() {
        if (this.readOnly) {
            return;
        }

        this.submitted = true;

        if (this.form.invalid) {
            return;
        }

        const productCatalog = new ProductCatalogModel();
        productCatalog.id = this.productCatalog ? this.productCatalog.id : -1;
        productCatalog.isPrivate = this.productCatalog ? this.productCatalog.isPrivate : true;
        productCatalog.name = this.form.value.name;
        productCatalog.categoryId = this.form.value.category[0].id;
        productCatalog.shortDescription = this.form.value.description;
        productCatalog.weight = this.form.value.shippingWeight;
        productCatalog.vendorSku = this.form.value.sku;
        productCatalog.uomSize = this.form.value.packSize;
        productCatalog.unitUomSize = this.form.value.unitSize;
        productCatalog.uomId = this.form.value.packSizeUom;
        productCatalog.unitUomId = this.form.value.unitSizeUom;
        productCatalog.uomDescription = this.form.value.packDescription;
        productCatalog.unitUomDescription = this.form.value.unitUomDescription;
        productCatalog.isOffered = this.form.value.isOffered;
        productCatalog.newIsAvailable = this.form.value.isAvailable;
        productCatalog.baseCatalogId = this.baseCatalogId;
        productCatalog.historyID = this.productCatalog ? this.productCatalog.historyID : -1;
        productCatalog.categoryName = this.form.value.category[0].itemName;

        this.store.dispatch(new SaveProductCatalog(
            {
                product: productCatalog,
                file: this.file
            }
        ));
    }

    processFile(files: FileList) {
        if (files.length === 0) {
            return;
        }

        const fileReader: FileReader = new FileReader();
        fileReader.readAsDataURL(files.item(0));

        fileReader.onload = (event: any) => {
            this.url = event.target.result;
        };

        this.file = files.item(0);
    }

    cancel() {
        this.form.reset();
        this.store.dispatch(new ToggleInfobar({
            open: false
        }));
    }

    onSetOffer() {
        this.isOffer = !this.isOffer;
    }

    onSetAvailable() {
        this.isAvailable = !this.isAvailable;
    }

    uploadFile() {
        document.getElementById('selectedFile').click();
    }

    deleteFile() {
        this.url = undefined;
        this.file = undefined;

        if (this.productCatalog) {
            this.productCatalog.imageUrl = undefined;
        }
    }

    getButtonTitle() {
        return this.editMode ? 'Save' : 'Add Product';
    }

    loadRelatedUom(event: any) {
        if (!this.editMode) {
            this.store.dispatch(new LoadRelatedUom(event.value));
        }
    }

    getUomOptions(): Observable<Array<UnitOfMeasureModel>> {
        return this.editMode ? this.uomOptions$ : this.relatedUomOptions$;
    }

    getDefaultImage(): string {
        switch (this.currentPlatform) {
            case PlatformType.AMAST:
                return 'default.amast';
            default:
                return 'default';
        }
    }

    getOnErrorImage($event: any) {
        $event.src = 'assets/images/categories/' + this.getDefaultImage() + '.svg';
    }

    openProductCompositionInfobar() {
        this.store.dispatch(new ToggleInfobar({
            ...this.infobarConfigs,
            open: this.infobarConfigs.isOpen,
            params: {
                [InfobarProductCompositionComponents.PAGE_NAME]: true,
                [InfobarComponents.SITE]: null,
                productId: this.infobarConfigs.params.productId,
                baseCatalogId: this.infobarConfigs.params.baseCatalogId,
                editMode: this.infobarConfigs.params.editMode,
                productCompositionId: this.infobarConfigs.params.productCompositionId,
                compositionType: this.infobarConfigs.params.compositionType,
            }
        }));
    }
}
