import { BrandingActionTypes, BrandingActions } from '../actions/branding.actions';
import { Platform } from 'src/app/core/models/branding/platform.model';

export interface BrandingState {
    loading: boolean;
    platformConfiguration: Platform;
}

const initialState: BrandingState = {
    loading: false,
    platformConfiguration: undefined
};

export function brandingReducer(state = initialState, action: BrandingActions): BrandingState {
    switch (action.type) {
        case BrandingActionTypes.LoadPlatformConfigurationSuccess:
            return {
                ...state,
                platformConfiguration: action.payload
            };
        default:
            return state;
    }
}



