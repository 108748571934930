import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpErrorResponse, HttpSentEvent, HttpHeaderResponse, HttpProgressEvent, HttpResponse, HttpUserEvent } from '@angular/common/http';
import { Observable, of, BehaviorSubject, throwError } from 'rxjs';
import { catchError, switchMap, finalize, filter, take, tap, first, flatMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AuthenticationService } from './authentication.service';
import { AppState } from 'src/app/app.reducer';
import { environment } from 'src/environments/environment';
import { LOGIN_DATA_STORAGE_KEY } from 'src/app/shared/constants/global.contants';
import { Logout, Login } from 'src/app/shared/actions/auth.actions';
import { currentUserDetails } from 'src/app/shared/selectors/auth.selector';
import { BrandingService } from '../branding/branding.service';
import { LoginDataModel } from '../../models/auth/login-data.model';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {

    constructor(
        private authService: AuthenticationService,
        private store: Store<AppState>,
        private brandingSvc: BrandingService
    ) { }

    isRefreshingToken: boolean = false;
    tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any> | any> {

        return this.store.select(currentUserDetails).pipe(
            first(),
            flatMap(currentUserDetails => {
                const headers = {
                    'Platform-ID': environment.platformId
                };

                if (!request.headers.has('SITE-ID')) {
                    const siteIdStorage = JSON.parse(localStorage.getItem('SITE_ID_STORAGE'));
                    if (siteIdStorage) {
                        headers['Site-ID'] = siteIdStorage.toString();
                    } else if (currentUserDetails && currentUserDetails.defaultSiteId) {
                        headers['Site-ID'] = currentUserDetails.defaultSiteId.toString();
                    }
                }

                request = request.clone({
                    setHeaders: headers
                });

                const loginData = JSON.parse(localStorage.getItem(LOGIN_DATA_STORAGE_KEY())) as any;
                return next.handle(this.addTokenToRequest(request, loginData))
                    .pipe(
                        tap(event => {
                            if (event instanceof HttpResponse) {
                                if (event.headers.has('X-REDIRECT')) {
                                    window.location.href = event.headers.get('X-REDIRECT');
                                    return;
                                }
                            }
                        }),
                        catchError(err => {
                            switch ((<HttpErrorResponse>err).status) {
                                case 401:
                                    this.store.dispatch(new Logout());
                                    break;
                                // return this.handle401Error(request, next);
                                case 400:
                                    // this.store.dispatch(new Logout());
                                    return throwError(err);
                            }
                            return throwError(err);
                        }));
            }),
        );
    }

    private addTokenToRequest(request: HttpRequest<any>, loginData: LoginDataModel): HttpRequest<any> {
        if (loginData) {
            return request.clone({ setHeaders: { Authorization: `Bearer ${loginData.accessToken}` } });
        }

        return request;
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
        if (!this.isRefreshingToken) {
            this.isRefreshingToken = true;

            // Reset here so that the following requests wait until the token
            // comes back from the refreshToken call.
            this.tokenSubject.next(null);

            const loginData = JSON.parse(localStorage.getItem(LOGIN_DATA_STORAGE_KEY())) as any;
            return this.authService.refreshToken(loginData.refreshToken)
                .pipe(
                    switchMap((loginData: LoginDataModel) => {
                        if (loginData) {
                            this.tokenSubject.next(loginData.accessToken);
                            this.store.dispatch(new Login({ loginResponse: loginData }));
                            return next.handle(this.addTokenToRequest(request, loginData));
                        }

                        this.store.dispatch(new Logout());
                    }),
                    catchError(err => {
                        return throwError(err);
                    }),
                    finalize(() => {
                        this.isRefreshingToken = false;
                    })
                );
        } else {
            return this.tokenSubject
                .pipe(filter(token => token != null),
                    take(1),
                    switchMap(token => {
                        return next.handle(this.addTokenToRequest(request, {
                            accessToken: token
                        }));
                    }));
        }
    }
}
