import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'app-global-search-temp1',
    templateUrl: './global-search.temp1.component.html',
    styleUrls: ['./global-search.temp1.component.scss']
})
export class GlobalSearchTemp1Component implements OnInit {

    @Input() form: FormGroup;
    @Input() searchText: string;

    constructor() { }

    ngOnInit(): void {
    }

    clearKeyword() {
        this.form.controls.keyword.setValue(undefined);
    }

    get formControls() { return this.form.controls; }

}
