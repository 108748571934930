import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { ProductInfobarHeaders } from 'src/app/core/enums/product-infobar-headers.enum';
import { ToggleInfobar } from 'src/app/shared/actions/infobar.actions';
import { InfobarState } from 'src/app/shared/reducers/infobar.reducer';


@Component({
    selector: 'app-infobar-header',
    templateUrl: './infobar-header.component.html',
    styleUrls: ['./infobar-header.component.scss']
})
export class InfobarHeaderComponent implements OnInit {
    @Input() infobarConfigs: InfobarState;
    @Input() hideButtons: boolean;
    @Input() form: FormGroup;
    @Input() disabledSave: boolean;
    @Output() saveAction: EventEmitter<any> = new EventEmitter<any>();

    productInfobarHeaders = ProductInfobarHeaders;

    constructor(private store: Store<AppState>) { }

    ngOnInit() { }

    hideInfobar() {
        this.store.dispatch(new ToggleInfobar({
            open: false
        }));
    }

    save() {
        this.saveAction.emit();
    }

    cancel() {
        this.hideInfobar();
    }

    changeTab(tabId: number) {
        this.store.dispatch(new ToggleInfobar({
            ...this.infobarConfigs,
            params: {
                ...this.infobarConfigs.params,
                editMode: tabId === this.productInfobarHeaders.Edit
            },
            selectedTabId: tabId,
            open: true
        }));
    }

    get disableSave(): boolean {
        if (this.form) {
            return this.form && (this.form.invalid || (this.form.valid && !this.form.dirty));
        } else {
            return this.disabledSave;
        }
    }

}
