import { AddressModel } from './customer/address.model';
import { Permission } from './permission.model';

export class SiteModel {
    accountSecretKey: string;
    acronym: string;
    active: boolean;
    allowEdit: boolean;
    assignedCustomers: number;
    baseCatalogId: number;;
    commission: number;
    companyTypeId: number;
    companyTypeName: string;
    cultureCurrencyId: number;
    cultureDateId: number;
    cultureNumberId: number;
    customerAccountId: number;
    discountFee: number;
    displayName: string;
    email: string;
    firstName: string;
    fullName: string;
    id: number;
    inheritTypeId: number;
    lastName: string;
    leadAccountId: number;
    name: number;
    permissions: Array<Permission>;
    phone: string;
    secretKey: string;
    shippingAddress: AddressModel;
    siteManagerId: number;
    timezoneDisplayName: string;
    timezoneId: number;
    timezoneName: string;
    userID: number;
    validEmail: boolean;
    versionTypeId: number;
    virtualSeller: boolean;
    webSite: string;
    billingAddress: AddressModel;
}
