import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AppState } from 'src/app/app.reducer';
import { CategoryModel } from 'src/app/core/models/product/category.model';
import { GlobalProductModel } from 'src/app/core/models/product/global-product.model';
import { AuctionService } from 'src/app/core/services/auction/auction.service';
import { AddEditAuctionItemResponseModel } from 'src/app/modules/auctions/models/add-edit-auction-item-response.model';
import { AuctionItemModel } from 'src/app/modules/auctions/models/auction-item.model';
import { DeliveryOptionEnum } from 'src/app/modules/auctions/models/delivery-options.enum';
import { ProductTypeEnum } from 'src/app/modules/auctions/models/product-type.enum';
import { SaveAuctionItem } from '../../../../shared/actions/auction.actions';
import { GeneralItemModel } from 'src/app/shared/models/general-item.model';
import { InfobarState } from 'src/app/shared/reducers/infobar.reducer';
import { selectSavingAuction } from 'src/app/shared/selectors/auction.selector';
import { ProductService } from '../../../../core/services/product/product.service';

@Component({
    selector: 'app-add-edit-auction-item',
    templateUrl: './add-edit-auction-item.component.html',
    styleUrls: ['./add-edit-auction-item.component.scss']
})
export class AddEditAuctionItemComponent implements OnInit {

    @Input() infobarConfigs: InfobarState;
    activeSlideIndex = 0;
    images: Array<{ id: number, file: File; }> = new Array<{ id: number, file: File; }>();
    imagesUrls: Array<{ id: number, imageUrl: string; }> = new Array<{ id: number, imageUrl: string; }>();
    keyword: string;
    displayList: boolean;
    keyword$: Subject<string> = new Subject<string>();
    products: Array<GlobalProductModel>;
    categories: Array<CategoryModel>;
    conditions: Array<GeneralItemModel>;
    manufacturers: Array<GeneralItemModel>;
    availableCategories: Array<{ id: number, itemName: string, masterCategory: string; }> =
        new Array<{ id: number, itemName: string, masterCategory: string; }>();
    auctionItem: AuctionItemModel;
    shippingOptionSelected: boolean;
    pickupOptionSelected: boolean;
    firmOnPrice: boolean;
    loading$: Observable<boolean>;
    fileId = 0;
    refreshImages: boolean;
    disableProductFields: boolean;
    submitted: boolean;
    productType = ProductTypeEnum;
    deliveryOption = DeliveryOptionEnum;

    categoriesSettings = {
        text: '',
        classes: 'tl-multipleselect invalid-input',
        badgeShowLimit: 2,
        groupBy: 'masterCategory',
        singleSelection: true,
        position: 'bottom',
        enableSearchFilter: true,
        addNewItemOnFilter: true,
        disabled: false,
        autoPosition: false
    };

    manufacturersSettings = {
        text: '',
        classes: 'tl-multipleselect invalid-input',
        badgeShowLimit: 2,
        singleSelection: true,
        position: 'bottom',
        enableSearchFilter: true,
        addNewItemOnFilter: true,
        disabled: false,
        autoPosition: false
    };

    constructor(
        private auctionService: AuctionService,
        private productsService: ProductService,
        private store: Store<AppState>
    ) { }

    ngOnInit(): void {
        this.auctionItem = new AuctionItemModel();
        this.loading$ = this.store.select(selectSavingAuction);
        this.productsService.getAllCategories().subscribe((data: Array<CategoryModel>) => {
            this.categories = data;
            this.availableCategories = [];
            this.categories.forEach(c => {
                if (c.subCategories && c.subCategories.length) {
                    c.subCategories.forEach(sc => this.availableCategories.push({
                        id: sc.id,
                        itemName: sc.name,
                        masterCategory: c.name
                    }));
                } else {
                    this.availableCategories.push({
                        id: c.id,
                        itemName: c.name,
                        masterCategory: c.name
                    });
                }
            });

            if (this.auctionItem.categoryId) {
                this.auctionItem.category = this.availableCategories.filter(category =>
                    category.id === this.auctionItem.categoryId).map(category =>
                        ({ id: category.id, name: category.itemName, itemName: category.itemName }));
            }
        });

        this.auctionService.getAllConditions().subscribe((data: Array<GeneralItemModel>) => {
            this.conditions = data;
        });

        this.productsService.getManufacturers().subscribe((data: Array<GeneralItemModel>) => {
            this.manufacturers = data.map(manufacturer => ({ id: manufacturer.id, itemName: manufacturer.name, name: manufacturer.name }));

            if (this.auctionItem.manufacturerId) {
                this.auctionItem.manufacturer = this.manufacturers.filter(manufacturer =>
                    manufacturer.id === this.auctionItem.manufacturerId).map(manufacturer =>
                        ({ id: manufacturer.id, itemName: manufacturer.itemName, name: manufacturer.itemName }));
            }
        });

        this.keyword$.pipe(
            debounceTime(500),
        ).subscribe((keyword: string) => {
            if (keyword.length >= 3) {
                this.displayList = true;
                this.productsService.getProductsGlobalList(keyword, []).subscribe((data: Array<GlobalProductModel>) => {
                    this.products = data;
                });
            }
        });


        if (this.infobarConfigs.params.isEditMode) {
            this.auctionService.getAuctionItemForEdit(this.infobarConfigs.params.auctionItemId)
                .subscribe((data: AddEditAuctionItemResponseModel) => {
                    if (data) {
                        this.imagesUrls = data.auctionItemImageGallery;
                        this.assignAuctionItem(data);
                    }
                });
        }
    }

    assignAuctionItem(data: AddEditAuctionItemResponseModel): void {
        this.auctionItem.id = data.id;
        this.auctionItem.name = data.productName;
        this.auctionItem.price = data.price;
        this.auctionItem.isPriceNegociable = data.isPriceNegociable;
        this.auctionItem.description = data.productDescription;
        this.auctionItem.categoryId = data.categoryId;
        this.auctionItem.manufacturerId = data.manufacturerId;
        this.auctionItem.conditionTypeId = data.conditionTypeId;
        this.auctionItem.conditionDescription = data.conditionDescription;
        this.auctionItem.deliveryMethodIds = data.deliveryMethods.map(deliveryMethod => deliveryMethod.deliveryTypeId);
        this.auctionItem.deliveryFee = data.deliveryFee;
        this.auctionItem.address = data.address;
        this.auctionItem.status = data.status;
        this.auctionItem.productId = data.productId;
        this.auctionItem.productTypeId = data.productTypeId;
        this.firmOnPrice = !data.isPriceNegociable;
        this.shippingOptionSelected = data.deliveryMethods.find(deliveryMethod =>
            deliveryMethod.deliveryTypeId === DeliveryOptionEnum.SHIPPING) ? true : false;
        this.pickupOptionSelected = data.deliveryMethods.find(deliveryMethod =>
            deliveryMethod.deliveryTypeId === DeliveryOptionEnum.PICKUP) ? true : false;

        if (this.availableCategories && this.availableCategories.length) {
            this.auctionItem.category = this.availableCategories.filter(category =>
                category.id === data.categoryId).map(category =>
                    ({ id: category.id, name: category.itemName, itemName: category.itemName }));
        }

        if (this.manufacturers && this.manufacturers.length > 0) {
            this.auctionItem.manufacturer = this.manufacturers.filter(manufacturer =>
                manufacturer.id === data.manufacturerId).map(manufacturer =>
                    ({ id: manufacturer.id, itemName: manufacturer.itemName, name: manufacturer.name }));
        }

        if (this.auctionItem.productTypeId !== this.productType.AUCTION) {
            this.disableProductFields = true;
            this.updateDropDownSettings(true);
        }
    }

    showProductsList(event: any) {
        event.stopPropagation();
        this.displayList = true;
    }

    selectProduct(product: GlobalProductModel) {
        this.displayList = false;
        this.auctionItem.name = product.productName;
        this.auctionItem.description = product.description;
        this.auctionItem.productId = product.productId;
        this.auctionItem.manufacturer = [this.manufacturers.find(manufacturer => manufacturer.id = product.manufacturerId)];
        const selectedCategory = this.categories.find(category => category.id = product.categoryId);
        this.auctionItem.category = [{ id: selectedCategory.id, itemName: selectedCategory.name, name: selectedCategory.name }];

        this.updateDropDownSettings(true);
        this.disableProductFields = true;
    }

    // updateModelValidator(event: boolean, control: any): void {
    //     debugger;

    //     if (event) {
    //         control.setValidators(Validators.required);
    //         control.updateValueAndValidity();
    //     } else {
    //         control.clearValidators();
    //         control.updateValueAndValidity();
    //     }
    // }

    save(): void {
        this.submitted = true;

        if (!this.auctionItem.manufacturer[0].id || !this.auctionItem.category[0].id) {
            return;
        }

        this.auctionItem.isPriceNegociable = !this.firmOnPrice;
        if (this.shippingOptionSelected && !this.auctionItem.deliveryMethodIds
            .find(deliveryMethodId => deliveryMethodId === DeliveryOptionEnum.SHIPPING)) {
            this.auctionItem.deliveryMethodIds.push(DeliveryOptionEnum.SHIPPING);
        }

        if (this.pickupOptionSelected && !this.auctionItem.deliveryMethodIds
            .find(deliveryMethodId => deliveryMethodId === DeliveryOptionEnum.PICKUP)) {
            this.auctionItem.deliveryMethodIds.push(DeliveryOptionEnum.PICKUP);
        }

        if (this.auctionItem.category[0].id !== -1) {
            this.auctionItem.categoryId = this.auctionItem.category[0].id;
        } else {
            this.auctionItem.categoryId = undefined;
            this.auctionItem.categoryString = this.auctionItem.category[0].name;
            this.auctionItem.category = undefined;
        }

        if (this.auctionItem.manufacturer[0].id !== -1) {
            this.auctionItem.manufacturerId = this.auctionItem.manufacturer[0].id;
        } else {
            this.auctionItem.manufacturerId = undefined;
            this.auctionItem.manufacturerString = this.auctionItem.manufacturer[0].name;
            this.auctionItem.manufacturer = undefined;
        }

        this.auctionItem.status = this.auctionItem.status ? this.auctionItem.status : 1;
        this.auctionItem.imageNames = new Array<string>();

        // send saved images to backend
        if (this.infobarConfigs.params.isEditMode) {
            this.imagesUrls.filter(image => !image.imageUrl.includes('data:image')).map(image => (image.imageUrl)).forEach(image => {
                const imageSplit = image.split('/');
                this.auctionItem.imageNames.push(imageSplit[imageSplit.length - 1]);
            });
        }

        this.store.dispatch(new SaveAuctionItem({
            images: this.images.map(image =>
                (image.file)), auctionItem: this.auctionItem, isEditMode: this.infobarConfigs.params.isEditMode
        }));
    }

    onSelect(addedFiles: Array<File>, addAuctionItemForm: FormGroup) {
        addedFiles.forEach(file => {
            this.fileId++;
            this.images.push({ id: this.fileId, file });
        });
        this.processImages(this.images);
        addAuctionItemForm.markAsDirty();
    }

    processImages(images: Array<{ id: number, file: File; }>) {
        if (images.length === 0) {
            return;
        }

        images.forEach(image => {
            const fileReader: FileReader = new FileReader();
            fileReader.readAsDataURL(image.file);

            fileReader.onload = (event: any) => {
                this.imagesUrls.push({ id: image.id, imageUrl: event.target.result });
            };
        });
    }

    changeImage(event: any, isRange?: boolean) {
        if (isRange) {
            this.activeSlideIndex = event[0];
        } else {
            this.activeSlideIndex = event;
        }
    }

    deleteImage(index: number, image: { id: number, file: File; }, addAuctionItemForm: FormGroup): void {
        this.refreshImages = true;

        setTimeout(() => {
            this.imagesUrls.splice(index, 1);
            const fileIndex = this.images.findIndex(file => file.id === image.id);

            if (fileIndex !== -1) {
                this.images.splice(fileIndex, 1);
            }

            this.activeSlideIndex = index;
            this.refreshImages = false;
            addAuctionItemForm.markAsDirty();
        }, 300);

    }

    onAddItem(name: string, isCategory: boolean) {
        if (isCategory) {
            this.auctionItem.category = [{ id: -1, itemName: name, name }];
        } else {
            this.auctionItem.manufacturer = [{ id: -1, itemName: name, name }];
        }

        const elem = document.getElementById('click-element') as HTMLElement;
        elem.click();
    }

    clearSelectedProduct() {
        this.disableProductFields = false;
        this.auctionItem.description = '';
        this.auctionItem.name = '';
        this.auctionItem.productId = undefined;
        this.auctionItem.category = [new GeneralItemModel()];
        this.auctionItem.manufacturer = [new GeneralItemModel()];
        this.auctionItem.categoryId = undefined;
        this.auctionItem.manufacturerId = undefined;
        this.updateDropDownSettings(false);
    }

    updateDropDownSettings(disabled: boolean) {
        this.categoriesSettings = {
            ...this.categoriesSettings,
            disabled
        };

        this.manufacturersSettings = {
            ...this.manufacturersSettings,
            disabled
        };
    }
}
