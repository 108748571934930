import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-icon-show',
    templateUrl: './icon-show.component.html',
    styleUrls: ['./icon-show.component.scss']
})
export class IconShowComponent implements OnInit {

    @Input() cssClass = '';

    constructor() { }

    ngOnInit() {
    }

}
