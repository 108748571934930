import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'app-coupon-temp1',
    templateUrl: './coupon.temp1.component.html',
    styleUrls: ['./coupon.temp1.component.scss']
})
export class CouponTemp1Component implements OnInit {

    @Input() couponForm: FormGroup;
    @Input() couponError: any;
    @Output() submit = new EventEmitter();

    submitted: boolean;

    constructor() { }

    ngOnInit(): void {
    }

    onSubmit() {
        this.submitted = true;

        this.submit.emit();
    }

}
