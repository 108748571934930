import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AppState } from 'src/app/app.reducer';
import { TemplateEnum } from 'src/app/core/enums/template.enum';
import { getThemeId } from '../../selectors/branding.selector';

@Component({
    selector: 'app-pagination',
    templateUrl: './pagination.component.html',
    styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {

    @Input() page = 1;
    @Input() itemDetails: any;
    @Output() pageChanged = new EventEmitter();

    template$: Observable<TemplateEnum>;
    tempalteEnum = TemplateEnum;

    public term$ = new Subject<number>();

    constructor(private store: Store<AppState>) { }

    ngOnInit() {
        this.template$ = this.store.select(getThemeId);
        this.term$.pipe(
            debounceTime(500),
            distinctUntilChanged()
        ).subscribe((newPage) => {
            this.setPage(isNaN(newPage) ? 1 : newPage);
        });
    }

    setPage(newPage) {
        if (newPage < 1) {
            this.page = 1;
        } else if (newPage > this.itemDetails.totalPages) {
            this.page = this.itemDetails.totalPages;
        } else {
            this.page = newPage;
        }

        this.pageChanged.emit(this.page);
    }
}
