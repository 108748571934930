import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-icon-revert',
  templateUrl: './icon-revert.component.html',
  styleUrls: ['./icon-revert.component.scss']
})
export class IconRevertComponent implements OnInit {

  @Input() cssClass = '';

  constructor() { }

  ngOnInit() {
  }

}
