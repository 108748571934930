import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { SaveTips } from '../../actions/cart.actions';
import { Observable } from 'rxjs';
import { getSavingTips } from '../../selectors/cart.selector';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-tips-modal',
  templateUrl: './tips-modal.component.html',
  styleUrls: ['./tips-modal.component.scss']
})
export class TipsModalComponent implements OnInit {

  savingTips$: Observable<boolean>;
  tipsForm: FormGroup;

  tipsOptions = [
    { amount: 5 },
    { amount: 10 },
    { amount: 20 },
  ]

  constructor(
    public dialogRef: MatDialogRef<TipsModalComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: { vendorId: number, vendor?: any },
    private store: Store<AppState>,
    private fb: FormBuilder
  ) { 

    this.tipsForm = this.fb.group({
      amount: [null, Validators.pattern(/^[0-9]+(\.[0,5])?$/)]
    });

   }

  ngOnInit() {
    this.savingTips$ = this.store.select(getSavingTips);
  }

  get formAmount() { return this.tipsForm.controls.amount; }

  onSelectTip(amount) {
    this.tipsForm.controls.amount.setValue(amount);
    this.tipsForm.controls.amount.updateValueAndValidity();
  }

  onSubmit() {
    if (this.tipsForm.invalid) {
      return;
    }

    this.store.dispatch(new SaveTips({
      vendorId: this.data.vendorId,
      amount: this.formAmount.value || 0,
      vendor: this.data.vendor
    }));
  }

  onSkip() {
    this.store.dispatch(new SaveTips({
      vendorId: this.data.vendorId,
      amount: 0,
      vendor: this.data.vendor
    }));
  }

  close(): void {
    this.dialogRef.close();
  }

}
