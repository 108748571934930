import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-icon-price-levels',
    templateUrl: './icon-price-levels.component.html'
})
export class IconPriceLevelsComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
