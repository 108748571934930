import { environment } from 'src/environments/environment';
import { GridColumnDateGrouping } from 'src/app/core/enums/date-groping.enum';
import * as moment from 'moment';

export function LOGIN_DATA_STORAGE_KEY() {
    return `mkt_usr_key_${environment.platformId}`;
};

export function USER_PERMISSIONS_STORAGE_KEY() {
    return `mkt_usr_permissions_key_${environment.platformId}`;
}

export const DATE_RANGE_DEFAULT_OPTIONS = [
    { value: GridColumnDateGrouping.DAILY, viewValue: 'Daily' },
    { value: GridColumnDateGrouping.WEEKLY, viewValue: 'Weekly' },
    { value: GridColumnDateGrouping.MONTHLY, viewValue: 'Monthly' }
];

export const DATE_CUSTOM_RANGES = [
    {
        label: 'Last 7 Days',
        value: [new Date(moment().subtract(6, 'days').toString()), new Date(moment().toString())]
    },
    {
        label: 'Last 30 Days',
        value: [new Date(moment().subtract(29, 'days').toString()), new Date(moment().toString())]
    },
    {
        label: 'This Month',
        value: [new Date(moment().startOf('month').toString()), new Date(moment().endOf('month').toString())]
    },
    {
        label: 'Last Month',
        value: [new Date(moment().subtract(1, 'month').startOf('month').toString()),
        new Date(moment().subtract(1, 'month').endOf('month').toString())]
    },
    {
        label: 'Year to Date',
        value: [new Date(moment().startOf('year').toString()), new Date(moment().toString())]
    }
];

export const DEFAULT_DATE_RANGE_SELECTION = [
    moment().subtract(4, 'weeks').toDate(),
    moment().toDate()
];

export function randomColor() {
    const colorsArray = ['#95BF16', '#E8C625', '#54DD8B'];
    const color = colorsArray[Math.floor(Math.random() * colorsArray.length)];
    return color;
}

export function getDateWithSetTimes(time: string) {
    if (!time || !time.length) {
        return;
    }

    const date = new Date();
    const parts = time.split(":");
    if (parts.length !== 2) {
        date.setHours(0);
        date.setMinutes(0);
        return date;
    }

    date.setHours(parseInt(parts[0]));
    date.setMinutes(parseInt(parts[1].split(" ")[0]));
    return date;
}