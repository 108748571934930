import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { LoadVendors } from '../../actions/order-guide.action';
import { VendorCategoryFilter } from 'src/app/core/enums/vendor-cat-filter.enum';

@Component({
    selector: 'app-vendor-category-filter',
    templateUrl: './vendor-category-filter.component.html',
    styleUrls: ['./vendor-category-filter.component.scss']
})
export class VendorCategoryFilterComponent implements OnInit {

    @Input() type: VendorCategoryFilter;

    @Output() changed = new EventEmitter<VendorCategoryFilter>();

    options = [
        { id: VendorCategoryFilter.ALL_VENDORS, name: 'All Sellers', isSelected: true },
        { id: VendorCategoryFilter.MY_VENDORS, name: 'My Sellers', isSelected: false },
        { id: VendorCategoryFilter.CATALOG_VENDORS, name: 'Catalog Sellers', isSelected: false },
        { id: VendorCategoryFilter.FAVORITE_VENDORS, name: 'Favorite Sellers', isSelected: false }
    ];

    constructor(
        private store: Store<AppState>
    ) { }

    ngOnInit() {
        this.store.dispatch(new LoadVendors());
    }

    ngOnChanges(): void {
        this.options.forEach(o => o.isSelected = o.id === this.type)
    }

    onClick(option) {
        this.options.forEach(o => {
            o.isSelected = o.id === option.id;
        });

        this.changed.emit(option.id);
    }

}

