import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-icon-inventory',
  templateUrl: './icon-inventory.component.html',
  styleUrls: ['./icon-inventory.component.scss']
})
export class IconInventoryComponent implements OnInit {

  @Input() cssClass = '';
  
  constructor() { }

  ngOnInit() {
  }

}
