export class AddressModel {
    address1: string;
    address2: string;
    city: string;
    company: string;
    country: string;
    countryId: number;
    email: string;
    fax: string;
    firstName: string;
    id: number;
    lastName: string;
    phone: string;
    phone2: string;
    stateId: number;
    stateName: string;
    zipCode: string;
    // used for credit card
    address: string;
}
