import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import * as moment from 'moment';
import { InfobarComponents } from 'src/app/core/enums/infobar-components.enum';
import { EntityChanel } from 'src/app/core/models/chat/entity-chanel.model';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import * as chatSelectors from '../../selectors/chat.selector';
import * as chatActions from '../../actions/chat.actions';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { LoadAllChannelsFilter } from 'src/app/core/models/chat/load-all-channels.filter';
import { ChatDisplayStatus } from 'src/app/core/models/chat/chat-display.status';

@Component({
    selector: 'app-inbox',
    templateUrl: './inbox.component.html',
    styleUrls: ['./inbox.component.scss']
})
export class InboxComponent implements OnInit, OnDestroy {
    @Input() chatDisplayStatus: ChatDisplayStatus;
    @Output() closeInbox: EventEmitter<any> = new EventEmitter<any>();
    @Output() newConversation: EventEmitter<any> = new EventEmitter<any>();
    @Output() existingConversation: EventEmitter<EntityChanel> = new EventEmitter<EntityChanel>();
    @Output() chatDisplayStatusChange: EventEmitter<ChatDisplayStatus> = new EventEmitter<ChatDisplayStatus>();

    channelsList: Array<EntityChanel>;
    date: Date = new Date();
    isLoading: boolean;
    ngUnsubscribe: Subject<void> = new Subject<void>();
    search$ = new Subject<any>();
    keyword = '';
    showSearch: boolean;

    constructor(private store: Store<AppState>) { }

    ngOnInit() {
        this.store.pipe(select(chatSelectors.getChatLoading), takeUntil(this.ngUnsubscribe)).subscribe((loading: boolean) => {
            this.isLoading = loading;
        });

        this.store.dispatch(new chatActions.LoadAllChannels(this.initFilter()));

        this.store.pipe(select(chatSelectors.getChatChannels), takeUntil(this.ngUnsubscribe)).subscribe((channels: Array<EntityChanel>) => {
            this.channelsList = channels;
        });

        this.search$.pipe(debounceTime(500)).subscribe(() => {
            this.store.dispatch(new chatActions.LoadAllChannels(this.initFilter()));
        });
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    initFilter(): LoadAllChannelsFilter {
        return {
            statuses: [1, 2, 3],
            currentPage: 1,
            pageSize: 40,
            entityTypeId: undefined,
            keyword: this.keyword
        };
    }

    onCloseInbox() {
        this.closeInbox.emit();
    }

    closeSearch(): void {
        this.showSearch = false;
        this.keyword = '';
        this.search$.next();
    }

    getDate(date): string {
        return moment.utc(date).local().format('L,  h:mm');
    }

    openNewConversation() {
        this.newConversation.emit();
    }

    openExistingConversation(channel: EntityChanel) {
        this.existingConversation.emit(channel);
    }

    onCloseChat() {
        this.chatDisplayStatusChange.emit(this.chatDisplayStatus);
    }
}
