import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-icon-tips',
    templateUrl: './icon-tips.component.html'
})
export class IconTipsComponent implements OnInit {

    @Input() cssClass = 'icon--18';

    constructor() { }

    ngOnInit(): void {
    }

}
