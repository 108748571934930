import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-icon-unlock',
    templateUrl: './icon-unlock.component.html'
})
export class IconUnlockComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
